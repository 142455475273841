import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const getPartnerUsers = createAction('GET_PARTNER_USERS');
export const setPartnerUsers = createAction('SET_PARTNER_USERS');

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
        setPartnerUsers,
      (state, action) => {
        return action.payload
      } 
    ]
  ]),
  {rows: [], total: 0} 
);

// SELECTORS
 
