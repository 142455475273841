import {
  ADD_PRODUCTS,
  UPDATE_TRIP_PURPOSE,
  UPDATE_TRAVELLER_DETAILS,
  UPDATE_TRIP_DETAILS,
  UPDATE_TRIP_DURATION,
  DOCUMENT_CONCENT,
  BMF_UPDATE_ADDRESS,
} from "../../../constants";
export const OTP_MISMATCH = "OTP_MISMATCH";
export const DISPLAY_ERROR_PAGE = "DISPLAY_ERROR_PAGE";
export const DISPLAY_ERROR = "DISPLAY_ERROR";
export const VALIDATION_ERROR = "VALIDATION_ERROR";
export const LOGIN_REQUIRED = "LOGIN_REQUIRED";
export const INTERNAL_ERROR = "INTERNAL_ERROR";
export const REFRESH_ERROR = "REFRESH_ERROR";
export const BAD_REQUEST = "BAD_REQUEST";
export const ERROR_NO_ACTION = "ERROR_NO_ACTION";

//responsestatus
export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";

// card status
export const BLOCKED = "BLOCKED";
export const ACTIVE = "ACTIVE";
export const INACTIVE = "INACTIVE";
export const BANNED = "BANNED";

//transaction
export const DAY = "DAY";
export const MONTH = "MONTH";
export const CONTACTLESS = "CONTACTLESS";
export const POINTOFSALE = "POS";
export const AUTOTELLERMACHINE = "ATM";
export const ECOM = "ECOM";
export const DOMESTIC = "DOM";
export const INTERNATIONAL = "INTL";

//Onboarding states
export const PENDING_ONBOARDING = "PENDING_ONBOARDING";
export const ONBOARDING_NOT_INITIATED = "ONBOARDING_NOT_INITIATED";
export const KYC_DECLINED = "KYC_DECLINED";
export const ACTIVE_PIN_NOT_SET = "ACTIVE_PIN_NOT_SET";

//card states before activation
export const CREATED = "CREATED";
export const DELIVERY_IN_PROGRESS = "DELIVERY_IN_PROGRESS";
export const ORDERED = "ORDERED";
export const PICK_UP_SCHEDULED = "PICK_UP_SCHEDULED";
export const SHIPPED = "SHIPPED";
export const IN_TRANSIT = "IN_TRANSIT";
export const OUT_FOR_DELIVERY = "OUT_FOR_DELIVERY";
export const DELIVERED = "DELIVERED";
export const DELIVERY_FAILED = "DELIVERY_FAILED";
export const RETURNED_TO_ORIGIN = "RETURNED_TO_ORIGIN";
export const INVALID = "INVALID";

//pin set states
export const VERIFY_OTP = "verify_otp";

//transactions states
export const SPEND = "SPEND";
export const LOAD = "LOAD";
export const UNLOAD = "UNLOAD";
export const REFUND = "REFUND";

//cookie values
export const PERSONAL_DETAILS = "visitedPersonalDetails";
export const SELFIE = "visitedSelfiePage";
export const CARD_ASSIGNED = "visitedCardAssigned";
export const ADDRESS_SUBMIT = "visitedAddressSubmit";
export const ADDRESS_SUBMIT_SUCCESS = "visitedAddressSubmitSuccess";
export const DASHBOARD = "visitedDashboard";
export const KYC_DECLINED_COOKIE_NAME = "visitedKycDeclined";
export const LANDING_FOREX = "visitedLanding";
export const TRACKING = "visitedTracking";
export const SELFIE_SUBMITTED_AT = "selfieSubmittedAt";
export const CARD_UNDER_PROCESSING = "card_under_processing";

// dispute states
export const RESOLVED = "RESOLVED";
export const PENDING = "PENDING";
export const OTHER = "OTHER";

// partners
export const GI = "gi";

//timeout state
export const CKYC_READ_TIMEOUT_BREACHED = "ckyc_read_timeout_breached";
export const SYNC_KYC = "sync_kyc";

//delivery partner
export const DELHIVERY = "Delhivery";
export const BMF = "bmf";
export const BMF_LANDING = "bmf-landing";
export const BMF_TOP_CITIES = "bmf_top_cities";
export const BUY = "Buy";
export const SELL = "Sell";
export const PROGRAM = "program";

export const FOREX_OFFERINGS = "offerings";

//redirection urls
export const cardFaqLink = "https://www.bookmyforex.com/card-faqs/";
export const generalFaqLink =
  "https://www.bookmyforex.com/all-frequently-asked-questions/";

export const BMFTypes = Object.freeze({
  BUY: "Buy Forex",
  SELL: "Sell Forex",
});
export const BMFStages = Object.freeze({
  add_products: "Currency Details",
  update_traveller_details: "Traveller Details",
  update_trip_details: "Trip Details",
  update_trip_duration: "Trip Duration",
  update_trip_purpose: "Trip Purpose",
  update_documents: "Upload Documents",
  bmf_update_address: "Delivery Address",
  submit_order: "Submit Order",
  document_consent: "Info & Consent",
  confirm_order_details: "Confirm Order Details",
});

export const BMF_CONTINUE_FORM_KEY = "bmf-continue-form";
export const BMF_PROGRAM_COOKIE_KEY = "bmf_program";
export const BMF_DOCUMENT_PASSPORT = "PASSPORT";
export const BMF_DOCUMENT_FLIGHT_TICKET = "FLIGHT_TICKET";
export const BMF_DOCUMENT_VISA = "VISA";
export const SUBMIT_ORDER = "submit_order";
export const TM_AUTH_COOKIE = "x-tm-auth";
export const INR_SESSION_COOKIE = "is-initial-landing";
export const DEVICE = "device";
export const DESKTOP = 'Desktop';
export const MOBILE = 'Mobile';

export const BMF_POPULAR_COUNTRIES = [
  "United States of America (USA)",
  "United Kingdom (UK)",
  "Canada",
  "Indonesia",
  "Australia",
  "United Arab Emirates",
  "Singapore",
  "Thailand",
  "Maldives",
];
export const BMF_PRODUCT_COOKIE_KEY = "bmf_product";
export const BMF_CURRENCY_DETAILS = "bmf-currency-details";
export const BMF_CURRENCY = "bmf-currencies";
export const BMF_COUNTRY = "bmf-countries";
export const BMF_CITIES = "bmf-cities";
export const BMF_STATE = "bmf-states";
export const BMF_TRIP_PURPOSE = "bmf-trip-purposes";
export const BMF_SAMPLE_DOCS_KEY = "bmf-sample-docs";
export const PC = "PC";
export const CN = "CN";
export const CASH = "cash";
export const CARDS = "cards";
export const CMP = "cmp";
export const BMFCashOrCard = Object.freeze({
  "option-2": CASH,
  "option-1": CARDS,
});
export const BMFOptionToCashOrCard = Object.freeze({
  [CASH]: "option-2",
  [CARDS]: "option-1",
});
export const EXCHANGE_RATE_TIMEOUT = 60000;
export const PAYMENT_STATUS_TIMEOUT = 10000;
export const BMF_ROUTES = {
  "currency-exchange": "continue-form",
  "currency-exchange-add-money": "continue-form",
  "buy-cash-card": "continue-form",
  "trip-details": "continue-form",
  "delivery-details": "continue-form",
  "trip-duration": "continue-form",
  "trip-purpose": "continue-form",
  "order-confirmed": "continue-form",
  "traveller-details": "continue-form",
  "order-failed": "continue-form",
  "order-summary": "continue-form",
  "order-pending": "continue-form",
  "doc-info": "continue-form",
};

export const WORKFLOW_REDIRECT_MAPPING = {
  add_products: "currency-exchange-add-money",
};
export const FILE_SIZE_LIMIT = 8;

// bmf file types
export const doc = "msword";
export const docx =
  "vnd.openxmlformats-officedocument.wordprocessingml.document";
export const svg = "svg+xml";

// bmf url
export const BMF_UPLOAD_URL = `card-management/api/v1/documents/upload`;

// bmf Header names

export const FOREX_CURRENCY = "Currency Notes";
export const headerName = {
  [ADD_PRODUCTS]: "Currency Details",
  [UPDATE_TRIP_PURPOSE]: "Trip Purpose",
  [UPDATE_TRAVELLER_DETAILS]: "Traveller Details",
  [UPDATE_TRIP_DETAILS]: "Trip Details",
  [UPDATE_TRIP_DURATION]: "Trip Duration",
  [DOCUMENT_CONCENT]: "Info & Consent",
  [BMF_UPDATE_ADDRESS]: "Delivery Address",
};

// OrderStatus
export const ORDER_SUBMITTED = "Order Submitted";
export const DOCUMENTS_PENDING = "Documents Pending";

export const cardStatesBeforeActive = [
  CREATED,
  ORDERED,
  PICK_UP_SCHEDULED,
  SHIPPED,
  IN_TRANSIT,
  OUT_FOR_DELIVERY,
  DELIVERED,
  DELIVERY_FAILED,
  RETURNED_TO_ORIGIN,
  DELIVERY_IN_PROGRESS,
];
export const HAPPAY = "HAPPAY";

export const FOREX_HAPPAY_MAKEMYTRIP = "FOREX_HAPPAY_MAKEMYTRIP";
export const FOREX_HAPPAY_GOIBIBO = "FOREX_HAPPAY_GOIBIBO";
export const FOREX_BMF_MAKEMYTRIP = "FOREX_BMF_MAKEMYTRIP";
export const FOREX_BMF_GOIBIBO = "FOREX_BMF_GOIBIBO";
export const product_features_mapping = {
  "CN":[
    {
      "icon": "ec3",
      "infoTitle": "Lowest Exchange Rate in India"
    },
    {
      "icon": "ec2",
      "infoTitle": "Same/Next Day Delivery"
    },
    {
      "icon": "ec1",
      "infoTitle": "Fully Digital Order Placement"
    },
    {
      "icon": "ec4",
      "infoTitle": "Best in class service"
    }
  ],
  "inr":[
    {
      "icon": "gcc1",
      "infoTitle": "No Forex Mark-up"
    },
    {
      "icon": "gcc2",
      "infoTitle": "Load in INR & Spend in any Currency"
    },
    {
      "icon": "gcc3",
      "infoTitle": "Pan India Delivery within 10 Days"
    },
    {
      "icon": "gcc4",
      "infoTitle":"Fully Digital Order Placement & Card Management"
    }
  ],
  "PC":[
    {
      "icon": "fc1",
      "infoTitle": "0% Forex Mark-up (IBR Rates Applicable)"
    },
    {
      "icon": "fc2",
      "infoTitle": "Load in 14 Currencies"
    },
    {
      "icon": "fc3",
      "infoTitle": "Same/Next Day Delivery"
    },
    {
      "icon": "fc4",
      "infoTitle": "Fully Digital Order Placement"
    }
  ]
}

export const TNC_URL = "https://tripmoneycmshtmlak.mmtcdn.com/html/MITC_28th_Oct_Trip_Money_New_d171aa5086.html";
export const USER_AGGREMENT_URL = "https://www.tripmoney.com/user-agreement";
export const CKYC_INFO_FETCH_AGREEMENT_URL = "https://tripmoneycmshtmlak.mmtcdn.com/html/CKYC_Consent_3e509b8506.html"; 
// ERROR code
export const DISPLAY_ERROR_MISMATCH = "DISPLAY_ERROR_MISMATCH";

// CKYC DECLINE ERROR CASE
export const CKYC_PAN_DECLINED_MOBILE_MISMATCH = "CKYC_PAN_DECLINED_MOBILE_MISMATCH";
export const CKYC_PAN_REDIRECT_DOB_MISMATCH = "CKYC_PAN_REDIRECT_DOB_MISMATCH";
export const CKYC_PAN_REDIRECT_NAME_MISMATCH = "CKYC_PAN_REDIRECT_NAME_MISMATCH";
export const CKYC_PAN_DECLINED_DUE_PHOTO_MISMATCH = "CKYC_PAN_DECLINED_DUE_PHOTO_MISMATCH";
export const CKYC_PAN_DECLINED_LOCATION = "CKYC_PAN_DECLINED_LOCATION";
export const CKYC_PAN_DECLINED_DEFAULT = "CKYC_PAN_DECLINED_DEFAULT";
export const CKYC_PAN_DECLINED_NO_RECORD = "CKYC_PAN_DECLINED_NO_RECORD";
export const CKYC_PAN_DECLINED_OLDER_RECORD = "CKYC_PAN_DECLINED_OLDER_RECORD";
export const CKYC_PAN_DECLINED_USER_KYCED = "CKYC_PAN_DECLINED_USER_KYCED";
export const CKYC_PAN_REDIRECT_PASSPORT_MISMATCH = "CKYC_PAN_REDIRECT_PASSPORT_MISMATCH";

// FEEDBACK COMPONENT TOUCHPOINTS
export const CARD_ACIIVATION = "CARD_ACTIVATION";
export const CARD_DELIVERED = "CARD_DELIVERED";
export const CARD_LOAD_MONEY = "CARD_LOAD_MONEY";
export const CARD_ORDERED = "CARD_ORDERED";
export const CARD_SPENT_MONEY = "CARD_SPENT_MONEY";
export const CARD_WITHDRAW_MONEY = "CARD_WITHDRAW_MONEY";
export const CANCEL_EVENT = "CANCEL";
export const COMPLETED_EVENT = "COMPLETE";

//MMT ANDROID VERSION FOR POST SALES
export const POST_SALES_MMTAPP_VERSION = '8.9.7';

export const PARTNER_KEY = "partner";

export const getFaqData = (url) => {
  return [
    {
      id: "faq1",
      qun: "When will the card operations stop?",
      ans: `Load Money functionality on all Active cards will be available till 30th September 2023. 
<br/>
    Cards can be used across all ATMs/E-commerce websites/POS Terminals till 30th October 2023. All TripMoney Global Cash Cards will be closed on 31st October 2023. 
    <br/>
    <a>Please note: Card can not be used at ATMs located in India and E-commerce and POS transactions within India are restricted to a maximum spend of Rs.2000 per day.</a> `,
    },
    {
      id: "faq2",
      qun: "Why is the card being stopped?",
      ans: `LWe at TripMoney are continuously working towards building the best Forex solutions for
     our customers. Keeping in line with the RBI regulations to ensure top-quality and secured financial products for the customers, we are working towards replacing the existing TripMoney Global Cash Card with a renewed and more rewarding card for all your Travel needs.  `,
    },
    {
      id: "faq3",
      qun: "What will happen to any existing balance in the card?",
      ans: `You are advised to withdraw any existing balance from the card to your Bank account by 15th October 2023 to avoid any last minute technical glitches. `,
    },
    {
      id: "faq4",
      qun: "How can I withdraw balance from my card to the bank account?",
      ans: `You can withdraw the money from the card by following these steps: 
<br/><br/>
   1. Open your Card Dashboard. 
   <br/><br/>
   2. Click on ‘Withdraw Money’ option 
   <br/><br/>
    3. Enter the amount to be withdrawn 
    <br/><br/>
    4.Enter your Bank Account Details 
    <br/><br/>
    5. Verify your identity by entering the OTP <br/><br/>
    6. Amount will be transferred to your account within 48-72 hours<br/><br/>
    <b>Please Note: Cardholder’s name and Bank account holder’s name must match. </b>
    `,
    },
    {
      id: "faq5",
      qun: "I have not received the amount in my bank account even after 72 hours. What should I do?",
      ans: `Sometimes, transactions may fail due to technical issues. In this case, the amount will be refunded to the card and the same will be visible under the ‘Transaction History’ section on the card dashboard. You can retry to withdraw the money into a different bank account in this case. `,
    },
    {
      id: "faq6",
      qun: "Will I be notified when my card gets closed?",
      ans: `All active TripMoney cards will be closed on 31st October 2023. No separate notification will be sent for the same.  `,
    },
    {
      id: "faq7",
      qun: "I am in the middle of an international trip. Will my card stop working?",
      ans: `All card will continue to function globally till 30th October 2023, but loading money to the cards will not be allowed post 30th September 2023. Any existing balance must be utilized or withdrawn before 30th October 2023. `,
    },
    {
      id: "faq8",
      qun: "I have an upcoming international trip and I was planning to use the card. What should I do now?",
      ans: `We got you covered. You can order the MultiCurrency Card for your upcoming trip and get an exciting gift voucher as a token of appreciation for being a valued TripMoney Global Cash Card user. <a href=${url}>Click here</a> to know more about MultiCurrency Card. `,
    },
  ];
};
