import axios from 'axios'
import { configureScope, withScope, captureException} from '@sentry/browser';
import uuidv4 from 'uuid/v4';
import {ERROR_NO_ACTION, LOGIN_REQUIRED, LOGIN_SESSION_STORAGE_KEY, SUCCESS } from '../constants';
import { getItemFromStorage, getZuserFromStore, getUserDeviceOS,getAppVersion, persistSpecificParamInCookie, getItemFromSessionStorage} from '../utils/common';

let baseURL = "";
// if (process.env.NODE_ENV !== "production") {
//     baseURL = "http://localhost:8080"
// }
const ai = axios.create({
    baseURL,
    timeout: 30000,
    validateStatus: function (status) {
        return status >= 200 && status < 300; // default
    },
});

const nonCriticalRequests = axios.create({
    baseURL,
    timeout: 20000,
    validateStatus: function (status) {
        return status >= 200 && status < 300; // default
    },
});

const cmsRequests = axios.create({
    baseURL,
    timeout: 20000,
    validateStatus: function (status) {
        return status >= 200 && status < 300; // default
    },
});
const pdtRequest = axios.create({
    baseURL,
    timeout: 20000,
    validateStatus: function (status) {
        return status >= 200 && status < 300; // default
    },
});

// Add a request interceptor
pdtRequest.interceptors.request.use(function (config) {
    config.headers['Content-Type'] = "application/json";
    if (process.env.NODE_ENV !== "production") {
        config.headers['X-DEBUG'] = true;
    }
    config.headers['Request-Data-Encoding'] = "json";
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});




export const setupInterceptors = (store) => {

    //setup interceptors for non critical requests to pass cookies.
    nonCriticalRequests.interceptors.request.use(function (config) {
        config.withCredentials = true; //For cross site calls for future to pass auth or session related cookies.
        return config;
    }, function (error) {
        return Promise.reject(error);
    });

    // Add a request interceptor
    ai.interceptors.request.use(function (config) {
        let uuid = uuidv4();
        config.withCredentials = true;
        config.headers['X-Request-Id'] = uuid;
        configureScope(function (scope) {
            scope.setTag("X-Request-Id", uuid);
        });
        if (window.location == window.parent.location) {
            let { zuser, zpartneruser } = getZuserFromStore(store);
            if (!zuser) {
                zuser = getItemFromStorage("zuser");
            }
            if (!zpartneruser) {
                zpartneruser = getItemFromStorage("zpartneruser");
            }
            if (zuser && zpartneruser) {
                config.headers['zuser'] = zuser;
                config.headers['zpartneruser'] = zpartneruser;
            }
        }
        // Do something before request is sent
        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });

    // Add a response interceptor
    ai.interceptors.response.use(function (response) {
        if (response.headers['z-csrf-token'] !== undefined) {
            store.dispatch({ type: "SET_OTHER_INFO", payload: { csrf: response.headers['z-csrf-token'] } })
            ai.defaults.headers.common['Z-Csrf-Token'] = response.headers['z-csrf-token'];
            nonCriticalRequests.defaults.headers.common['Z-Csrf-Token'] = response.headers['z-csrf-token'];
        }
        if (response.headers['lat'] !== undefined) {
            store.dispatch({ type: "SET_OTHER_INFO", payload: { lat: response.headers['lat'] } })
        }
        if (response.headers['long'] !== undefined) {
            store.dispatch({ type: "SET_OTHER_INFO", payload: { long: response.headers['long'] } })
        }
        if (response.status == 200) {
            if (response.data == null
                || (response.data.responseStatus !== "ERROR"
                    && response.data.responseStatus !== "SUCCESS")) {

                store.dispatch({
                    type: "SET_OTHER_INFO", payload: {
                        requestData: {
                            xRequestId: response && response.config && response.config.headers && response.config.headers['X-Request-Id'],
                            apiURL: (response && response.config && response.config.url ? response.config.url : "NOT FOUND"),
                            reponseData: JSON.stringify(response ? response.data : {}),
                            responseHeaders: JSON.stringify(response && response.headers ? response.headers : {}),
                            pageUrl: JSON.stringify(window.location)
                        }
                    }
                })
                withScope(scope => {
                    scope.setFingerprint(['INTERNAL_ERROR']);
                    scope.setExtra("url", response.config.url);
                    captureException(new Error("UNKNOWN_RESPONSE_STATUS"));
                });
                store.dispatch({ type: 'INTERNAL_ERROR' })
            }
            else if (response.data.responseStatus == "ERROR") {

                store.dispatch({
                    type: "SET_OTHER_INFO", payload: {
                        requestData: {
                            xRequestId: response && response.config && response.config.headers && response.config.headers['X-Request-Id'],
                            apiURL: (response && response.config && response.config.url ? response.config.url : "NOT FOUND"),
                            reponseData: JSON.stringify(response ? response.data : {}),
                            responseHeaders: JSON.stringify(response && response.headers ? response.headers : {}),
                            pageUrl: JSON.stringify(window.location)
                        }
                    }
                })

                if (response.data.error.code == "INTERNAL_ERROR") {
                    withScope(scope => {
                        scope.setFingerprint(['INTERNAL_ERROR']);
                        scope.setExtra("url", response.config.url);
                        captureException(new Error("INTERNAL_ERROR"));
                    });
                    store.dispatch({ type: 'INTERNAL_ERROR' })
                }
                if (response.data.error.code == "DISPLAY_ERROR_PAGE") {
                    withScope(scope => {
                        scope.setFingerprint(['DISPLAY_ERROR_PAGE']);
                        scope.setExtra("url", response.config.url);
                        captureException(new Error("DISPLAY_ERROR_PAGE"));
                    });
                    store.dispatch({ type: 'DISPLAY_ERROR_PAGE', payload: response.data.error, redirectUrl: (response.data.data ? response.data.data.redirectUrl : null), retry: false })
                }
                if (response.data.error.code == "DISPLAY_ERROR_PAGE_RETRY") {
                    withScope(scope => {
                        scope.setFingerprint(['DISPLAY_ERROR_PAGE_RETRY']);
                        scope.setExtra("url", response.config.url);
                        captureException(new Error("DISPLAY_ERROR_PAGE_RETRY"));
                    });
                    store.dispatch({ type: 'DISPLAY_ERROR_PAGE', payload: response.data.error, redirectUrl: (response.data.data ? response.data.data.redirectUrl : null), retry: true })
                }
                if (response.data.error.code == LOGIN_REQUIRED) {
                    store.dispatch({
                        type: "SET_OTHER_INFO", payload: {
                            loggedIn: false,
                            sessionEnded: true
                        }
                    });
                    withScope(scope => {
                        scope.setFingerprint([LOGIN_REQUIRED]);
                        scope.setExtra("url", response.config.url);
                        captureException(new Error(LOGIN_REQUIRED));
                    });
                    //redirect to init
                    store.dispatch({ type: LOGIN_REQUIRED })
                    persistSpecificParamInCookie(LOGIN_SESSION_STORAGE_KEY,false)
                }
                if (response.data.error.code == ERROR_NO_ACTION) {
                    //redirect to init
                    store.dispatch({ type: ERROR_NO_ACTION, payload: response.data.error })
                }


            }
        }
        if (response.config && response.config.url &&
            ((response.config.url.indexOf("users/current") != -1 && response.data && response.data.responseStatus === SUCCESS && response.data.data && response.data.data.userInfo) ||
                (response.config.url.indexOf("otu") != -1 && response.data && response.data.responseStatus === SUCCESS && response.data.data && !response.data.data.loginRequired) ||
                (response.config.url.indexOf("otp/verify") != -1 && response.data && response.data.responseStatus === SUCCESS))) {
            store.dispatch({
                type: "SET_OTHER_INFO", payload: {
                    loggedIn: true,
                    sessionEnded: false
                }
            });
        }
        return response;
    }, function (error) {
        let response = error.response;
        if (response && response.headers && response.headers['z-csrf-token'] !== undefined) {
            store.dispatch({ type: "SET_OTHER_INFO", payload: { csrf: response.headers['z-csrf-token'] } })
            ai.defaults.headers.common['Z-Csrf-Token'] = response.headers['z-csrf-token'];
            nonCriticalRequests.defaults.headers.common['Z-Csrf-Token'] = response.headers['z-csrf-token'];
        }

        store.dispatch({
            type: "SET_OTHER_INFO", payload: {
                requestData: {
                    xRequestId: response && response.config && response.config.headers && response.config.headers['X-Request-Id'],
                    apiURL: (response && response.config && response.config.url ? response.config.url : "NOT FOUND"),
                    reponseData: JSON.stringify(response ? response.data : {}),
                    responseHeaders: JSON.stringify(response && response.headers ? response.headers : {}),
                    pageUrl: JSON.stringify(window.location)
                }
            }
        })

        withScope(scope => {
            scope.setFingerprint(['NETWORK_REQUEST_ERROR']);
            scope.setExtra("url", error.config.url);
            scope.setExtra("csrf", ai.defaults.headers.common['Z-Csrf-Token'])
            if (response) {
                scope.setExtra("response-data", JSON.stringify(response.data))
                scope.setExtra("response-headers", JSON.stringify(response.headers))
            }
            captureException(error);
        });
        if ([401, 403].includes(response.status)) {
            if (response.data.error.code == LOGIN_REQUIRED) {
                store.dispatch({ type: LOGIN_REQUIRED })
                persistSpecificParamInCookie(LOGIN_SESSION_STORAGE_KEY,false)
                return Promise.resolve(error);
            }
        } else if (response.status > 399) {
            store.dispatch({ type: 'INTERNAL_ERROR' })
        }
        return Promise.reject(error);
    });

}

let headers = {
    'X-REQUEST-ID': uuidv4()
}

export const closeURL = (partner) => ai.get(`/ext/api/v1/partners/${partner}/close`)

export const linkUser = (partner, context) => ai.post(`/ext/api/v1/partners/${partner}/linkPartnerVerified`)

export const validateOTU = (partner, context, otuToken, ins_new_flow) =>
  ai.post(
    `/ext/api/v1/partners/${partner}/otu/${context}/${otuToken}${
      ins_new_flow ? `?ins_new_flow=${ins_new_flow}` : ""
    }`
  );

export const getUserLoginStatus = () => ai.post("/ext/api/v1/partners/tm/user/session");

export const getContextFromOTU = (partner, otuRefId, context) => ai.get(`/ext/api/v1/partners/${partner}/otudata/${otuRefId}`)

export const getConfig = (partner, context) => ai.get(`/ext/api/v1/partners/${partner}/krysalisConfig`) //check where its called

export const sendOTP = (partner, mobile) => ai.post(`/ext/api/v1/partners/${partner}/otp/send`, {
    mobile,
    mode: 'sms'
})
export const validateOTP = (partner, otp) => ai.post(`/ext/api/v1/partners/${partner}/otp/verify`, {
    otp
})

export const getUser = (partner, context) => ai.get(`/ext/api/v1/partners/${partner}/users/current?context=${context}`) //userId from this response is zuid

export const getCurrentStage = (partner, zuid, lender, stageId, data) => ai.get(`/ext/api/v1/partners/${partner}/users/${zuid}/lenders/${lender}/onboarding/stages/${stageId}?retry=${data ? data.retryCount : ''}`)
export const getStage = (partner, zuid,lender,deviceId = "")  => ai.get(`/ext/api/v1/partners/${partner}/users/${zuid}/lenders/${lender}/onboarding/stages/current/details`,{
    headers :{
        deviceId : deviceId
    }
})

export const submitStage = (partner, zuid, lender, stageId, data) => ai.post(`/ext/api/v1/partners/${partner}/users/${zuid}/lenders/${lender}/onboarding/stages/${stageId}?retry=${data.retryCount}`, data)

export const whatsAppConsent = (partner, zuid, whatsAppConsent) => ai.post(`/ext/api/v1/partners/${partner}/users/${zuid}/whatsAppConsent/${whatsAppConsent}`)

export const getZipInfo = (partner, zuid, pincode) => nonCriticalRequests.get(`/ext/api/v1/partners/${partner}/users/${zuid}/areaInfo/pincodes/${pincode}`)

export const getIfscInfo = (partner, zuid, ifsc) => nonCriticalRequests.get(`/ext/api/v1/partners/${partner}/users/${zuid}/branches/ifsc/${ifsc}`)

export const submitAddress = (partner, zuid, lender, stageId, data) => ai.put(`/ext/api/v1/partners/${partner}/users/${zuid}/lenders/${lender}/onboarding/stages/${stageId}`, data)

//Transaction
export const getPaymentOptions = (partner, ptxnid) => ai.post(`/ext/api/v1/partners/${partner}/txns/ptxnid/${ptxnid}/payOptions`)

export const initPaymentOptions = (partner, ptxnid, selectedOption) => ai.post(`/ext/api/v1/partners/${partner}/txns/ptxnid/${ptxnid}/init`, { selectedOption, amountX100: selectedOption.principal })

export const finalPayment = (partner, ztxnid, otp) => ai.post(`/ext/api/v1/partners/${partner}/txns/ztxnid/${ztxnid}/finalize`, { otp })

export const getIntermediateEligibility = (data) => ai.post(`/ext/api/v1/partners/${data.partner}/txns/ptxnid/${data.pTxnId}/intermediateEligibility`)

//Ledger
export const getLedger = (partner, context, userId) => ai.get(`/ext/api/v1/partners/${partner}/users/${userId}/${context}/details`)

export const getLedgerFaqs = (partner, context, userId) => ai.get(`/ext/api/v1/partners/${partner}/users/${userId}/${context}/faqs`)

export const getLedgerLoanDetails = (context, partner, txnId, userId) => ai.get(`/ext/api/v1/partners/${partner}/users/${userId}/${context}/txnId/${txnId}/extendedLedger`)

export const sendLedgerFeedback = (partner, context, userId, feedback) => ai.post(`/ext/api/v1/partners/${partner}/users/${userId}/${context}/feedback`, feedback)

export const getPayNowUrl = (partner, context, userId) => ai.get(`/ext/api/v1/partners/${partner}/users/${userId}/${context}/paymentLink`)

export const sendSOA = (data) => ai.post(`/ext/api/v1/partners/${data.partner}/users/${data.userId}/${data.context}/soa`, data.data);

//for saving personal info for zest

export const saveStageInfo = (partner, zuid, lender, stageId, data) => ai.put(`/ext/api/v1/partners/${partner}/users/${zuid}/lenders/${lender}/onboarding/stages/${stageId}?retry=${data.retryCount}`, data)

//get name from pan

export const validatePAN = (data) => ai.post(`/ext/api/v1/partners/${data.partner}/validatePan`, { pan: data.pan });
export const logout = (partner) => ai.post(`/ext/api/v1/partners/${partner}/user/logout`);

//send download app sms

export const sendDownloadAppSMS = (data) => ai.post(`/ext/api/v1/admin/notification/send`,
    {
        templateName: "DOWNLOAD_APP_SMS",
        partner: data.partner,
        lender: data.lender,
        channel: "SMS",
        data: null,
        type: "DOWNLOAD__APP_SMS",
        to: data.mobile
    });

//Insurance
export const getUserData = (data) => nonCriticalRequests.get(`/ext/api/v1/partners/${data.partner}/insurance/user`);
export const getProducts = (data) => ai.get(`/ext/api/v1/partners/${data.partner}/insurance/products?category=insurance&sub_category=${data.subCategory}`);
export const checkout = (data) => ai.post(`/ext/api/v1/partners/${data.partner}/insurance/checkout`, data);
export const getOrders = (data) => ai.get(`/ext/api/v1/partners/${data.partner}/insurance/orders`);
export const sendPolicyMail = (data) => ai.post(`/ext/api/v1/partners/${data.partner}/insurance/email/${data.bookingID}`);
export const sendPolicyMailNonLoggedIn = ({ key }) => ai.post(`/ext/api/v1/insurance/policy/email?tokenID=${key}`);
export const getFAQ = (data) => ai.get(`/ext/api/v1/config/faq/${data.configKey}?raw=${data.raw ? "true" : "false"}`);
export const verifyOtpForInsurance = (data) => ai.post(`/ext/api/v1/partners/${data.partner}/insurance/verifyOtp/${data.CartID}`, { otp: data.otp });
export const editPolicyDetails = (data) => ai.put(`/ext/api/v1/partners/${data.partner}/insurance/order/${data.bookingID}/refId/${data.refID}`, data.values);

export const validatePLOTP = (partner, otp, pTxnId, userId) => ai.post(`/ext/api/v1/partners/${partner}/users/${userId}/otp/validate`, {
    otp, pTxnId
})
export const getLoanCards = (data) => ai.get(`/ext/api/v1/partners/${data.partner}/users/${data.userId}/cards`);
export const sendPLOTP = (data) => ai.get(`/ext/api/v1/partners/${data.partner}/users/${data.userId}/cardAction`, {
    "product": "PL"
});

export const sendAppLinkToMobile = (data) => ai.get(`/ext/api/v1/getAppLink/${data.mobile}`);

export const getProductCards = (data) => ai.post(`/ext/api/v1/partners/tm/users/${data.userId}/cards`, { context: "homepage" });

// For Insurance Postsale
export const getAddOnsDetails = ({ partner, bookingID }) => ai.get(`ext/api/v1/partners/${partner}/insurance/add-ons${bookingID ? `/${bookingID}` : ""}`);
export const getCheckoutDetails = ({ booking_id, product_id, partner }) => ai.post(`ext/api/v1/partners/${partner}/insurance/postSalesCheckout`, { booking_id, product_id });
export const getThankYouPageDetails = ({ bookingId, partner }) => ai.get(`ext/api/v1/partners/${partner}/insurance/booking?bookingID=${bookingId}`);

//For Standalone Insurance
export const getStandaloneInsuranceProducts = (data) => ai.post(`/ext/api/v1/partners/${data.partner}/insurance/standalone/products?platform=${data.platform}`, { userSearchIntent: data.userSearchIntent });
export const getStandaloneCheckoutDetails = (data) => ai.post(`/ext/api/v1/partners/${data.partner}/insurance/standalone/checkout`, { ...data.checkout_details }, {
    headers: {
        'app-os': getUserDeviceOS(),
        'app-version': getAppVersion()
    }
});
export const getSearchHistory = ({ partner }) => ai.get(`/ext/api/v1/partners/${partner}/insurance/standalone/searches`);
export const getStandaloneBookingDetails = ({ bookingID, context }) => ai.get(`/ext/api/v1/insurance/standalone/booking?bookingID=${bookingID}&context=${context}`);

//pdt 
export const submitPDTEvent = (data) =>
  pdtRequest.post(`/ext/api/v1/insurance/pdt`, { ...data.ui_pdt_data });

export const getInsuranceDetails = (data) => ai.get(`/ext/api/v1/insurance/details?refID=${data.refID}&key=${data.key}`);


export const getUserDetails = (data) => ai.get(`/ext/api/v1/leadgen-service/wishfin/users/${data.userId}/getDetails`, { headers: { Authorization: data.accessToken } });
export const verifyOtpCreditCard = (data) => ai.post(`/ext/api/v1/leadgen-service/verifyOTP`, { ...data });
export const checkAccessTokenStatus = (data) => ai.post(`/ext/api/v1/leadgen-service/wishfin/users/${data.userId}/fetchTokenStatus`, { ...data }, { headers: { Authorization: data.accessToken } });
export const getNewAccessToken = (data) => ai.post(`/ext/api/v1/leadgen-service/refreshToken`, { ...data });

export const getTripLandingPageSections = (data) => nonCriticalRequests.post(`/ext/api/v1/partners/${data.partner}/users/${data.userId}/sections`, { sections: data.sections, source: data.source });

// Travel Loan Api
export const getTravelLoan = (data,lender,partner) => nonCriticalRequests.post(`/ext/api/v1/partners/${partner}/lenders/${lender}/travelLoan/getTravelLoanStatus`,data)

// CMS service
export const getCmsContent = (type) => cmsRequests.get(`/ext/cms-service/api/${type}`);
export const getCmsContentNoCache = (type) => cmsRequests.get(`/ext/cms-service-no-cache/api/${type}`);
// export const getCmsListContent = (type, id) => cmsRequests.get(`/ext/cms-service/api/${type}/${id}`);
//for transaction resent otp only to be called for ZEST
export const resendOTPForZestTransaction = (data) => ai.post(`/ext/api/v1/partners/${data.partner}/txns/ztxnid/${data.ztxnId}/retry`);

export const logPDT = (data) => pdtRequest.post('/ext/api/v1/pdt', [data]);

//for insurance
export const registerClaim = (data) => ai.post(`/ext/api/v1/partners/${data.partner}/insurance/claim/${data.booking_id}`, { booking_id: data.booking_id, date_of_loss: data.date_of_loss });
export const getClaimStatus = (data) => ai.get(`/ext/api/v1/partners/${data.partner}/insurance/claim/${data.booking_id}`);
