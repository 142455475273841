import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const getConfig = createAction('GET_CONFIG');
export const setConfig = createAction('SET_KRYSALIS_CONFIG');

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
        setConfig,
      (state, action) => {
        return action.payload
      } 
    ]
  ]),
  {} 
);

// SELECTORS
export const getConfigState = state => state.config;
 
