import { createBrowserHistory } from 'history';

export default class History {
    static historyObject = null;

    static getHistoryObject() {
        if(!History.historyObject) {
            History.historyObject = createBrowserHistory({ basename: process.env.PUBLIC_URL });
        }

        return History.historyObject;
    }
}