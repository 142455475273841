/* eslint-disable no-console */
/* eslint-disable no-constant-condition */
import { take, select } from 'redux-saga/effects';
import { addBreadcrumb, Severity } from '@sentry/browser';


/**
 * Log all redux actions saga
 */
export default function* logActionsSaga() {
    console.log("Log Actions", "background: red; color: yellow; font-size: x-large");


    const stringify = (data) => {
      try{
        let str = JSON.stringify(data);
        return str;
      }catch(e){
        return "";
      }
    }

    while (true) {
      const action = yield take();
      addBreadcrumb({
        category: action.type,
        message: stringify(action.payload),
        level: Severity.Info
      });
      if (process.env.NODE_ENV !== 'production') {
        console.group(`%c${action.type}`, "background: red; color: white");
        console.info('dispatching', action);

        const nextState = yield select();
        console.log('next state....', nextState);
        console.groupEnd(action.type);
      }
    }

}
 
