import { createAction, handleActions } from 'redux-actions';

// ACTION TO FETCH CURRENT STAGE
export const getCurrentLenderStage = createAction('FETCH_CURRENT_STAGE');
export const setCurrentLenderStage = createAction('SET_LENDER_CURRENT_STAGE');
export const clearCurrentLenderStage = createAction('CLEAR_LENDER_CURRENT_STAGE');

// REDUCERS
export const reducer = handleActions(
    new Map([
      [
        setCurrentLenderStage,
        (state, action) => {
          return action.payload
        } 
      ],
      [
        clearCurrentLenderStage,
        (state, action) => {
          return { data : null}
        } 
      ]
    ]),
    { data : null}
  );