import { takeLatest, put, select } from 'redux-saga/effects';
import * as zero from '../api';
import { setOtherInfo } from '../reducers/otherInfo';
import { getUserState } from '../reducers/user';

export default function* watchGetZipInfo() {
    yield takeLatest('GET_ZIP_INFO', getData)
}

function* getData(action) {
    try {
        yield put(setOtherInfo({zipInfo: ''}))
        const { partner, zipCode } = action.payload;
        const user = yield select(getUserState);
        let response = yield zero.getZipInfo(partner, user.data.userInfo.userId, zipCode)
        if (response.data.responseStatus == "SUCCESS") {
            yield put(setOtherInfo({zipInfo: response.data.data.areaInfo}))
        } else {
            yield put(setOtherInfo({zipInfo: ''}))
            //yield put(setError({...response.data.error, viewed: false}))
        }
    } catch(e) {
        yield put(setOtherInfo({zipInfo: ''}))
        //yield put(setError({viewed: false, description: 'Something went wrong'}))
        console.log(e)
    }
}