import { takeLatest, put } from 'redux-saga/effects';
import * as zero from '../api';
import { setLoading } from '../reducers/loading';
import { history } from "../containers/Router";
import { otpValidated } from '../reducers/otp'
import { setError } from '../reducers/error';
import { getCurrentStage } from '../reducers/stage';
import { redirectIntercept } from '../utils/common';


import { ERROR_NO_ACTION, LOGIN_REQUIRED } from '../constants';

export default function* watchValidateOTP() {
    yield takeLatest('VALIDATE_OTP_PL', validateOTP)
}

function* validateOTP(action) {
    try {
        yield put(setLoading(true))
        const { partner, otp, userId, pTxnId } = action.payload;
        let validateOTPResponse = yield zero.validatePLOTP(partner, otp, pTxnId, userId);
        yield put(setLoading(false))
        if (validateOTPResponse.data && validateOTPResponse.data.responseStatus === "SUCCESS") {
            yield put(otpValidated(true));
            yield put(getCurrentStage({partner, context: "transaction", pTxnId, userId, lender: "icici", stage: "current"}))
        } else {
            let error = validateOTPResponse.data.error;
            if(!(error!= null && (error.code == LOGIN_REQUIRED || error.code == ERROR_NO_ACTION))){
                window.dataLayer.push({
                    'event': 'eventTracking',
                    'category': 'OTP',
                    'action': 'OTP_VALIDATION_FAILED',
                    'label': validateOTPResponse.data.error.description,
                });
                yield put(setError({...validateOTPResponse.data.error, viewed: false}))
            }            
        }
    } catch(e) {
        window.dataLayer.push({
            'event': 'eventTracking',
            'category': 'OTP',
            'action': 'OTP_VALIDATION_FAILED',
            'label': e.message,
        });
        yield put(setLoading(false))
        redirectIntercept("/error")
        console.error(e)
    }
}