import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const getUser = createAction('GET_USER');
export const getUserDetails = createAction('GET_USER_DETAILS');
export const setUser = createAction('SET_USER');
export const clearUser = createAction('CANCEL_GET_USER');

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
        setUser,
      (state, action) => {
        return action.payload
      },
      clearUser,
      (state, action) => {
        return { data: null, error: null}
      } 
    ]
  ]),
  { data: null, error: null}
);

// SELECTORS
export const getUserState = state => state.user;
 
