import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const setAllOpenRooms = createAction('SET_ALL_OPEN_ROOMS');
export const roomCreated = createAction('ROOM_CREATED');
export const roomEnded = createAction('ROOM_ENDED');
export const participantJoined = createAction('PARTICIPANT_JOINED');
export const participantDisconnected = createAction('PARTICIPANT_DISCONNECTED');
export const startWebSocket = createAction('START_WEBSOCKET')

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
      setAllOpenRooms,
      (state, action) => {
        return {...action.payload}
      } 
    ],
    [
        roomCreated,
        (state, action) => {
            state[action.payload.room_sid] = {
                "sid": action.payload.room_sid,
                "unique_name": action.payload.unique_name,
                "date_created": action.payload.timestamp,
                "participants": []
            };
            return {...state}
        } 
    ],
    [
        roomEnded,
        (state, action) => {
            delete state[action.payload.room_sid] 
            return {...state}
        } 
    ],
    [
        participantJoined,
        (state, action) => {
            if (state[action.payload.room_sid]) {
                state[action.payload.room_sid].participants.push({
                    "identity": action.payload.participant_id,
                    "date_created": action.payload.timestamp,
                    "sid": action.payload.participant_sid,
                });
                return {...state}
            }
        } 
    ],
    [
        participantDisconnected,
        (state, action) => {
            if (state[action.payload.room_sid]) {
                let currentParticipants = state[action.payload.room_sid].participants;
                
                state[action.payload.room_sid].participants = currentParticipants.filter((value, index, array) => 
                    value.sid !== action.payload.participant_sid
                )
            }
            return {...state}
        } 
    ],
  ]),
  {}
);

// SELECTORS
export const getRooms = state => state;
 
