import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const validateOTU = createAction('VALIDATE_OTU');
export const otuValidated = createAction('OTU_VALIDATED');
export const otuValidationFailed = createAction('OTU_VALIDATION_FAILED');

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
        otuValidated,
      (state, action) => {
        return action.payload
      } 
    ],
    [
        otuValidationFailed,
      (state, action) => {
        return action.payload
      } 
    ],

  ]),
  {
    "error": null,
    "data": null
  }
);

// SELECTORS
export const getLoading = state => state;
 
