import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const getStageInfo = createAction('GET_STAGE_INFO');
export const setStageInfo = createAction('SET_STAGE_INFO');

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
      setStageInfo,
      (state, action) => {
        return action.payload
      } 
    ]
  ]),
  {} 
);

// SELECTORS
export const getStageInfoState = state => state.stageInfo;
 
