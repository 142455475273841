import { takeLatest, put } from 'redux-saga/effects';
import * as zero from '../api';
import { setContext } from '../reducers/context';
import { setLoading } from '../reducers/loading';
import { history } from "../containers/Router";
import { ERROR_NO_ACTION, LANDING, LOGIN_REQUIRED } from '../constants';
import { redirectIntercept } from '../utils/common';

export default function* watchGetContextFromOtu() {
    yield takeLatest('GET_CONTEXT_FROM_OTU', getContextFromOtu)
}

function* getContextFromOtu(action) {
    let validateOTUResponse;
    try {
        const { context } = action.payload;
        if (context != LANDING) {
            yield put(setLoading(true))
        }
        const { partner, otuRefId } = action.payload
        let contextResponse = yield zero.getContextFromOTU(partner, otuRefId)
        yield put(setLoading(false))
        if (contextResponse.data.responseStatus == "SUCCESS") {
            yield put(setContext({ ...contextResponse.data }));
        } else {
            let error = contextResponse.data.error;
            if (!(error != null && error.code == LOGIN_REQUIRED)) {
                redirectIntercept("/error")
            }
        }

    } catch (e) {
        yield put(setLoading(false))
        redirectIntercept("/error")
        console.log(e)
    }
}