import { takeLatest, put, select } from 'redux-saga/effects';
import * as zero from '../api';
import { setLoading } from '../reducers/loading';
import { tokenDetails } from '../reducers/otpCreditCard';
import { getNewToken, saveOtpDetailsCreditCard } from '../reducers/otpCreditCard';

export default function* watchOtpData() {
    yield takeLatest('CHECK_ACCESS_TOKEN_STATUS', checkTokenStatus)
}

function* checkTokenStatus(action) {
    const { accessToken, refreshToken, userId } = action.payload;
    try {
        yield put(setLoading(true))
        let response = yield zero.checkAccessTokenStatus({ accessToken, userId });
        yield put(setLoading(false))
        if (response && response.data && response.data.error && response.data.error.code === 'AUTHENTICATION_ERROR') {
            yield put(getNewToken({ refreshToken, userId }))
        } else {
            const tokens = yield select(tokenDetails);
            let refreshTokenFromStore = tokens && tokens.tokenDetailsCreditCard && tokens.tokenDetailsCreditCard.refreshToken;
            let accessTokenFromStore = tokens && tokens.tokenDetailsCreditCard && tokens.tokenDetailsCreditCard.accessToken;
            let userIdFromStore = tokens && tokens.tokenDetailsCreditCard && tokens.tokenDetailsCreditCard.userId;
            if (!refreshTokenFromStore || !accessTokenFromStore || !userIdFromStore) {
                yield put(saveOtpDetailsCreditCard({ tokenDetailsCreditCard: { accessToken, refreshToken, userId } }))
            }
        }

    } catch (e) {
        yield put(setLoading(false))
        console.log(e)
    }
}