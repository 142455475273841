import React from 'react';
import ReactDOM from 'react-dom';
import {init as SentryInit} from '@sentry/browser';
import "core-js/stable";
import "regenerator-runtime/runtime";
import AppProvider from './components/AppProvider';

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_RELEASE) {
    SentryInit({
      dsn: "https://02eb1443302b4668a6e6a610ee98885d@bugs.goibibo.com/167",
      release: process.env.REACT_APP_SENTRY_RELEASE,
    });
} else {
SentryInit({dsn: "https://02eb1443302b4668a6e6a610ee98885d@bugs.goibibo.com/167"});
}


if (window.mmt_android_bridge && window.mmt_android_bridge.hideProgress) {
  console.log("Loader closed")
  window.mmt_android_bridge.hideProgress();
}

ReactDOM.render(<AppProvider />, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
