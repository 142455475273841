import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const bankSelected = createAction('BANK_SELECTED');

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
        bankSelected,
      (state, action) => {
            return {...state, selectedBank: action.payload}
      } 
    ]
  ]),
  {selectedBank: null}
);

// SELECTORS
export const getSelectedBank = state => state.bankSelected;
 
