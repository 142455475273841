import React, { Component, lazy, Suspense } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import getStore from '../store';
import  { setupInterceptors } from '../api'
import  { setupForexInterceptors } from '../containers/Forex/api'

import allSagas from '../sagas/sagas';
import forexSagas from '../containers/Forex/sagas'
import CardLoader from './CardLoader';

let App = lazy(() => import('../containers/App'))
export const {store, persistor} = getStore([...allSagas, ...forexSagas]);

setupInterceptors(store);
setupForexInterceptors(store);

export default class AppProvider extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={<CardLoader />} persistor={persistor}>
          <Suspense fallback={<CardLoader />}>
            <App />
          </Suspense>
        </PersistGate>
      </Provider>
    )
  }
}