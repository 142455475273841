import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const setLedgerFeedback = createAction('SET_LEDGER_FEEDBACK');
export const ledgerFeedbackSent = createAction('LEDGER_FEEDBACK_SENT');


// REDUCERS
export const reducer = handleActions(
  new Map([
    [
        ledgerFeedbackSent,
        (state, action) => {
            return {...state, ledgerFeedbackSent: action.payload}
        }
    ]
  ]),
  {ledgerFeedbackSent: false}
);
 
