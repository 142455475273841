import * as zero from '../api';
import { INSURANCE } from '../constants';
import { getItemFromStorage } from "../utils/common";
import { takeEvery } from "redux-saga/effects";
import { createAction } from "redux-actions";

export const publishPDTData = createAction('PUBLISH_PDT_EVENT');

export default function* watchPublishPDTEvent() {
    yield takeEvery('PUBLISH_PDT_EVENT', publishPDTEventWorker)
}

function* publishPDTEventWorker(action) {
   let { partner, context, eventType , eventData, actionName,zuser} = action.payload
    if (context.toLowerCase() == INSURANCE){
        try{
            if (!zuser) {
                zuser = getItemFromStorage("zuser");
            }
            const data = {
                partner,
                ui_pdt_data : {
                    eventData,
                    eventType,
                    actionName,
                    ref : zuser ? zuser : ""
                }
            }
            console.log("publishing pdt event data for ref id : ",zuser)
            
            const response = yield zero.submitPDTEvent(data)
            if (response?.data?.data?.status && (response?.data?.data?.status) !== "Success"){
                console.error("responses status not equal to success for pdt event for ref id : ",zuser)
            }
        }
        catch(e){
            console.error("failed to publish pdt event",e)
        }
    }
}