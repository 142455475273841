import React from 'react';
import UAParser from 'ua-parser-js';
import { Cookies } from 'react-cookie';
import dayjs from "dayjs";

import fullerton from '../assets/images/fullerton.png';
import cashe from '../assets/images/logo/casheLogo.png';
import incred from '../assets/images/logo/incredLogo.png';
import lkart from '../assets/images/logo/lendingKartLogo.svg';
import moneyView from '../assets/images/logo/moneyViewLogo.png';
import tataCapital from '../assets/images/logo/tataCapitalLogo.png';
import yesbank from '../assets/images/logo/yes.png';
import bajaj from '../assets/images/logo/bajajFinserv.png';

//for wishfin
import airPlatinum from '../assets/images/wishfin/sbi/Air India SBI Platinum Card.png';
import airSignature from '../assets/images/wishfin/sbi/Air India SBI Signature Card.png';
import apollo from '../assets/images/wishfin/sbi/APOLLO SBI CARD.png';
import bpcl from '../assets/images/wishfin/sbi/BPCL SBI Card.png';
import vistaraPrime from '../assets/images/wishfin/sbi/Club Vistara SBI Card PRIME.png';
import vistara from '../assets/images/wishfin/sbi/Club Vistara SBI Card.png';
import ima from '../assets/images/wishfin/sbi/DOCTOR\'S SBI CARD (IMA).png';
import doctor from '../assets/images/wishfin/sbi/DOCTOR\'S SBI CARD.png';
import etihad from '../assets/images/wishfin/sbi/ETIHAD GUEST SBI CARD.png';
import etihadPremier from '../assets/images/wishfin/sbi/ETIHAD GUEST SBI PREMIER CARD.png';
import fbb from '../assets/images/wishfin/sbi/Fbb SBI STYLEUP Contactless Card.png';
import irctc from '../assets/images/wishfin/sbi/IRCTC SBI Platinum Card.png';
import elite from '../assets/images/wishfin/sbi/SBI ELITE Card.png';
import simplySave from '../assets/images/wishfin/sbi/SBI SimplySAVE Card.png';
import simplyClick from '../assets/images/wishfin/sbi/SimplyCLICK SBI Card.png';
import irctcPremier from '../assets/images/wishfin/sbi/SBI IRCTC Premier card.png'
import rupay from '../assets/images/wishfin/sbi/Rupay.png';

import manhattan from '../assets/images/wishfin/scb/manhattan.jpeg';
import platinumRewards from '../assets/images/wishfin/scb/platinumRewards.jpeg';
import superValue from '../assets/images/wishfin/scb/superValue.jpg'
import digiSmart from '../assets/images/wishfin/scb/digiSmart.png';

import History from '../containers/Router/getBrowserHistory';
import { ANDROID, APP_VERSION, FOREX, GoIbibo, INSURANCE, INS_NEW_FLOW, INS_UI_EVENTS, IOS, MakeMyTrip,NEW_INSURANCE_DOMAIN, PROTECTED_DEEPLINKS_MAP, REDIRECT_, REJECTED_, SUSPENDED_, TripMoney, UNKNOWN, UNPROTECTED_API_FOREX_ROUTES, WAITLIST_, DEEPLINK, DEEPLINK_PARAM_ORDER_ID, SOURCE_PLATFORM, COOKIES, MMT_SUB_DOMAIN, GI_SUB_DOMAIN, LOGIN_SESSION_STORAGE_KEY, MMT_FOREX_URL, IS_INITOTU_SESSION_KEY, INSTANT_CASH,  MMT_FOREX_COOKIE, INS_MMT_SUBDOMAIN, INS_GI_SUBDOMAIN } from '../constants';
import { GI, TM_AUTH_COOKIE } from '../containers/Forex/util/constants';
import { getForexBasePath } from '../containers/Forex/util';
import { publishPDTData } from '../sagas/publishPDTEvent';
import { getCurrentLenderStage } from '../reducers/lendingCurrentStage';
import { store } from '../components/AppProvider';
import closeWebViewFunc from '../components/close';
import { getPartner } from '../containers/Forex/util';
import { getDeviceType, isMobile } from '../containers/Forex/util/common';

const history = History.getHistoryObject();


export const getLendingCommonPageText = (config,xData) => {
    
    if(xData?.nextStep){

     switch (xData?.nextStep) {
        case WAITLIST_ :  
                return { title : (xData?.title && xData?.title.length > 0) ? xData?.title : "You are now on the waitlist", description :  (xData?.description && xData?.description.length > 0) ?  xData?.description : "We have accepted your request for travel loan. You will be notified when once approved."} 
        case REJECTED_ : 
                return { title : (xData?.title && xData?.title.length > 0) ? xData?.title :  "Rejected", description : (xData?.description && xData?.description.length > 0) ?  xData?.description : "Your travel loan has been rejected by the lending partner. Please try again after 45 days" }
        case SUSPENDED_ : 
                return { title : (xData?.title && xData?.title.length > 0) ? xData?.title :  "Suspended", description : (xData?.description && xData?.description.length > 0) ?  xData?.description : "Your credit limit has been suspended by the lending partner axio. Please reach out to them for queries."}
        default : 
             return { title  : "Error ", description : "There is some error please try again after some time" }  
     }
    }
    return  { title  : "Error ", description : "There is some error please try again after some time"}  
}

export const getUserStatus = (user) => {
    return user && user.data && user.data.loanInfo && user.data.loanInfo.status ? user.data.loanInfo.status : "";
}

export const isLoggedIn = (otu)=>{
    return (otu !== null && otu.data && !otu.data.loginRequired)
}

export const getInitOtuLink = (partner, product) => {
    if (partner === MakeMyTrip) {
        if(product === INSURANCE && window.location.hostname === NEW_INSURANCE_DOMAIN){
            return `https://${window.location.hostname}/ext/api/v1/partners/${partner}/initOTU?product=${product}`
        }
        return `https://lending.makemytrip.com/ext/api/v1/partners/${partner}/initOTU?product=${product}`
    }
    else if (partner === GoIbibo) {
        if(product === INSURANCE && window.location.hostname === NEW_INSURANCE_DOMAIN){
            return `https://${window.location.hostname}/ext/api/v1/partners/${partner}/initOTUGI?product=${product}`
        }
        return `https://lending.goibibo.com/ext/api/v1/partners/${partner}/initOTUGI?product=${product}`
    }
}

export function isWebRtcEnabled(navigator) {
    return (((navigator.mediaDevices && navigator.mediaDevices.getUserMedia) || navigator.getUserMedia));

}

export function closeOrRedirect(closeData, user, partner, dispatch, logoutAction) {
    //redirecting to close url

    if (closeData && closeData.data) {
        if (window.app_bridge || window.mmt_android_bridge)
        {
            closeWebViewFunc(false);
        }
        else
        {
            if (user && user.data && user.data.loanInfo && (user.data.loanInfo.status === "ACTIVE" || user.data.loanInfo.status === "SUSPENDED")) {
            redirectInterceptHref(closeData.data.closeUrl);
        } else {
            if (partner === "tm")
                dispatch(logoutAction({ redirectURL: (closeData && closeData.data && closeData.data.closeUrl ? closeData.data.closeUrl : "/") }));
            else
                redirectInterceptHref(closeData.data.closeUrl);
        }
        }
    }
    else {
        if (window.mmt_android_bridge) {
            window.mmt_android_bridge.closeAndRefreshWebView();
        } else {
            window.history.go(-(window.history.length - 1))
        }
    }
}

export const getImageClassName = (productName, image = false) => {
    if (productName) {
        if (productName.toLowerCase().indexOf("bajaj") != -1) {
            if (!image)
                return "bajaj__icon";
            else
                return <span className={`bajaj__logo append-right10`}><img className="bajaj__logo" src={bajaj} /></span>

        }

        if (productName.toLowerCase().indexOf("faircent") != -1) {
            if (!image)
                return "faircent__logo";
            else
                return <span className={`tm__sprite faircent__logo append-right10`}></span>
        }

        if (productName.toLowerCase().indexOf("dmi") != -1) {
            if (!image)
                return "dmi__logo";
            else
                return <span className={`tm__sprite dmi__logo append-right10`}></span>
        }

        if (productName.toLowerCase().indexOf("clix") != -1) {
            if (!image)
                return "clex__logo";
            else
                return <span className={`tm__sprite clex__logo append-right10`}></span>
        }

        if (productName.toLowerCase().indexOf("paysense") != -1) {
            if (!image)
                return "paysense__logo";
            else
                return <span className={`tm__sprite paysense__logo append-right10`}></span>
        }

        if (productName.toLowerCase().indexOf("hdfc") != -1) {
            if (!image)
                return "hdfc__logo";
            else
                return <span className={`tm__sprite hdfc__logo append-right10`}></span>
        }

        if (productName.toLowerCase().indexOf("fullerton") != -1) {
            if (!image)
                return "fullerton__logo";
            else
                return <span className={`fullerton__logo append-right10`}><img className="fullerton__logo" src={fullerton} /></span>

        }

        if (productName.toLowerCase().indexOf("cashe") != -1) {
            if (!image)
                return "cashe__logo";
            else
                return <span className={`cashe__logo append-right10`}><img className="cashe__logo" src={cashe} /></span>
        }

        if (productName.toLowerCase().indexOf("incred") != -1) {
            if (!image)
                return "incred__logo";
            else
                return <span className={`incred__logo append-right10`}><img className="incred__logo" src={incred} /></span>
        }

        if (productName.toLowerCase().indexOf("lending") != -1) {
            if (!image)
                return "lending__logo";
            else
                return <span className={`lending__logo append-right10`}><img className="lending__logo" src={lkart} /></span>
        }

        if (productName.toLowerCase().indexOf("money") != -1) {
            if (!image)
                return "money__logo";
            else
                return <span className={`money__logo append-right10`}><img className="money__logo" src={moneyView} /></span>
        }

        if (productName.toLowerCase().indexOf("tata") != -1) {
            if (!image)
                return "tata__logo";
            else
                return <span className={`tata__logo append-right10`}><img className="tata__logo" src={tataCapital} /></span>
        }

        if (productName.toLowerCase().indexOf("yes") != -1) {
            if (!image)
                return "yes__logo";
            else
                return <span className={`yes__logo append-right10`}><img className="yes__logo" src={yesbank} /></span>
        }

    }
}

export const getCardImage = (name) => {

    switch (name) {
        case "Air India SBI Platinum Card": return airPlatinum;
        case "Air India SBI Signature Card": return airSignature
        case "APOLLO SBI CARD": return apollo;
        case "BPCL SBI Card": return bpcl;
        case "Club Vistara SBI Card PRIME": return vistaraPrime;
        case "Club Vistara SBI Card": return vistara;
        case "DOCTOR'S SBI CARD (IMA)": return ima;
        case "DOCTOR'S SBI CARD": return doctor;
        case "ETIHAD GUEST SBI CARD": return etihad;
        case "ETIHAD GUEST SBI PREMIER CARD": return etihadPremier;
        case "Fbb SBI STYLEUP Contactless Card": return fbb;
        case "IRCTC SBI Platinum Card": return irctc;
        case "SBI ELITE Card": return elite;
        case "SBI SimplySAVE Card": return simplySave;
        case "SimplyCLICK SBI Card": return simplyClick;
        case "IRCTC SBI Card Premier": return irctcPremier;
        case "SBI IRCTC Rupay Card": return rupay;
        //case "Visa Infinite": return visaInfinite;
        //case "Emirates Platinum": return emiratesPlatinum;
        case "Standard Chartered Manhattan Platinum Card": return manhattan;
        case "Standard Chartered Super Value Titanium card": return superValue;
        case "Standard Chartered Platinum Rewards card": return platinumRewards;
        //case "Inner Circle Platinum / Landmark Rewards Cards": return innerCircle;
        //case "Yatra co - branded": return yatraCo;
        //case "Ultimate Credit Card": return ultimateCC;
        case "Standard Chartered DigiSmart Card": return digiSmart;

    }
}

export const addRemoveCard = (card, dispatch, selectedCards, setOtherInfo, setError) => {

    let index = selectedCards.findIndex(element => element.name === card.name);
    if (index != -1) {
        let tempCardsSelected = [...selectedCards];
        tempCardsSelected.splice(index, 1);
        dispatch(setOtherInfo({ selectedCards: [...tempCardsSelected] }));
    } else {
        if (selectedCards.length < 2) {
            dispatch(setOtherInfo({ selectedCards: [...selectedCards, { ...card, icon: getCardImage(card.name) }] }));
        } else {
            dispatch(setError({ viewed: false, description: "Only 2 cards can be compared" }));
        }
    }
}

export const addRuppeeSymbol = (str) => {
    return str.replace(/(\<rs\>)/gm, "Rs.");
}

export const parseUserAgent = (ua, eventIdentifier, cardClicked, partner, otherInfo) => {

    let parser = new UAParser().setUA(ua).getResult();
    let app_version = navigator.userAgent.split("MMT_")[1];
    app_version = "MMT_" + app_version;

    return {
        "context": {
            "topic_name": "tm_card_click",
            "template_id": "21959",
            "server_timestamp": new Date().getTime(),
            "lob": "LEN",
            "environment": "prod"
        },
        "event_details": {
            "event_name": "card_click",
            "event_identifier": eventIdentifier,
            "card_clicked": cardClicked
        },
        "user": {
            "partner": partner
        },
        "device": {
            "latitude": (otherInfo ? otherInfo.lat : ""),
            "longitude": (otherInfo ? otherInfo.long : ""),
            "operating_system": parser && parser.os && parser.os.name ? parser.os.name : "",
            "type": parser && parser.device && parser.device.type ? parser.device.type : "",
            "operating_system_version": parser && parser.os && parser.os.version ? parser.os.version : "",
            "browser": parser && parser.browser && parser.browser.name ? parser.browser.name : "",
            "browser_version": parser && parser.browser && parser.browser.version ? parser.browser.version : "",
            "application_version": app_version,
            "user_agent": ua
        }
    };
}

export const getUserDeviceOS = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
        return ANDROID;
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return IOS;
    }

    return UNKNOWN;
}

export const convertDateFormat = (fromFormat, date, toFormat) => {
    let customParseFormat = require('dayjs/plugin/customParseFormat')
    dayjs.extend(customParseFormat)
    if (date && fromFormat && toFormat) {
        return dayjs(date, fromFormat).format(toFormat);
    }
}

export const isValidDateFormat = (date, format = 'DD/MM/YYYY') => {
    let customParseFormat = require('dayjs/plugin/customParseFormat')
    dayjs.extend(customParseFormat)
    if (date && format) {
        return dayjs(date, format, true).isValid()
    }
    return false
}

export const persistSpecificParamInCookie = (
  specificParamKey,
  specificParamValue,
  options = { path: "/" }
) => {
  const c = new Cookies();
  c.set(specificParamKey, specificParamValue, options);
};

export const getSpecificCookie = (specificParamKey) => {
    const c = new Cookies();
    return c.get(specificParamKey, { path: '/' }) ?? "";
}

export const callPersistUtmParams = (queryParam) => {
    let utm_source = queryParam.get('utm_source');
    let utm_medium = queryParam.get('utm_medium');
    let utm_campaign = queryParam.get('utm_campaign');
    let utm_lender = queryParam.get('utm_lender');
    let utm_login = queryParam.get('utm_login');
    let utm_content = queryParam.get('utm_content');
    let utm_flow = queryParam.get('utm_flow');
    //correct function call syntax
    persistutmParamsInCookie(utm_source, utm_medium, utm_campaign, utm_lender, utm_login, utm_content, utm_flow);
}

export const getUtmParamsFromQueryParams = (queryParam) => {
  let utm_source = queryParam.get("utm_source");
  let utm_medium = queryParam.get("utm_medium");
  let utm_campaign = queryParam.get("utm_campaign");
  let utm_lender = queryParam.get("utm_lender");
  let utm_login = queryParam.get("utm_login");
  let utm_content = queryParam.get("utm_content");
  let utm_flow = queryParam.get("utm_flow");
  let cmp = queryParam.get("cmp");

  return {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_lender,
    utm_login,
    utm_content,
    utm_flow,
    cmp
  };
};

export const publishInsPdtEvent = (actionName,partner,context,dispatch,miscData = {}) => { 
        const eventData = {
            utm_source : getSpecificCookie('utm_source'),
            utm_medium : getSpecificCookie('utm_medium'),
            utm_campaign : getSpecificCookie('utm_ins_campaign'),
            utm_login : getSpecificCookie('utm_login'),
            utm_content : getSpecificCookie('utm_content'),
            utm_flow : getSpecificCookie('utm_flow')

        }
        dispatch(publishPDTData({partner,context,eventType : INS_UI_EVENTS, actionName,eventData : {partner,...eventData,...miscData}}))
}



//entrypoint for new insurance flow form bottombar
export const redirectToInsNewFlow = (otu)=>{
    const queryParams = new URLSearchParams(window.location.search);
    let loginRequired = "true"
    if(otu && (otu?.loginRequired === false)) loginRequired = "false"
    persistSpecificParamInCookie("login_required",loginRequired, {
        path: "/",
      });
      const utm_params = getUtmParamsFromQueryParams(queryParams);
      const urlParams = {
        login_required: loginRequired,
        ...utm_params,
        cmp: "bottombar"
      };
      const isMMT = window.location.pathname.includes('/mmt/');
      const isGI = window.location.pathname.includes('/gi/');
      let domain = null;
      if(isMMT) {
        domain = INS_MMT_SUBDOMAIN;
      } else if(isGI) {
        domain = INS_GI_SUBDOMAIN;
      } else {
        throw "Error detecting partner";
      }
      window.location.href = `https://${domain}/insurance${setURLQueryParams(
        urlParams
      )}`;
}


export const persistutmParamsInCookie = (utm_source, utm_medium, utm_campaign, utm_lender, utm_login, utm_content, utm_flow) => {
    const c = new Cookies();
    if (!!utm_source && utm_source != null && utm_source !== "video-kyc.happay.in") {
        c.set("utm_source", utm_source, { path: '/' });
    }
    if (!!utm_medium && utm_medium != null && utm_medium !== "referral") {
        c.set("utm_medium", utm_medium, { path: '/' });
    }
    if (!!utm_campaign && utm_campaign != null) {
        c.set("utm_campaign", utm_campaign, { path: '/' });
    }
    if (!!utm_lender && utm_lender != null) {
        c.set("utm_lender", utm_lender, { path: '/' });
    }
    if (!!utm_login && utm_login != null) {
        c.set("utm_login", utm_login, { path: '/' });
    }

    if (!!utm_content && utm_content != null) {
        c.set("utm_content", utm_content, { path: '/' });
    }

    if (!!utm_flow && utm_flow != null) {
        c.set("utm_flow", utm_flow, { path: '/' });
    }

}

export const fetchutmParamsFromCookie = () => {
    const c = new Cookies();
    let utm_source = c.get("utm_source", { path: '/' });
    let utm_medium = c.get("utm_medium", { path: '/' });
    let utm_campaign = c.get("utm_campaign", { path: '/' });
    let utm_lender = c.get("utm_lender", { path: '/' });
    let utm_login = c.get("utm_login", { path: '/' });
    let utm_content = c.get("utm_content", { path: '/' });
    let utm_flow = c.get("utm_flow", { path: '/' });
    let cmp = c.get("cmp", { path: '/' });
    return { utm_source, utm_medium, utm_campaign, utm_lender, utm_login, utm_content, utm_flow, cmp : cmp ?? "" };
}

export const formUrlForRedirection = (utm_source, utm_medium, utm_campaign, utm_lender, utm_login, utm_content, utm_flow,cmp) => {
    return (new URLSearchParams(JSON.parse(JSON.stringify({utm_source, utm_medium, utm_campaign, utm_lender, utm_login, utm_content, utm_flow,cmp})))).toString();
}

export const getAppVersion = ()=>{
    let appVersion = getSpecificCookie(APP_VERSION)
    if(appVersion) return appVersion
    return localStorage.getItem(APP_VERSION) ?? "" 
}

export const redirectIntercept = (url) => {
    let { utm_source, utm_medium, utm_campaign, utm_lender, utm_login, utm_content, utm_flow,cmp} = fetchutmParamsFromCookie();

    let urlParam = formUrlForRedirection(utm_source, utm_medium, utm_campaign, utm_lender, utm_login, utm_content, utm_flow,cmp);
    if (!url.includes('utm')) {
        if (url.includes('?')) {
            url = url + "&" + urlParam;
        } else {
            url = url + "?" + urlParam;
        }
    }
    history.replace(url);
}

export const redirectInterceptPush = (url) => {
    let { utm_source, utm_medium, utm_campaign, utm_lender, utm_login, utm_content, utm_flow } = fetchutmParamsFromCookie();
    let urlParam = formUrlForRedirection(utm_source, utm_medium, utm_campaign, utm_lender, utm_login, utm_content, utm_flow);
    if (url) {
        if (url.includes('?')) {
            url = url + "&" + urlParam;
        } else {
            url = url + "?" + urlParam;
        }
    }
    history.push(url);
}

export const redirectInterceptHref = (url) => {
    let { utm_source, utm_medium, utm_campaign, utm_lender, utm_login, utm_content, utm_flow } = fetchutmParamsFromCookie();
    let urlParam = formUrlForRedirection(utm_source, utm_medium, utm_campaign, utm_lender, utm_login, utm_content, utm_flow);
    if (url) {
        if (url.includes('?')) {
            url = url + "&" + urlParam;
        } else {
            url = url + "?" + urlParam;
        }
    }
    window.location.href = url;
}

export const addUtmTagsToUrl = (url) => {
    if (url) {
        let { utm_source, utm_medium, utm_campaign, utm_lender, utm_login, utm_content, utm_flow } = fetchutmParamsFromCookie();
        if (utm_source || utm_medium || utm_campaign || utm_lender || utm_login || utm_content || utm_flow) {
            let urlParam = formUrlForRedirection(utm_source, utm_medium, utm_campaign, utm_lender, utm_login, utm_content, utm_flow);
            if (url.includes('?')) {
                url = url + "&" + urlParam;
            } else {
                url = url + "?" + urlParam;
            }
        }
    }
    return url;
}

export const cardClicked = (type, user, partner, context, sectionClicked, link = "", params = "",dispatch,krysalisConfig,otu = {}) => {
    //TODO : lending.makemytrip.com will returns host as tripmoney.com need to fix it from lb side.
    persistSpecificParamInCookie("utm_content", sectionClicked);
    switch (type) {
        case "insurance":
        case "INS": 
            redirectToInsNewFlow(otu)
        return;
        case INSTANT_CASH:
            if(link !== ""){
               window.location.href = link
            }
        return;
        case "creditLine":  
                if (user?.data?.userInfo) {
                    if(!krysalisConfig?.data?.newLendingFlow){
                        redirectInterceptPush(`/partners/${partner}/init/onboarding/refresh`)
                    }else{
                        dispatch(getCurrentLenderStage({user,partner,context,krysalisConfig,deviceId : "",lender : "CF",stageId : "onboarding"}))
                    } 
                } else {
                redirectInterceptPush(`/partner/${partner}/context/onboarding/otp`);
            }
            return;
        case "creditCard": redirectInterceptPush(`/credit`);
            return;
        case "personalLoan": redirectInterceptPush(`/personal-loans`);
            return;

        // create new flow.
        case "CL":if (user?.data?.userInfo) {
            if(!krysalisConfig?.data?.newLendingFlow){
                redirectInterceptPush(`/partners/${partner}/init/onboarding/refresh`)
            }else{
                dispatch(getCurrentLenderStage({user,partner,context,krysalisConfig,deviceId : "",lender : "CF",stageId : "onboarding"}))
            } 
        } else {
           redirectInterceptPush(`/partner/${partner}/context/onboarding/otp`);
       }
            return;
        case "CC": redirectInterceptPush(`/credit`);
            return;
        case "PL": redirectInterceptPush(`/personal-loans`);
            return;

        case "PAYLATER": if (link) window.location = link;
            return;
        case "CN":
        case "FOREX": 
            setItemInSessionStorage(IS_INITOTU_SESSION_KEY,"");
            redirectInterceptHref(`${partner  === MakeMyTrip ? "https://tripmoney.makemytrip.com/partners/mmt/forex/listing" : "https://tripmoney.goibibo.com/partners/gi/forex/listing"}`)
            // redirectInterceptPush(`/partner/${partner}/context/forex/otp`);
            return;
    }
}

export const getTmLandingCardUrl = ({ type, partner }) => {
  const device = getDeviceType();
  if (["FOREX", "CN"].includes(type)) {
    setItemInSessionStorage(IS_INITOTU_SESSION_KEY, "");
    const url =
      partner === MakeMyTrip
        ? (device === 'App' ? "https://tripmoney.makemytrip.com/partners/mmt/forex/listing?utm_source=bottombar&utm_medium=mmtapp&utm_content=icons_FOREX" 
        : "https://tripmoney.makemytrip.com/partners/mmt/forex/listing?utm_source=bottombar&utm_medium=mmtpwa&utm_content=icons_FOREX")
        : (device === 'App' ? "https://tripmoney.goibibo.com/partners/gi/forex/listing?utm_source=bottombar&utm_medium=giapp&utm_content=icons_FOREX" 
        : "https://tripmoney.goibibo.com/partners/gi/forex/listing?utm_source=bottombar&utm_medium=gipwa&utm_content=icons_FOREX");
    return addUtmTagsToUrl(url);
  }
  return null;
};

export const hashCode = (text) => {
    var hash = 0;
    if (text) {
        if (text.length == 0) {
            return hash;
        }
        for (var i = 0; i < text.length; i++) {
            var char = text.charCodeAt(i);
            hash = ((hash << 5) - hash) + char;
            hash = hash & hash; // Convert to 32bit integer
        }
    }
    return hash;
}

export const isMobileWebview = () => {
    return window.mmt_android_bridge !== undefined
}

export const isPartnerGI = (partner) => {
    return partner === GI;
}

export const getCurrentDate = (unformattedDate = null) => {
    if (!(unformattedDate instanceof Date) && unformattedDate) unformattedDate = unformattedDate.toDate();
    const date = unformattedDate === null ? new Date() : new Date(convertDateFormat('DD/MM/YYYY', unformattedDate, 'MM/DD/YYYY'));
    return {
        date,
        localeDate: date.toLocaleDateString(),
        day: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear()
    }
}



export const sendGAEventForInit = (partner, context, user, landedOn, loginStatus) => {
    const label = "loggedIn:" + loginStatus + "-" + context + "-" + partner + "-" + landedOn + "-" + (user && user.data && user.data.userInfo ? user.data.userInfo.userId : "NO_USER_ID_FOUND") + "-" + (user && user.data && user.data.userInfo ? user.data.userInfo.mobile : "NO_MOBILE_FOUND") + "-" + (user && user.data && user.data.loanInfo && user.data.loanInfo.lender ? user.data.loanInfo.lender : "NO_LENDER_ASSIGNED_YET");
    window.dataLayer.push({
        'event': 'eventTracking',
        'category': 'INIT_OTU',
        'action': context,
        'label': label,
        'value': 0
    });
}

export const parseJSONString = (data) => {
    let parsedData = null;
    try {
        parsedData = JSON.parse(data)
    } catch {
        parsedData = data;
    }

    return parsedData;
}

export const defaultGoBack = (location, otherInfo, partner, context) => {
    let landing;
    if (!location) location = window.location;
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has('landing')) {
        landing = queryParams.get('landing');
    }

    const goToLandingPage = () => {
        redirectIntercept(`/partner/${otherInfo.landingPageDetails.partner}/context/${otherInfo.landingPageDetails.context}`);
    }

    const goToLanding = () => {
        redirectIntercept(`/partner/${partner}/context/${context}`);
    }

    const goBack = () => {
        if (otherInfo && otherInfo.landingPageDetails && otherInfo.landingPageDetails.triggeredFromLandingPage) {
            goToLandingPage();
        } else if (window.history.length == 0 || landing || partner === GoIbibo || partner === MakeMyTrip) {
            if (window.mmt_android_bridge) {
                window.mmt_android_bridge.closeAndRefreshWebView();
                return;
            } else {
                goToLanding();
            }
        } else {
            goToLanding();
        }
    }

    return goBack;
}
export const getUserId = (user) => user && user.data && user.data.userInfo && user.data.userInfo.userId;
export const getLoanStatus = (user) => user && user.data && user.data.loanInfo && user.data.loanInfo.status;
export const getLender = (user) => user && user.data && user.data.loanInfo && user.data.loanInfo.lender;
export const getUserMobile = (user) => user && user.data && user.data.userInfo && user.data.userInfo.mobile ? user.data.userInfo.mobile : "";
export const getFormattedAmountWithSymbol = (amount, currency = "INR", isX100 = false, noDecimals = false) => {
    if (amount == "" || !amount) {
        amount = "0";
    }
    let val = Number(amount).toString();
    if (isX100) {
        val = val.substring(0, val.length - 2) + (!noDecimals ? "." + val.substring(val.length - 2) : "");
    }
    return new Intl.NumberFormat('en-IN', { style: 'currency', currency: currency }).format(val);
}

export const redirectToForexLanding = (partner) => {
    redirectIntercept(getForexBasePath());
}

export const setCaretPosition = (element, caretPos) => {

    if (element != null) {
        if (element.createTextRange) {
            var range = element.createTextRange();
            range.move('character', caretPos);
            range.select();
        }
        else {
            if (element.selectionStart) {
                element.focus();
                element.setSelectionRange(caretPos, caretPos);
            }
            else
                element.focus();
        }
    }
}

export const getRupeeSymbol = (amount) => {
    return <span style={{ fontFamily: "Merriweather" }}>₹ <span style={{ fontFamily: "Poppins" }}>{amount ? amount : ""}</span></span>
}

export const getZuserFromStore = (store) => {
    let state = store.getState();
    if (state.otu && state.otu.data && state.otu.data.zuser && state.otu.data.zpartneruser) {
        return {
            zuser: state.otu.data.zuser,
            zpartneruser: state.otu.data.zpartneruser
        }
    }
    return {};
}

export const getItemFromStorage = (key) => {
    try {
        return localStorage.getItem(key);
    }
    catch (error) {
        console.log('Err', error);
    }
}

export const setItemInStorage = (key, value) => {
    try {
        return localStorage.setItem(key, value);
    }
    catch (error) {
        console.log('Err', error);
    }
}

export const getFullName = (firstName = "", middleName = "", lastName = "") => {
    return firstName + (firstName ? " " : "") + middleName + (middleName ? " " : "") + lastName;
}

export const convertMonthsToYrs = (months) => months / 12;

/**
 * params: age in months
 */
export const getMinAgeMessage = (age) => {
    if (typeof age === 'number') {
        if (age < 12) {
            return `${age} ${age === 1 ? 'month' : 'months'}`
        } else {
            let years = convertMonthsToYrs(age)
            if (years % 1 !== 0) {
                years = years.toFixed(1)
            }
            return `${years} ${years === 1 ? 'year' : 'years'}`
        }
    }
    throw new Error('Age must be [number]')
}

export const getSessionExpiredRedirectUrl = ({ partner, context, action, postSales, mobile, isNewLogin }) => {
    const urlParams = new URLSearchParams(window.location.search);
    partner = partner || getPartner();
    let deeplink;
    if(urlParams.has(DEEPLINK)){
        deeplink = urlParams.get(DEEPLINK)
    }

    let deeplinkParamOrderId;
    if(urlParams.has(DEEPLINK_PARAM_ORDER_ID)){
        deeplinkParamOrderId = urlParams.get(DEEPLINK_PARAM_ORDER_ID)
    }
    const deeplinkStr = `${deeplink ? DEEPLINK+"="+deeplink : ""}${deeplink && deeplinkParamOrderId ? "&"+DEEPLINK_PARAM_ORDER_ID+"="+deeplinkParamOrderId : ""}`;

    let redirectUrl = `${window?.location?.protocol}//${window?.location?.hostname}/partners/${partner}/forex/listing${deeplinkStr ? '?' + deeplinkStr : ''
        }`;

    removeTmMmmtAuthCookie()

    if(partner === MakeMyTrip && !isMobile()){
        redirectUrl = MMT_FOREX_URL
    }

    if(!getItemFromSessionStorage(IS_INITOTU_SESSION_KEY) || getItemFromSessionStorage(IS_INITOTU_SESSION_KEY) !==  "true"){
        return redirectUrl;
    }


    switch (partner) {
        case MakeMyTrip:
            //for dev testing on local
            // if (process.env.NODE_ENV !== "production") {
            //     return `http://localhost:3003/ext/api/v1/partners/${partner}/initOTU?product=${context}${!isNewLogin ? "&sessionExpired=true" : ""}&mobile=${!isNewLogin ? mobile : ""}&action=${action ?? ""}&postSales=${postSales ?? ""}`;
            // }
            if(context === INSURANCE && window.location.hostname === NEW_INSURANCE_DOMAIN){
                return `https://${window.location.hostname}/ext/api/v1/partners/${partner}/initOTU?product=${context}${!isNewLogin ? "&sessionExpired=true" : ""}&mobile=${!isNewLogin ? mobile : ""}&action=${action ?? ""}&postSales=${postSales ?? ""}`;
            }
            return `https://lending.makemytrip.com/ext/api/v1/partners/${partner}/initOTU?product=${context}${!isNewLogin ? "&sessionExpired=true" : ""}&mobile=${!isNewLogin ? mobile : ""}&action=${action ?? ""}&postSales=${postSales ?? ""}${deeplinkStr ? "&"+deeplinkStr : ""}`;
        case GoIbibo:
            if(context === INSURANCE && window.location.hostname === NEW_INSURANCE_DOMAIN){
                return `https://${window.location.hostname}/ext/api/v1/partners/${partner}/initOTUGI?product=${context}${!isNewLogin ? "&sessionExpired=true" : ""}&mobile=${!isNewLogin ? mobile : ""}&action=${action ?? ""}&postSales=${postSales ?? ""}`;
            }
            return `https://lending.goibibo.com/ext/api/v1/partners/${partner}/initOTUGI?product=${context}${!isNewLogin ? "&sessionExpired=true" : ""}&mobile=${!isNewLogin ? mobile : ""}&action=${action ?? ""}&postSales=${postSales ?? ""}${deeplinkStr ? "&"+deeplinkStr : ""}`;
        case TripMoney:
            return "https://www.tripmoney.com";
        default:
            return "/error";
    }
}


export const removeTmMmmtAuthCookie = () => {
    if (window?.location?.hostname.indexOf(SOURCE_PLATFORM.MAKEMYTRIP) > -1) {
        persistSpecificParamInCookie(COOKIES.TM_AUTH_KEY, '', {
            path: "/",
            domain: MMT_SUB_DOMAIN,
            expires: new Date(0)
        });
        persistSpecificParamInCookie(COOKIES.TM_MMT_AUTH_KEY, '', {
            path: "/",
            domain: MMT_SUB_DOMAIN,
            expires: new Date(0)
        });
        // persistSpecificParamInCookie(COOKIES.MMT_AUTH_KEY, '', {
        //     path: "/",
        //     domain: MMT_SUB_DOMAIN,
        //     expires: new Date(0)
        // });
    } else if (window?.location?.hostname.indexOf(SOURCE_PLATFORM.GOIBIBO) > -1) {

        persistSpecificParamInCookie(COOKIES.TM_AUTH_KEY, '', {
            path: "/",
            domain: GI_SUB_DOMAIN,
            expires: new Date(0)
        });
        persistSpecificParamInCookie(COOKIES.TM_GI_AUTH_KEY, '', {
            path: "/",
            domain: GI_SUB_DOMAIN,
            expires: new Date(0)
        });
        // persistSpecificParamInCookie(COOKIES.GI_AUTH_KEY, '', {
        //     path: "/",
        //     domain: GI_SUB_DOMAIN,
        //     expires: new Date(0)
        // });
    }
}

var mapObj = {
    "&lt;":"<",
    "&gt;":">",
};

export const replaceMapper = (str) => {
    str = str.replace(/&lt;|&gt;/gi, function(matched){
      return mapObj[matched];
    });
    return str;
}
/**
 * params: age in months
 */
export const getMaxAgeMessage = (maxAge) => {
    if (typeof maxAge === 'number') {
        const remainingMonths = maxAge % 12;
        if (remainingMonths === 0) {
            return `${maxAge / 12} years`
        } else {
            const years = parseInt(maxAge / 12);
            if (years === 0) {
                return `${remainingMonths} ${remainingMonths === 1 ? 'month' : 'months'}`;
            }
            return `${years} ${years === 1 ? 'year' : 'years'} and ${remainingMonths} ${remainingMonths === 1 ? 'month' : 'months'}`
        }
    }
    return new Error('Max age must be [number]')
}

export const setUserIdInPagesenseRecording = (userId) => {
    window.pagesense = window.pagesense || [];
    window.pagesense.push(['identifyUser', userId]);
}

/*
sorts array of objects by the given key in asc order
*/
export const sortDataByKey = (data, key) => data.sort((itemA, itemB) => (itemA[key] < itemB[key] ? -1 : 1));

export const truncateToDecimalPlaces = (num, fixed) => {
    let re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
    return +num.toString().match(re)[0];
}

export const getPartnerUrlForLedger = (partner) => {
    if (window.mmt_android_bridge) {
        window.mmt_android_bridge.closeAndRefreshWebView();
        return;
    }
    else {
        if (partner === GoIbibo) {
            return `https://www.goibibo.com?utm_source=web&utm_medium=gi_ledger`;
        }
        else {
            return `https://www.makemytrip.com?utm_source=web&utm_medium=${partner}_ledger`;
        }
    }
}


export const checkProtectedDeeplinks = (deeplink) => deeplink ? PROTECTED_DEEPLINKS_MAP[deeplink] : false;

export const numToMonthShort = (num) => {
    const monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    return monthList[num] || '';
}

const dateSuffix = (num) => {
    switch (num) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
}

export const getDateInShortFormat = (epochDate) => {
    const unixDate = dayjs.unix(epochDate);
    const date = unixDate.date();
    const month = numToMonthShort(unixDate.month());
    const year = unixDate.year();
    return `${date}${dateSuffix(parseInt(date))} ${month}, ${year}`;
}

export const campaignRedirect = (utm_source_mmt, utm_medium_mmt, utm_source_gi, utm_medium_gi) => {
    const queryParams = new URLSearchParams(window.location.search);
    let param = "";
    if (queryParams.has("partner")) {
        param = queryParams.get("partner").toLowerCase();
    }
    if (param === "gi") {
        redirectInterceptHref(`https://lending.goibibo.com/ext/api/v1/partners/gi/initOTUGI?product=forex&utm_source==${utm_source_gi}&utm_medium=${utm_medium_gi}&deeplink=inr-card`);
    }
    else {
        redirectInterceptHref(`https://lending.makemytrip.com/ext/api/v1/partners/mmt/initOTU?product=forex&utm_source==${utm_source_mmt}&utm_medium=${utm_medium_mmt}&deeplink=inr-card`)
    }
}

export const setURLQueryParams = (params = {}) => {
    let usp = new URLSearchParams();
    Object.keys(params).forEach((e) => {
        if (params[e]) {
            usp.set(e, params[e])
        }
    })

    return `${usp.toString() ? "?" : ""}${usp.toString()}`
}

export const setItemInSessionStorage = (key, value) => {
    if (key && value) {
        window.sessionStorage.setItem(key, value)
    }
}

export const getItemFromSessionStorage = (key) => {
    if (key) {
        return window.sessionStorage.getItem(key);
    }
    return null
}

export const checkUnprotectedAPIRoutes = (url) => {
    if (url) {
        let route = UNPROTECTED_API_FOREX_ROUTES.find((e) => url.indexOf(e) > -1)
        return route ? true : false
    }
    return false;
}

export const getObjectValueFromPath = (obj, path, defValue) => {
    if (!path) {
        return defValue;
    }
    // Check if path is string or array. Regex : ensure that we do not have '.' and brackets.
    // Regex explained: https://regexr.com/58j0k
    const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
    // Find value
    const result = pathArray.reduce(
        (prevObj, key) => prevObj && prevObj[key],
        obj
    );
    // If found value is undefined return default value; otherwise return the value
    return result === undefined ? defValue : result;
};

/*
 * Group items from an array together by some criteria or value.
 * @param  {Array}           arr      The array to group items from
 * @param  {String|Function} criteria The criteria to group by
 * @return {Object}                   The grouped object
 */
export const groupBy = function (arr, criteria) {
    return arr ? arr.reduce(function (obj, item) {
        // Check if the criteria is a function to run on the item or a property of it
        let key = typeof criteria === 'function' ? criteria(item) : item[criteria];
        // If the key doesn't exist yet, create it
        if (!obj.hasOwnProperty(key)) {
            obj[key] = [];
        }
        // Push the value to the object
        obj[key].push(item);
        // Return the object to the next item in the loop
        return obj;
    }, {}) : {};
};


export const collectionKeyBy = (collection = {}, criteria) => {
    let c = Array.isArray(collection) ? collection : Object.values(collection);
    return (c || []).reduce((acc, x) => {
        const key = criteria ? (typeof criteria === 'function' ? criteria(x) : x[criteria]) : x;
        return { ...acc, [key]: x }
    }, {});
};

export const debounce = (func, delay = 0) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
};

export const parseTemplateString = (template, obj, fallback = "") => {
    return template.replace(/\${([^{]+[^}])}/g, (match) => {
        const path = match.substr(2, match.length - 3).trim();
        return path.split('.').reduce((res, key) => res?.[key] || fallback, obj);
    });
}

export const deepClone = (obj) => {
    let clone = Array.isArray(obj) ? [] : {};

    if (typeof obj !== "object" || obj === null) {
        return obj; // Return the value if obj is not an object
    }

    for (let key in obj) {
        let value = obj[key];
        // Recursively deep copy for nested objects and arrays
        clone[key] = deepClone(value);
    }
    return clone;
}

export const isEqual = (obj1, obj2) => {
    try {
        return JSON.stringify(obj1) === JSON.stringify(obj2);;
    }
    catch (e) {
        console.log('Error', e);
    }
}

export const toArray = val => {
    if (val instanceof String || typeof val === 'string') {
        return val.split('');
    } else if (val instanceof Object) {
        return Object.values(val)
    }
    return [];
}

export const persistInsCampaignCookie = (context) => {
    const queryParams = new URLSearchParams(window.location.search);
    let campaign = queryParams.get("utm_campaign");
    if (context === INSURANCE) {
        if (campaign) {
            persistSpecificParamInCookie("utm_ins_campaign", campaign);
        }
    }
}

export const getCurrentTimeStamp = ()=>{
    const date = new Date();
    const curr_date = date.getDate()+'/'+(date.getMonth()+1)+'/'+date.getFullYear();
    const curr_time = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    return { curr_time,curr_date}
}

/*

    added new bridge as app_bridge.
    app_bridge currently contains getAppVersion method and navigateToPermission method.
    getAppVersion method available for GI Android and IOS both
    navigateToPermission -> method available for GI IOS.
    We will move all methods from mmt_android_bridge to app_bridge as this will be a common name for it.
 */
export const isAppBridgeExist = () => {
    if (window.mmt_android_bridge || window.app_bridge) {
        return true;
    }
}

export const isForexListingRolledOut = () => {
    const globalState = store.getState();
    const dynamicConfigs = globalState?.dynamicConfigs?.data || null;
    const user = globalState?.user || null;
    const userId = user?.data?.userInfo?.userId || ""
    const bmf_revamp_onboarding = dynamicConfigs?.bmf_revamp_onboarding || null;
    if(bmf_revamp_onboarding?.is_enabled){
        return true
    }

    return bmf_revamp_onboarding?.whitelisted_users?.includes(userId);

}

export const isAuthCookie = () => {
    const isAppLoggedIn = getSpecificCookie(LOGIN_SESSION_STORAGE_KEY);
    const tm_auth = getSpecificCookie(TM_AUTH_COOKIE);
    return (isAppLoggedIn === "true" || tm_auth)
}

export const backBtnListingHandler = (partner) => {
    const backUrl = getSpecificCookie(MMT_FOREX_COOKIE) || false;
    const isApp = window?.app_bridge || window?.mmt_android_bridge;
    const isMmtForexRedirect = backUrl && partner === MakeMyTrip && !isApp;
    if(isMmtForexRedirect) redirectInterceptHref(MMT_FOREX_URL);
    else redirectInterceptHref(`/partners/${partner}/forex/listing`);
}