import { takeLatest, put } from 'redux-saga/effects';
import * as zero from '../api';
import { setLoading } from '../reducers/loading';
import { setError } from '../reducers/error';
import { LOGIN_REQUIRED, ERROR_NO_ACTION } from '../constants';

export default function* watchWhatsAppConsent() {
    yield takeLatest('WHATS_APP_CONSENT', sendWhatsAppConsent)
}

function* sendWhatsAppConsent(action) {
    try {
        const { result, partner, zuid } = action.payload;
        window.dataLayer.push({
            'event': 'eventTracking',
            'category': 'WHATSAPP_CONSENT',
            'action': 'WHATSAPP_CONSENT_CHANGED',
            'label': action.payload,
        });
        yield put(setLoading(true))
        let whatsAppConsentResponse = yield zero.whatsAppConsent(partner, zuid, result)
        yield put(setLoading(false))
        if (whatsAppConsentResponse.data.responseStatus == "SUCCESS") {
        } else {
            let error = whatsAppConsentResponse.data.error;
            if (!(error!= null && (error.code == LOGIN_REQUIRED || error.code == ERROR_NO_ACTION))) {
                window.dataLayer.push({
                    'event': 'eventTracking',
                    'category': 'WHATSAPP_CONSENT',
                    'action': 'WHATSAPP_CONSENT_CHANGE_FAILED',
                    'label': whatsAppConsentResponse.data.error,
                });
                yield put(setError({ ...whatsAppConsentResponse.data.error, viewed: false }))
            }
        }
    } catch (e) {
        window.dataLayer.push({
            'event': 'eventTracking',
            'category': 'WHATSAPP_CONSENT',
            'action': 'WHATSAPP_CONSENT_CHANGE_FAILED',
            'label': e.message,
        });
        yield put(setLoading(false))
        yield put(setError({ viewed: false, description: 'Seems there is some problem with your network, please check back in a while.' }))
        console.log(e)
    }
}