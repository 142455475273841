import { takeLatest, put } from 'redux-saga/effects';
import { setConfig } from '../reducers/config'
import * as zero from '../api'
import {redirectIntercept} from '../utils/common';


export default function* watchGetConfig() {
    yield takeLatest('GET_CONFIG', getConfig)
}

function* getConfig(action) {
    try {
        let { partner = "tm" } = action?.payload || {};
        let configResponse = yield zero.getConfig(partner)
        if (configResponse.data != null)
            yield put(setConfig(configResponse.data));
    } catch(e) {
        redirectIntercept("/error")
        console.log(e)
    }
}