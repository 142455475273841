import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const getLedgerFaq = createAction('GET_LEDGER_FAQ');
export const setLedgerFaq = createAction('SET_LEDGER_FAQ');

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
        setLedgerFaq,
      (state, action) => {
        return action.payload
      } 
    ]
  ]),
  {}
);

 
