import { takeLatest, put } from 'redux-saga/effects';
import { setLoading } from '../../../reducers/loading';
import { setAction } from '../../../reducers/genericReducer';
import { setError } from '../../../reducers/error';
import {redirectIntercept} from '../util/common';

export default function* watch(actionName, successAction, redirectUrl, getResponseExectuor, loader = true, passEntireResponse = false, showError = true) {
    yield takeLatest(`GET_${actionName}`, getWorker(successAction, redirectUrl, getResponseExectuor, loader, passEntireResponse, showError))
    yield takeLatest(`SUBMIT_${actionName}`, getWorker(successAction, redirectUrl, getResponseExectuor, loader, passEntireResponse, showError))
}

const getWorker = (successAction, redirectUrl, getResponseExectuor, loader = true, passEntireResponse = false, showError = true) => {
    return function* getData(action) {
        try {
            if (loader)
                yield put(setLoading(true))
            let response = yield getResponseExectuor(action.payload)
            if (loader)
                yield put(setLoading(false))
            if (response.data.status === "OK" || response.data.status === "ZERO_RESULTS") {
                yield put(setAction(successAction)(response.data))
            }
        } catch (e) {
            if (loader)
                yield put(setLoading(false))
            if (e.response && e.response.status === 401) {
                redirectIntercept(`/login?redirect_url=${redirectUrl}`);
            } else if (e.response && e.response.status === 403) {
                yield put(setError({ viewed: false, description: 'You are not authorized to access!' }))
            } else {
                if (showError) {
                    yield put(setError({ viewed: false, description: 'Seems there is some problem with your network, please check back in a while.' }))
                }
            }
            console.log(e)
        }
    }
}