import { takeLatest, put, select } from 'redux-saga/effects';
import { getPayNowUrl } from '../api';
import { setLoading } from '../reducers/loading';
import { setError } from '../reducers/error';
import { getUserState } from '../reducers/user';
import { LOGIN_REQUIRED, ERROR_NO_ACTION } from '../constants';
import { setPayNowUrl } from '../reducers/payNowUrl';

export default function* watchGetLedgerInfo() {
    yield takeLatest('GET_PAY_NOW_URL', getData)
}

function* getData(action) {
    try {
        yield put(setLoading(true))
        const { partner, context } = action.payload;
        const user = yield select(getUserState);
        const userId = user && user.data && user.data.userInfo ? user.data.userInfo.userId : "";
        let ledgerResponse = yield getPayNowUrl(partner, context, userId)
        yield put(setLoading(false))
        if (ledgerResponse.data != null && ledgerResponse.data.responseStatus == "SUCCESS") {
            //redirectInterceptHref(ledgerResponse.data.data);
            yield put(setPayNowUrl(ledgerResponse.data));
        } else if (ledgerResponse.data != null && ledgerResponse.data.error) {
            let error = ledgerResponse.data.error;
            if (!(error!= null && (error.code == LOGIN_REQUIRED || error.code == ERROR_NO_ACTION))) {
                yield put(setError({ ...ledgerResponse.data.error, viewed: false }))
            }
        }
    } catch (e) {
        yield put(setError({ viewed: false, description: 'Seems there is some problem with your network, please check back in a while.' }))
        yield put(setLoading(false))
    }
}