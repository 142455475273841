import { takeLatest, put, select } from 'redux-saga/effects';
import * as zero from '../api';
import { setOtherInfo } from '../reducers/otherInfo';
import { getUserState } from '../reducers/user';

export default function* watchGetIfscInfo() {
    yield takeLatest('GET_IFSC_INFO', getData)
}

function* getData(action) {
    try {
        yield put(setOtherInfo({ifscInfo: {}}))
        const { partner, userId, ifsc} = action.payload;
        const user = yield select(getUserState);
        let response = yield zero.getIfscInfo(partner, userId, ifsc);
        if (response.data.responseStatus == "SUCCESS") {
            yield put(setOtherInfo({ifscInfo: response.data.data.bankBranch}))
        } else {
            yield put(setOtherInfo({ifscInfo: {}}))
            //yield put(setError({...response.data.error, viewed: false}))
        }
    } catch(e) {
        yield put(setOtherInfo({ifscInfo: {}}))
        //yield put(setError({viewed: false, description: 'Something went wrong'}))
        console.log(e)
    }
}