import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const setPayNowUrl = createAction('SET_PAY_NOW_URL');
export const getPayNowUrl = createAction('GET_PAY_NOW_URL');

// REDUCERS
export const reducer = handleActions(
    new Map([
      [
        setPayNowUrl,
        (state, action) => {
          return action.payload
        } 
      ]
    ]),
    {}
  );

// SELECTORS
export const getPayNowData = state => state.payNowData;