import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const getErrorEvents = createAction('GET_ERROR_EVENTS');
export const setErrorEvents = createAction('SET_ERROR_EVENTS');

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
        setErrorEvents,
      (state, action) => {
        return action.payload
      } 
    ]
  ]),
  {rows: [], total: 0} 
);

// SELECTORS
 
