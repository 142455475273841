import { take, call, race, put, select } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga'
import { setAllOpenRooms, roomCreated, roomEnded, participantJoined, participantDisconnected } from '../reducers/videoKycRooms'
import { getAgentLogin } from '../reducers/setAgentLogin'

function watchMessages(socket) {
    return eventChannel(emit => {
        socket.onopen = () => {
            //socket.send('Connection estabished'); // Send data to server
        };
        socket.onmessage = (event) => {
            const msg = JSON.parse(event.data);
            handleMessage(emit, msg);
        };
        return () => {
            socket.close();
        };
    });
}

function handleMessage(emit, msg) {
    //This is the initial Response
    if (msg.all_open_rooms) {
        checkForOrphanRooms(msg.all_open_rooms);
        emit(setAllOpenRooms(msg.all_open_rooms));
    } else if (msg.event_type === "participant-connected") {
        emit(participantJoined(msg));
    } else if (msg.event_type === "participant-connected") {
        emit(participantJoined(msg));
    } else if (msg.event_type === "participant-disconnected") {
        emit(participantDisconnected(msg));
    } else if (msg.event_type === "room-created") {
        emit(roomCreated(msg));
    } else if (msg.event_type === "room-ended") {
        emit(roomEnded(msg));
    }
}

function checkForOrphanRooms(all_open_rooms) {
    for (let i=0; i< all_open_rooms.length; i++) {
        if (all_open_rooms[i].participants > 0) {
            var audio = new Audio(`${process.env.PUBLIC_URL}/notification.mp3`);
            audio.play();
        }
    }
}

function* internalListener(socket) {
    while (true) {
        const data = yield take('SOCKET_SEND');
        socket.send(data.payload);
    }
}

function* externalListener(socketChannel) {
    while (true) {
        console.log("In externalListener");
        console.log(socketChannel);
        const action = yield take(socketChannel);
        yield put(action);
    }
}

export default function* wsHandler() {
    while (true) {
        const data = yield take('START_WEBSOCKET');
        const agentLogin = yield select(getAgentLogin)
        let loc = window.location, wsURI;
        if (loc.protocol === "https:") {
            wsURI = "wss:";
        } else {
            wsURI = "ws:";
        }
        wsURI += "//" + loc.host;
        wsURI += `/ext/v1/mtf/onboarding/agent/video-kyc/ws?uname=${agentLogin.username}&password=${agentLogin.password}`;
        const socket = new WebSocket(wsURI);
        const socketChannel = yield call(watchMessages, socket);
        try {
            //yield call(externalListener, socketChannel)
            const { cancel } = yield race({
                task: call(externalListener, socketChannel),
                cancel: take('STOP_WEBSOCKET')
            });
            if (cancel) {
                console.log("Closing channel");
                socketChannel.close();
            }
        } catch(e) {
            console.log(e);
        }
    }
}
