import { takeLatest, put } from 'redux-saga/effects';
import * as zero from '../api';
import { setLoading } from '../reducers/loading';
import { setError } from '../reducers/error';
import { saveOtpDetailsCreditCard } from '../reducers/otpCreditCard';

export default function* watchOtpData() {
    yield takeLatest('VALIDATE_OTP_CC', validateOtpForCreditCard)
}

function* validateOtpForCreditCard(action) {
    try {
        yield put(setLoading(true))
        let response = yield zero.verifyOtpCreditCard({ ...action.payload });
        yield put(setLoading(false))
        if (response.data.responseStatus == "SUCCESS") {
            yield put(saveOtpDetailsCreditCard({ tokenDetailsCreditCard: response.data.data }))
        } else {
            yield put(setError({ viewed: false, description: 'Invalid OTP or Something went wrong!' }))
            yield put(saveOtpDetailsCreditCard({ tokenDetailsCreditCard: {} }))
        }

    } catch (e) {
        yield put(setLoading(false))
        console.log(e)
    }
}