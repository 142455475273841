import { takeLatest, put } from 'redux-saga/effects';
import { setLoading } from '../../../reducers/loading';
import { setAction } from '../../../reducers/genericReducer';
import { setError } from '../../../reducers/error';
import { ERROR_NO_ACTION, LOGIN_REQUIRED } from '../util/constants';
import { redirectIntercept } from '../util/common';
import { OTP_RETRY } from '../../../constants';
import { setRateError } from '../../../reducers/rateError';

export default function* watch(actionName, successAction, redirectUrl, getResponseExectuor, loader = true, passEntireResponse = false, showError = true) {
    yield takeLatest(`GET_${actionName}`, getWorker(successAction, redirectUrl, getResponseExectuor, loader, passEntireResponse, showError))
    yield takeLatest(`SUBMIT_${actionName}`, getWorker(successAction, redirectUrl, getResponseExectuor, loader, passEntireResponse, showError))
}

const getWorker = (successAction, redirectUrl, getResponseExectuor, loader = true, passEntireResponse = false, showError = true) => {
    return function* getData(action) {
        try {
            let showLoader = true;
            if(action.payload && !!action.payload.noNeedOfLoader) {
                showLoader = false;
                delete action.payload.noNeedOfLoader
            }
            if (loader && showLoader)
                yield put(setLoading(true))
            let response = yield getResponseExectuor(action.payload)
            if (loader)
                yield put(setLoading(false))
            if (response.data.success === true) {
                if(action?.type === "GET_REQUEST_PAYMENT_CHECKOUT") {
                    if(response?.data?.data?.redirect_link) {
                        window.location.replace(response?.data?.data?.redirect_link)
                    } else {
                        yield put({type: "INTERNAL_ERROR"});
                    }
                } else {
                    yield put(setAction(successAction)(passEntireResponse ? response.data : (response.data.data ? response.data.data : response.data)))
                }
            } else {
                yield put(setAction(successAction)(response.data))
                if(successAction === "GET_BMF_EXCHANGE_RATE" && window?.location.pathname?.indexOf("partners/gi") === -1){
                    yield put(setRateError({ ...response.data.error, viewed: false, actionName: action }));
                }
                if (showError || (successAction === "GET_BMF_EXCHANGE_RATE" && window?.location.pathname?.indexOf("partners/gi") > -1)) {
                    if (response.data.error && response.data.error.code && (response.data.error.code === ERROR_NO_ACTION || response.data.error.code === LOGIN_REQUIRED)) {
                        yield put({ type: response.data.error.code, payload: response.data.error.description });
                    } else if (!(response.data.error.code === LOGIN_REQUIRED || response.data.error.code === ERROR_NO_ACTION)) {
                        if(response.data.error.action !== OTP_RETRY){
                            yield put(setError({ ...response.data.error, viewed: false, actionName: action }));
                        }
                    }
                }
            }
        } catch (e) {
            if (loader)
                yield put(setLoading(false))
            if (e.response && e.response.status === 401) {
                redirectIntercept(`/login?redirect_url=${redirectUrl}`);
            } else if (e.response && e.response.status === 403) {
                yield put(setError({ viewed: false, description: 'You are not authorized to access!' }))
            } else {
                if (showError) {
                    yield put(setError({ viewed: false, description: 'Seems there is some problem with your network, please check back in a while.', actionName: action }))
                }
            }
            console.log(e)
        }
    }
}