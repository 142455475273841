import { takeLatest, put, select } from 'redux-saga/effects';
import { getLedgerFaqs } from '../api';
import { setLoading } from '../reducers/loading';
import { setLedgerFaq } from '../reducers/ledgerFaq';
import { setError } from '../reducers/error';
import { getUserState } from '../reducers/user';
import { LOGIN_REQUIRED, ERROR_NO_ACTION } from '../constants';

export default function* watchGetLedgerFaq() {
    yield takeLatest('GET_LEDGER_FAQ', getData)
}

function* getData(action) {
    try {
        yield put(setLoading(true))
        const { partner, context } = action.payload;
        // todo: get lender and pass it to getLedgerFaqs function
        const user = yield select(getUserState);
        const userId = user.data && user.data.userInfo ? user.data.userInfo.userId : "";
        let ledgerFaqResponse = yield getLedgerFaqs(partner, context, userId)
        yield put(setLoading(false))
        if (ledgerFaqResponse.data != null && ledgerFaqResponse.data.responseStatus == "SUCCESS") {
            yield put(setLedgerFaq(ledgerFaqResponse.data.data));
        } else if (ledgerFaqResponse.data != null && ledgerFaqResponse.data.error != null) {
            let error = ledgerFaqResponse.data.error;
            if (!(error!= null && (error.code == LOGIN_REQUIRED || error.code == ERROR_NO_ACTION))) {
                yield put(setError({ viewed: false, description: ledgerFaqResponse.data.error.description }))
            }
        }
    } catch (e) {
        yield put(setError({ viewed: false, description: 'Seems there is some problem with your network, please check back in a while.' }))
        yield put(setLoading(false))
    }
}