import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const getCurrentStage = createAction('GET_CURRENT_STAGE');
export const setCurrentStage = createAction('SET_CURRENT_STAGE');
export const submitStage = createAction('SUBMIT_STAGE');
export const submitAddress = createAction('SUBMIT_ADDRESS')

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
        setCurrentStage,
      (state, action) => {
        return action.payload
      } 
    ]
  ]),
  false
);

// SELECTORS
export const getStage = state => state.stage;
 
