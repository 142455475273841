import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const setURLParams = createAction('SET_URL_PARAMS');

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
        setURLParams,
      (state, action) => {
        return action.payload
      } 
    ]
  ]),
  { }
);

// SELECTORS
export const getUrlParams = state => state.urlParams;
 
