import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const sendOTP = createAction('SEND_OTP');
export const otpSent = createAction('OTP_SENT');
export const validateOTP = createAction('VALIDATE_OTP');
export const otpValidated = createAction('VALIDATED_OTP');
export const validateOTPPL = createAction('VALIDATE_OTP_PL');


// REDUCERS
export const reducer = handleActions(
  new Map([
    [
      otpSent,
      (state, action) => {
        return {...state, sent: action.payload}
      } 
    ],
    [
      otpValidated,
    (state, action) => {
      return {...state, validated: action.payload}
    } 
  ]
  ]),
  {sent: null, validated: false}
);
