import { takeLatest, put } from 'redux-saga/effects';
import * as zero from '../api';
import { setLoading } from '../reducers/loading';
import { getLoginStatusOfUser } from "./loginStatus"
import { createAction } from 'redux-actions';
import {redirectIntercept,redirectInterceptHref} from '../utils/common';

export const logoutAction = createAction('LOGOUT');

export default function* watchGetLoginStatus() {
    yield takeLatest('LOGOUT', getLoginStatus)
}

function* getLoginStatus(action) {
    let loginStatusResponse;

    try {
        yield put(setLoading(true))
        let partner="tm";
        if(action.payload && action.payload.partner){
            partner = action.payload.partner;
        }
        loginStatusResponse = yield zero.logout(partner)
        yield put(setLoading(false))
        if (loginStatusResponse.data.responseStatus === "SUCCESS") {
            yield put(getLoginStatusOfUser({...loginStatusResponse.data}));
            if(action.payload && action.payload.redirectURL){
                redirectInterceptHref(action.payload.redirectURL);
            }
        }
    } catch(e) {
        yield put(setLoading(false))
        window.dataLayer.push({
            'event': 'eventTracking',
            'category': 'LOGIN_STATUS',
                'action': 'GET_LOGIN_FAILED',
            'label': e.message,
        });
        yield put(setLoading(false))
        // redirectIntercept("/error")
        console.log(e)
    }
}