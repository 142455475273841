import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const getClose = createAction('GET_CLOSE_URL');
export const setClose = createAction('SET_CLOSE_URL');

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
        setClose,
      (state, action) => {
        return action.payload
      } 
    ]
  ]),
  {} 
);

// SELECTORS
export const getCloseState = state => state.close;
 
