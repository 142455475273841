import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const searchUsers = createAction('SEARCH_USERS');
export const setSearchResults = createAction('SET_SEARCH_RESULTS');

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
        setSearchResults,
      (state, action) => {
        return action.payload
      } 
    ]
  ]),
  { data: null, error: null}
);

// SELECTORS
 
