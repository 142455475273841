import { put, select, takeLatest } from 'redux-saga/effects';
import * as zero from '../api';
import * as stageUtils from '../utils/stage';
import { redirectIntercept, sendGAEventForInit } from '../utils/common';
import { setLoading } from '../reducers/loading';
import { REDIRECT_,REJECTED_,ERROR_PAGE_,LEDGER_,WAITLIST_,SUSPENDED_} from "../constants";
import { setCurrentLenderStage } from '../reducers/lendingCurrentStage';
import { setConfig } from '../reducers/config';
import { clearUser, getUser } from '../reducers/user';

export default function* watchGetUser() {
    yield takeLatest('FETCH_CURRENT_STAGE', getStageResponse)
}

function* getStageResponse(action) {

    try{
        let {user,context,lender = "CF",partner,deviceId = "",loadingMessage = "Loading...",krysalisConfig} = action.payload
        if (loadingMessage) {
            yield put(setLoading({ status: true, message: loadingMessage }))
        } else {
            yield put(setLoading(true))
        }

        const lenderStageResponse = yield zero.getStage(partner,user?.data?.userInfo?.userId,lender,deviceId)
        yield put(setLoading(false))
        if (lenderStageResponse?.data != null && lenderStageResponse?.data?.responseStatus === "SUCCESS") {
           
            let stageData = lenderStageResponse?.data?.data?.stageDetails;
            
            //set the data for further use if required.
            yield put(setCurrentLenderStage({...(lenderStageResponse?.data)}))

            //Only perfrom these action if nextStep is available
            if (stageData && stageData?.xdata?.nextStep){
                sendGAEventForInit(partner, context, user, ((stageData?.stageName) ?? ""),true)
                
                //Perform actions based on the nextStep
                switch (stageData?.xdata?.nextStep) {
                    case LEDGER_ :
                       stageUtils.redirectToLedger(krysalisConfig,user,partner)
                      break;
                    case REDIRECT_:
                        if(stageData?.xdata?.redirectUrl){
                             stageUtils.redirectToLender(stageData?.xdata?.redirectUrl)
                        }else{
                            redirectIntercept("/error")
                        }
                      break;
                    case WAITLIST_ :
                        stageUtils.redirectToGenericPage(partner,context)
                        break;
                    case SUSPENDED_:
                        stageUtils.redirectToGenericPage(partner,context)
                        break;
                    case REJECTED_:
                        stageUtils.redirectToGenericPage(partner,context)
                        break;
                    case ERROR_PAGE_:
                        redirectIntercept("/error")
                        break;
                    default:
                        console.error("no data provided for the xNext step for user id : ",user?.data?.userInfo?.userId)
                        redirectIntercept("/error")
                  }

            }else{
                console.info("could not find nextStep from zero api for zuid ",user?.data?.userInfo?.userId)
                yield put(setConfig({ data : {...krysalisConfig,newLendingFlow : false}}))
                yield put(setLoading(false))
                return
            }

        }else{
            redirectIntercept("/error")
            yield put(setLoading(false))
            console.error(`got failed response from zero for zuid ${user?.data?.userInfo?.userId}`)
        }

    }catch(e){
        redirectIntercept("/error")
        yield put(setLoading(false))
        console.error(e.message)
    }
}