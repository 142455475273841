import { takeLatest, put } from 'redux-saga/effects';
import { setLoading } from '../reducers/loading';
import * as zero from '../api';
import { setError } from '../reducers/error';
import { setOtherInfo } from '../reducers/otherInfo';
import { ERROR_NO_ACTION, LOGIN_REQUIRED } from '../constants';

export default function* watchCreateSession() {
    yield takeLatest('SUBMIT_ADDRESS', submitStage)
}

function* submitStage(action) {
    try {
        yield put(setOtherInfo({ addressUpload: 'UPLOADING' }))
        yield put(setLoading(true))
        let { partner, userId, lender, stageId, data } = action.payload;
        let submitAddressResponse = yield zero.submitAddress(partner, userId, lender, stageId, data)
        if (submitAddressResponse.data && submitAddressResponse.data.responseStatus == "SUCCESS") {
            yield put(setLoading(false))
            yield put(setOtherInfo({ addressUpload: 'UPLOADED' }))
        }else{
            let error = submitAddressResponse.data.error;
            if(!(error != null && (error.code == LOGIN_REQUIRED || error.code == ERROR_NO_ACTION))){
                yield put(setLoading(false))
                yield put(setOtherInfo({ addressUpload: 'FAILED' }))
                yield put(setError({ viewed: false, description: 'Seems there is some problem with your network, please check back in a while.' }))
            }
        }
    } catch (e) {
        yield put(setLoading(false))
        yield put(setOtherInfo({ addressUpload: 'FAILED' }))
        yield put(setError({ viewed: false, description: 'Seems there is some problem with your network, please check back in a while.' }))
        console.log(e)
    }
}