import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const setSIPreference = createAction('SET_SI_PREFERENCE');
export const setSIURLError = createAction('SET_SI_URL_ERROR');
export const setSIURL = createAction('SET_SI_URL');
export const setupSI = createAction('SETUP_SI');

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
        setSIPreference,
      (state, action) => {
        return {...state, ...action.payload}
      }
    ],
    [
        setSIURLError,
      (state, action) => {
        return {...state, ...action.payload}
      }
    ],
    [
        setSIURL,
      (state, action) => {
        return {...state, ...action.payload}
      }
    ]
  ]),
  {error: null, si_preference: "setup_now", si_url: null}
);

// SELECTORS
export const getSI = state => state.si;
 
