import { put, select, takeEvery } from 'redux-saga/effects';
import { setError } from '../reducers/error';
import { setAction } from '../reducers/genericReducer';
import { getCmsContent, getCmsContentNoCache } from '../api';
import { CMS_CONTENT } from '../reducers/cms';
import { setLoading } from '../reducers/loading';

export default function* watchGetUser() {
    yield takeEvery(`GET_CMS_CONTENT`, getCMSContent);
}

const getKrysalisConfig = state => state.config;
function* getCMSContent(action) {
    const { showLoader = true, pageKey = action.payload } = action.payload;
    try {
        if (showLoader) {
            yield put(setLoading(true));
        }
        window.dataLayer.push({
            'event': 'eventTracking',
            'category': 'CMS',
            'action': action.type,
            'label': pageKey,
            'value': 0
        });
        let response;
        const krysalisConfig = yield select(getKrysalisConfig);
        if (krysalisConfig?.data?.forex?.enable_cms_no_cache) {
            response = yield getCmsContentNoCache(pageKey);
        } else {
            response = yield getCmsContent(pageKey);
        }
        if (response.status === 200) {
            window.dataLayer.push({
                'event': 'eventTracking',
                'category': 'CMS',
                'action': `${action.type}_SUCCESS`,
                'label': pageKey,
                'value': 0
            });
            yield put(setAction(CMS_CONTENT)({ pageKey, data: response.data }));
        } else {
            window.dataLayer.push({
                'event': 'eventTracking',
                'category': 'CMS',
                'action': `${action.type}_FAILED`,
                'label': response?.data?.error?.description || 'Failed to retrieve content',
                'value': 0
            });
            yield put(setError({...response.data.error, viewed: false}))
        }
        if (showLoader) {
            yield put(setLoading(false));
        }
    } catch(e) {
        window.dataLayer.push({
            'event': 'eventTracking',
            'category': 'CMS',
            'action': `${action.type}_FAILED`,
            'label': e.message,
            'value': 0
        });
        if (showLoader) {
            yield put(setLoading(false));
        }
        console.log(e)
    }
}
