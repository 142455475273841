import { takeEvery, race, put, call, take } from 'redux-saga/effects';
import { configureScope } from '@sentry/browser';
import { setUser } from '../reducers/user'
import { setLoading } from '../reducers/loading';
import { setOtherInfo } from '../reducers/otherInfo';
import * as zero from '../api';
import { LANDING } from '../constants';
import { redirectIntercept } from '../utils/common';

export default function* watchGetUser() {
    yield takeEvery(['GET_USER'], function* (...args) {
        yield race({
            task: call(getUser, ...args),
            cancel: take('CANCEL_GET_USER')
        })
    })
}

function* getUser(action) {
    try {
        const { partner, context } = action.payload;

        let userResponse = yield zero.getUser(partner, context);
        if (userResponse.data != null && userResponse.data.responseStatus == "SUCCESS") {
            if (userResponse.data.data.loanInfo && userResponse.data.data.userInfo) {
                if (window.dataLayer) {
                    window.dataLayer.push({ userTrackingId: userResponse.data.data.userInfo.trackingId })
                }
                configureScope((scope) => {
                    if (userResponse.data.data.userInfo && userResponse.data.data.userInfo.userId) {
                        scope.setUser({ "id": userResponse.data.data.userInfo.userId });
                    }
                });
                if (window.dataLayer) {
                    window.dataLayer.push({ lender: userResponse.data.data.loanInfo.lender })
                }
            }
            yield put(setUser(userResponse.data));
            yield put(setOtherInfo({ userContext: context }));
        }
    } catch (e) {
        redirectIntercept("/error")
        console.log(e)
    }
}