import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const setError = createAction('SET_ERROR');

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
        setError,
      (state, action) => {
        return {...state, ...action.payload}
      } 
    ]
  ]),
  {viewed: true} 
);

// SELECTORS
export const getError = state => state.error;
 
