import React, { Suspense, lazy } from 'react';
import {
    Switch,
    Route,
    Router,
    Redirect,
} from 'react-router-dom';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import History from './getBrowserHistory';
const PartnersPage  = (lazy(() => import('../../components/PartnersPage')));
const InitForex = (lazy(() => import('../Forex/Init')));

export const history = History.getHistoryObject();

const Init = (lazy(() => import('../Init')));
const Error = (lazy(() => import('../Error')));
const CardLoader = (lazy(() => import('../../components/CardLoader')));
const DisplayErrorPage = (lazy(() => import('../DisplayErrorPage')));

const Camera = (lazy(() => import('../../components/Camera')));
const LedgerPage = (lazy(() => import('../Ledger/LedgerPage/ledgerPage')));
const EMIDetailPage = (lazy(() => import('../Ledger/EMIDetailPage/EMIDetailPage')));
const FeedbackPage = (lazy(() => import('../Ledger/FeedbackPage/feedbackPage')));
const GetStatementPage = (lazy(() => import('../Ledger/GetStatementPage/getStatementPage')));
const FaqPage = (lazy(() => import('../Ledger/FaqPage/faqPage')));
const Tnc = (lazy(() => import('../../components/Tnc')));
const LendingCommonPage  = (lazy(() => import('../../components/LendingNew')))

const ProductsLanding = (lazy(() => import('../Landing/New/')));
const Landing = lazy(() => import('../Landing'));
const LoginLocation = lazy(() => import('../Onboarding/LoginLocation'));
const SuccessPagePostLogin = lazy(() => import('../Onboarding/SuccessPostLogin'));
const CreditLine = lazy(() => import('../Landing/CreditLine'));
const ProductsPostLogin = lazy(() => import('../Landing/ProductsPostLogin'))
const PrivacyPolicy = lazy(() => import('../Landing/PrivacyPolicy'));
const UserAgreement = lazy(() => import('../Landing/UserAgreement'));
const AboutUs = lazy(() => import('../Landing/AboutUs'));

const KisshtSuccess = lazy(() => import('../Onboarding/Success'));
const KisshtRejected = lazy(() => import('../Onboarding/Rejected'));
const KisshtSIBanks = lazy(() => import('../Onboarding/SI/BankDetails/BankDetails'));
const KisshtBankDetails = lazy(() => import('../Onboarding/SI/BankDetails/BankAccountDetails'));
const KisshtCohortThankYou = lazy(() => import("../Onboarding/SuccessPostLogin/kissht"));
const KisshtThankyou = lazy(() => import('../Onboarding/Thankyou'));
const LoginMobile = lazy(() => import('../LoginPage/LoginMobile'));

const Transaction = lazy(() => import('../Transaction'));
const Refresh = lazy(() => import('../Refresh'));

/*For TM suspended page*/
const TMSuspended = lazy(() => import('../Onboarding/TMSuspended'));

/*Mobile number page*/
const Otp = lazy(() => import('../New/OTP'));

const DownloadApp = lazy(() => import('../../components/New/DownloadApp/DownloadApp'));

/* to close app */
const Close = lazy(() => import('../../components/close'));


/* Insurance pages */
const InsuranceOfferings = lazy(() => import('../Insurance/Offerings'));
const EditPolicyDetails = lazy(() => import('../Insurance/EditPolicyDetails'));

/*DIGIT covid*/
const InsuranceLanding = lazy(() => import('../Insurance/Digit-Covid/Landing'));
const PolicyDetails = lazy(() => import('../Insurance/Digit-Covid/PolicyDetails'));
const SelectProduct = lazy(() => import('../Insurance/Digit-Covid/SelectProduct'));
const AddDetails = lazy(() => import('../Insurance/Digit-Covid/AddDetails'));
const InsuranceActivated = lazy(() => import('../Insurance/Digit-Covid/InsuranceActivated'));
const PolicyInformation = lazy(() => import('../Insurance/Digit-Covid/PolicyInformation'));
const TermsAndConditions = lazy(() => import('../Insurance/Digit-Covid/T&C'));
const ClaimDocuments = lazy(() => import('../Insurance/Digit-Covid/ClaimDocuments'));
const InsuranceError = lazy(() => import('../Insurance/Digit-Covid/Error'));
const PayOptions = lazy(() => import('../Transaction/Options'))


const PolicyInfo = lazy(() => import('../Insurance/PolicyInfo'));

// Insurance Post Sales
const InsurancePostSalePg = lazy(() => import('../InsurancePostSale/InsurancePostSalePg'));
const InsurancePostSaleThankYou = lazy(() => import('../InsurancePostSale/InsurancePostSaleThankYou'));
const InsurancePostSaleErrorPage = lazy(() => import('../InsurancePostSale/InsurancePostSaleThnkErr'));

const IntermediateEligibility = lazy(() => import('../Transaction/IntermediateErrorPage'));

const ErrorPageNoAction = lazy(() => import('../Error/ErrorNoAction'));
const ErrorPostSalesDatePassed = lazy(() => import('../Error/errorPostSalesDatePassed'))

//Travel Insurance
const TravelInsuranceLanding = lazy(() => import("../travelInsurance/travelLanding"));
const TravelInsuranceSuccess = lazy(() => import("../travelInsurance/travelSummary"));
const TravelInsuranceListing = lazy(() => import("../travelInsurance/travelListing"));
const TravelInsuranceEditInfo = lazy(() => import("../travelInsurance/travelEditInfo"));
const TravelInsuranceDetails = lazy(() => import("../travelInsurance/travelDetails"));
const PageNotFound = lazy(() => import("../PageNotFoundError"));

// * Request Money PaymentStates
const RequestMoneyPaymentStates = lazy(() => import("../../containers/Forex/Onboarding/Kyc/ForexCkycMismatchDecline.js"))
const RequestPaymentPage = lazy(() => import("../Forex/common/RequestPayment"))
/**
 * Marketing
 */
const UaeCampaign = lazy(() => import("../../components/UaeCampaign/index"))
const FifaCampaign = lazy(() => import("../../components/FifaCampaign/index"))
/**
 * React router configuration
 */
export default (props) => (
    <Router history={history}>
        <Suspense fallback={<CardLoader />}>
            <TransitionGroup>
                <CSSTransition
                    classNames="fade"
                    timeout={300}
                >
                    <Switch location={props.location}>
                        <Redirect from="/credit-line" to="/travel-loan" />
                        <Route exact path="/" component={Landing} />
                        <Route path="/partners/:partner/forex" component={InitForex} />
                        <Route exact path="/partner/:partner/context/:context" component={ProductsLanding} />
                        <Route path="/travel-loan" component={CreditLine} />
                        <Route exact path="/partner/:partner/context/:context/login-location" component={LoginLocation} />
                        <Route exact path="/partner/:partner/context/:context/success-page-post-login" component={SuccessPagePostLogin} />
                        <Route exact path="/products" component={ProductsPostLogin} />
                        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                        <Route exact path="/user-agreement" component={UserAgreement} />
                        <Route exact path="/about-us" component={AboutUs} />
                        <Route exact path="/partners/:partner/suspended" component={TMSuspended} />
                        <Route exact path="/partners/:partner/init/:context/:otuToken?" component={Init} />
                        <Route exact path="/partners/:partner/refresh/:context" component={Refresh} />
                        <Route exact path="/partner/:partner/context/:context/ledgerPage" component={LedgerPage} />
                        <Route path="/partner/:partner/context/:context/EMIDetailPage" component={EMIDetailPage} />
                        <Route exact path="/partner/:partner/context/:context/feedbackPage" component={FeedbackPage} />
                        <Route exact path="/partner/:partner/context/:context/getStatementPage" component={GetStatementPage} />
                        <Route exact path="/partner/:partner/context/:context/tnc" component={Tnc} />
                        <Route exact path="/partner/:partner/context/:context/faqPage" component={FaqPage} />
                        <Route path="/error" component={Error} />
                        <Route path="/error-page" component={DisplayErrorPage} />
                        <Route path="/partner/:partner/context/:context/otp" component={LoginMobile} />

                        <Route path="/partner/:partner/context/:context/banks" component={KisshtSIBanks} />
                        <Route path="/partner/:partner/context/:context/bank-details" component={KisshtBankDetails} />
                        <Route path="/partner/:partner/context/:context/success" component={KisshtSuccess} />
                        <Route path="/partner/:partner/context/:context/rejected" component={KisshtRejected} />
                        <Route path="/partner/:partner/context/:context/thank-you" component={KisshtThankyou} />
                        <Route path="/partner/:partner/context/:context/kissht/cohort/thank-you" component={KisshtCohortThankYou} />

                        <Route path="/camera" component={Camera} />

                        {/* Transaction Routes */}
                        <Route exact path="/partners/:partner/transaction/:pTxnId" component={Transaction} />
                        <Route exact path="/partners/:partner/transaction/:pTxnId/options" component={PayOptions} />

                        {/* redirect to this to close app */}
                        <Route path="/close" component={Close} />

                        <Route path="/partner/:partner/context/:context/new/otp" component={Otp} />

                        <Route path="/partner/:partner/context/:context/download-app" component={DownloadApp} />
                    
                        {/* Lending common error/waitlist/suspended page */}
                        <Route path="/partner/:partner/context/:context/lendingCommon" component={LendingCommonPage} />

                        {/* New insurance pages */}
                        <Route exact path='/partner/:partner/context/:context/product-offerings' component={InsuranceOfferings} />
                        <Route exact path='/partner/:partner/context/:context/edit-policy-details/:id' component={EditPolicyDetails} />
                        <Route exact path='/partner/:partner/context/:context/landing' component={InsuranceLanding} />
                        <Route exact path='/partner/:partner/context/:context/policy-details' component={PolicyDetails} />
                        <Route exact path='/partner/:partner/context/:context/products' component={SelectProduct} />
                        <Route exact path='/partner/:partner/context/:context/add-details' component={AddDetails} />
                        <Route exact path='/partner/:partner/context/:context/insurance-activated' component={InsuranceActivated} />
                        <Route exact path='/partner/:partner/context/:context/policy-details/:id' component={PolicyInformation} />
                        <Route exact path='/partner/:partner/context/:context/terms-and-conditions' component={TermsAndConditions} />
                        <Route exact path='/partner/:partner/context/:context/claim-documents' component={ClaimDocuments} />
                        <Route exact path='/insurance/error' component={InsuranceError} />
                        <Route exact path='/ins/partners' component={PartnersPage} />

                        {/* Insurance Post Sale routes */}
                        <Route exact path='/partner/:partner/context/:context/postsale' component={InsurancePostSalePg} />
                        <Route exact path='/partner/:partner/context/:context/postsale/activated' component={InsurancePostSalePg} />
                        <Route exact path='/partner/:partner/context/:context/postsale/thank-you' component={InsurancePostSaleThankYou} />
                        <Route exact path='/partner/:partner/context/:context/postsale/error' component={InsurancePostSaleErrorPage} />

                        <Route exact path='/ext/insurance/details' component={PolicyInfo} />

                        <Route exact path="/transaction/error" component={IntermediateEligibility} />
                        <Route exact path="/error-na" component={ErrorPageNoAction} />
                        <Route exact path="/post-sales/date-error" component={ErrorPostSalesDatePassed} />

                        <Route exact path="/campaign/uae" component={UaeCampaign} />
                        <Route exact path="/campaign/fifa" component={FifaCampaign} />

                        <Route exact path="/transaction/load/link-expired" render={(props) => <RequestMoneyPaymentStates linkExpired {...props}/> }/>
                        <Route exact path="/transaction/request/load/success" render={(props) => <RequestMoneyPaymentStates success {...props}/> }/>
                        <Route exact path="/transaction/request/load/failed" render={(props) => <RequestMoneyPaymentStates failed {...props}/> }/>
                        <Route exact path="/request/checkout" render={(props) => <RequestPaymentPage {...props}/> }/>
                        {/* Travel Insurance */}
                        <Route exact path="/partner/:partner/context/:context/travel-landing" component={TravelInsuranceLanding} />
                        <Route exact path="/partner/:partner/context/:context/travel-listing" component={TravelInsuranceListing} />
                        <Route exact path="/partner/:partner/context/:context/travel-edit-info" component={TravelInsuranceEditInfo} />
                        <Route exact path="/partner/:partner/context/:context/travel-details" component={TravelInsuranceDetails} />
                        <Route exact path="/partner/:partner/context/:context/travel-landing/checkout/success" component={TravelInsuranceSuccess} />

                        {/* It should always be the last route */}
                        <Route path="*" component={PageNotFound} />
                    </Switch>
                </CSSTransition>
            </TransitionGroup>
        </Suspense>
    </Router>
);

