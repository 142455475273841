import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const validateOtpCreditCard = createAction('VALIDATE_OTP_CC');
export const saveOtpDetailsCreditCard = createAction('SAVE_OTP_DETAILS_CC');
export const checkAccessTokenStatus = createAction('CHECK_ACCESS_TOKEN_STATUS');
export const getNewToken = createAction('GET_NEW_ACCESS_TOKEN');


// REDUCERS
export const reducer = handleActions(
    new Map([
        [
            saveOtpDetailsCreditCard,
            (state, action) => {
                return { ...state, ...action.payload }
            }
        ],
    ]),
    {}
);

// SELECTORS
export const tokenDetails = state => state.tokenDetailsCreditCard;