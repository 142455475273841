import stageApiSaga from './stageApiSaga';
import genericApiSaga from './genericApiSaga';
import pincodeSaga from './pincodeSaga';
import resetPreviousStageData from "./resetPreviousStageData";
import listingApiSaga from './listingApiSaga'

import * as forex from '../api'

const forexSagas = [
    stageApiSaga("SUBMIT_PERSONAL_DETAILS", "STAGE_RESPONSE", forex.submitPersonalDetails, true, true),
    stageApiSaga("SUBMIT_UPLOAD_PHOTO", "STAGE_RESPONSE", forex.uploadPhoto, true, true),
    genericApiSaga("GET_HYPERVERGE_TOKEN", "GET_HYPERVERGE_TOKEN", null, forex.getHypervergeToken, true, true, true),
    stageApiSaga("SUBMIT_CARD_ASSIGNED", "STAGE_RESPONSE", forex.submitCardAssigned, true, true),
    stageApiSaga("SUBMIT_UPLOAD_PHOTO_HV", "SUBMIT_UPLOAD_PHOTO_HV", forex.uploadPhotoHv, false, true, false),

    stageApiSaga("SUBMIT_UPDATE_ADDRESS", "UPDATE_ADDRESS", forex.updateAddress, true, true),
    genericApiSaga("BLOCK_CARD", "BLOCK_CARD", null, forex.blockCard, true, true, true),
    genericApiSaga("UNBLOCK_CARD", "UNBLOCK_CARD", null, forex.unblockCard, true, true, true),
    genericApiSaga("TRACK_CARD_STATUS", "TRACK_CARD_STATUS", null, forex.trackCardStatus, true, true, true),
    genericApiSaga("GET_ALL_DISPUTES", "GET_ALL_DISPUTES", null, forex.getAllDisputes, true, true, true),
    genericApiSaga("GET_DISPUTE_REASONS", "GET_DISPUTE_REASONS", null, forex.getDisputeReasons, true, true, true),
    genericApiSaga("GET_DISPUTE_BY_ID", "GET_DISPUTE_BY_ID", null, forex.getDisputeById, true, true, true),
    genericApiSaga("POST_DISPUTE", "POST_DISPUTE", null, forex.postDispute, true, true, true),
    genericApiSaga("ACTIVATE_CARD", "ACTIVATE_CARD", null, forex.activateCard, true, true, true),
    genericApiSaga("GET_TRANSACTION_MODES", "GET_TRANSACTION_MODES", null, forex.getTransactionModes, true, true, true),
    genericApiSaga("SET_TRANSACTION_MODES", "SET_TRANSACTION_MODES", null, forex.setTransactionModes, true, true, true),
    genericApiSaga("SET_TRANSACTION_LIMITS", "SET_TRANSACTION_LIMITS", null, forex.setTransactionLimit, true, true, true),
    genericApiSaga("GET_TRANSACTION_LIMITS", "GET_TRANSACTION_LIMITS", null, forex.getTransactionLimit, true, true, true),
    listingApiSaga("CURRENT_USER_STATUS", "CURRENT_USER_STATUS", null, forex.getCurrentUserStatus, true, true, true),
    genericApiSaga("PIN_SET", "PIN_SET", null, forex.pinSet, true, true, true),
    genericApiSaga("PIN_SET_VERIFY_OTP", "PIN_SET_VERIFY_OTP", null, forex.pinSetVerifyOTP, true, true, true),
    genericApiSaga("PIN_SET_SEND_OTP", "PIN_SET_SEND_OTP", null, forex.pinSetSendOTP, true, true, true),
    genericApiSaga("CARD_DETAILS", "CARD_DETAILS", null, forex.getCardDetails, true, true, true),
    genericApiSaga("WITHDRAWL_ELIGIBLITY", "WITHDRAWL_ELIGIBLITY", null, forex.getWithdrawlEligiblity, true, true, true),
    genericApiSaga("WITHDRAW", "WITHDRAW", null, forex.submitWithdraw, true, true, true),
    pincodeSaga("PINCODE", "PINCODE", null, forex.pincode, true, true, true),
    genericApiSaga("LOAD_MONEY", "LOAD_MONEY", null, forex.loadRequest, true, true, true),
    genericApiSaga("TRANSACTIONS", "TRANSACTIONS", null, forex.transactions, true, true, true),
    genericApiSaga("WITHDRAW_LIMIT", "WITHDRAW_LIMIT", null, forex.getWithdrawLimit, true, true, true),
    //ckyc flow
    stageApiSaga("SUBMIT_CKYC_PERSONAL_DETAILS", "CKYC_PERSONAL_DETAILS", forex.ckycSubmitPersonalDetails, true, true),
    stageApiSaga("SUBMIT_CKYC_UPLOAD_PHOTO", "CKYC_UPLOAD_PHOTO", forex.ckycUploadPhoto, true, true),
    stageApiSaga("SUBMIT_CKYC_CARD_ASSIGNED", "CKYC_CARD_ASSIGNED", forex.ckycSubmitCardAssigned, true, true),
    stageApiSaga("SUBMIT_CKYC_UPDATE_ADDRESS", "CKYC_UPDATE_ADDRESS", forex.ckycUpdateAddress, true, true),
    stageApiSaga("SUBMIT_CKYC_TIMER_BREACHED", "CKYC_TIMER_BREACHED", forex.ckycTimerBreached, true, true),

    //load money
    genericApiSaga("GET_LOAD_MONEY_TIMESTAMP", "GET_LOAD_MONEY_TIMESTAMP", null, forex.getLoadMoneyTimestamp, true, true, true),

    //bmf
    stageApiSaga("SUBMIT_UPDATE_TRIP_DURATION", "UPDATE_TRIP_DURATION", forex.updateTripDuration, true, true),
    genericApiSaga("TRIP_PURPOSE", "TRIP_PURPOSE", null, forex.getBmfTripPurpose, true, true, true),
    stageApiSaga("SUBMIT_UPDATE_TRIP_PURPOSE", "UPDATE_TRIP_PURPOSE", forex.updateTripPurpose, true, true),
    stageApiSaga("SUBMIT_BMF_ORDER", "BMF_ORDER", forex.submitBmfOrder, true, true),
    genericApiSaga("INITIATE_BMF_WORKFLOW", "INITIATE_BMF_WORKFLOW", null, forex.initiateBMFWorkflow, true, true, false),
    genericApiSaga("GET_BMF_CITIES", "GET_BMF_CITIES", null, forex.getBMFCities, true, true, false),
    stageApiSaga("SUBMIT_ADD_BMF_PRODUCTS", "ADD_BMF_PRODUCTS", forex.addBMFProducts, true, true),
    stageApiSaga("SUBMIT_BMF_TRAVELLER_DETAILS", "BMF_TRAVELLER_DETAILS", forex.updateTravellerDetails, true, true),
    genericApiSaga("BMF_TRAVELLER_DETAILS_SEND_OTP", "BMF_TRAVELLER_DETAILS_SEND_OTP", null, forex.bmfTravellerDetailsSendOTP, true, true, true),
    genericApiSaga("BMF_DOCUMENTS", "BMF_DOCUMENTS", null, forex.updateBMFDocuments, true, true, true),
    genericApiSaga("BMF_ORDER", "BMF_ORDER", null, forex.submitBmfOrder, true, true, true),
    genericApiSaga("GET_BMF_COUNTRIES", "GET_BMF_COUNTRIES", null, forex.getBMFCountries, true, true, false),
    stageApiSaga("SUBMIT_UPDATE_TRIP_DETAILS", "UPDATE_TRIP_DETAILS", forex.submitTripDetails, true, true),
    stageApiSaga("SUBMIT_PREVIOUS_STAGE_DATA", "PREVIOUS_STAGE_DATA", forex.getPreviousStageData, true, true),
    resetPreviousStageData("UPDATE_PREVIOUS_STAGE_DATA", "PREVIOUS_STAGE_DATA", true, true),
    genericApiSaga("BMF_ORDER_DETAILS", "BMF_ORDER_DETAILS", null, forex.getBMFOrderDetails, true, true, true),
    genericApiSaga("GET_BMF_CURRENCIES", "GET_BMF_CURRENCIES", null, forex.getBMFCurrencies, true, true, false),
    stageApiSaga("SUBMIT_CONFIRM_ORDER_DETAILS", "STAGE_RESPONSE", forex.confirmOrderDetails, true, true, true),
    genericApiSaga("GET_BMF_EXCHANGE_RATE", "GET_BMF_EXCHANGE_RATE", null, forex.getBMFExchangeRate, false, true, false),
    genericApiSaga("GET_BMF_WORKFLOW_STAGE", "GET_BMF_WORKFLOW_STAGE", null, forex.getBMFWorkflowStage, true, true, false),
    genericApiSaga("GET_BMF_WORKFLOW_PROGRESS", "GET_BMF_WORKFLOW_PROGRESS", null, forex.getBMFWorkflowProgress, true, true, false),
    stageApiSaga("SUBMIT_UPDATE_TRIP_CONSENT", "UPDATE_TRIP_CONSENT", forex.updateTripConcent, true, true),
    stageApiSaga("GET_PAYMENT_STATUS", "GET_PAYMENT_STATUS", forex.getPaymentStatus, false, true),    
    genericApiSaga("GET_BMF_REQUIRED_DOCUMENTS", "GET_BMF_REQUIRED_DOCUMENTS", null, forex.getBMFRequiredDocuments, true, true, false),
    genericApiSaga("DELETE_UPLOADED_DOC", "DELETE_UPLOADED_DOC", null, forex.deleteUploadedDoc, true, true, false),
    genericApiSaga("GET_ORDER_DOCUMENTS", "GET_ORDER_DOCUMENTS", null, forex.getOrderDocuments, true, true, false),
    genericApiSaga("GET_PROGRAMS", "GET_PROGRAMS", null, forex.getPrograms, true, true, false),
    genericApiSaga("GET_SPECEFIC_ORDER_DOCUMENTS", "GET_SPECEFIC_ORDER_DOCUMENTS", null, forex.getSpeceficOrderDocuments, true, true, false),
    genericApiSaga("GET_CURRENCY_RATES", "GET_CURRENCY_RATES",null, forex.getCurrencyRates, true, true, false),
    genericApiSaga("GET_POPULAR_CURRENCY_RATES", "GET_POPULAR_CURRENCY_RATES", null, forex.getPopularCurrencyRates, true, true, false),
    stageApiSaga("SUBMIT_RECHECK_STATUS", "SUBMIT_RECHECK_STATUS",forex.getRecheckStatus, true, true, true),
    stageApiSaga("GET_DETAILS_FROM_PAN", "DETAILS_FROM_PAN", forex.getTravellerDetailsFromPan, true, true),
    genericApiSaga("RATE_WHATSAPP_NOTIFY", "RATE_WHATSAPP_NOTIFY", null, forex.rateWhatsappNotify, false, true, false),
    genericApiSaga("PAYMENT_CHECKOUT_LINK", "PAYMENT_CHECKOUT_LINK",  null, forex.getPaymentCheckoutLink, true, true, true),
    genericApiSaga("GET_RATE_AND_REVIEWS", "GET_RATE_AND_REVIEWS",null, forex.getRateAndReviews,true,true,false),
    genericApiSaga("SUBMIT_RATINGS", "SUBMIT_RATINGS",null, forex.updateRatings,true, true,false ),
    genericApiSaga("FOREX_USER_DETAILS", "FOREX_USER_DETAILS",null, forex.forexUserDetails,true, true,true ),
    genericApiSaga("BMF_ORDER_STATUS", "BMF_ORDER_STATUS", null, forex.bmfOrderStatus, true, true,false),
    genericApiSaga("REQUEST_PAYMENT_CHECKOUT", "REQUEST_PAYMENT_CHECKOUT", null, forex.getRequestPaymentCheckoutLinkByID, true, true, true),
    genericApiSaga("GET_DYNAMIC_CONFIGS", "GET_DYNAMIC_CONFIGS",null, forex.getDynamicConfigs,false, true,false ),
    genericApiSaga("GET_VALID_CARD_HOLDER", "GET_VALID_CARD_HOLDER",null, forex.getValidCardHolder,false, true,false ),
    genericApiSaga("GET_TRAVELLER_DETAILS", "GET_TRAVELLER_DETAILS", null, forex.getTravellerDetails, true, true, false),
    genericApiSaga("GET_POKUS_ENABLED", "GET_POKUS_ENABLED",null, forex.getPokusEnabled,false, true,false ),
    genericApiSaga("GET_TM_USER", "GET_TM_USER",null, forex.getTmUser,false, true,false ),
]

export default forexSagas;