import { takeLatest, put } from 'redux-saga/effects';
import * as zero from '../api';
import { otuValidated } from '../reducers/otu';
import { setLoading } from '../reducers/loading';
import { getLoginStatusOfUser } from './loginStatus';
import { ERROR_NO_ACTION, LOGIN_REQUIRED } from '../constants';
import { redirectIntercept, redirectInterceptHref, setItemInStorage, setItemInSessionStorage } from '../utils/common';

export default function* watchValidateOTU() {
    yield takeLatest('VALIDATE_OTU', validateOTU)
}

function* validateOTU(action) {
    let validateOTUResponse;
    try {
        yield put(setLoading(true))
        const { partner, context, otuToken, ins_new_flow } = action.payload;
        if (!otuToken || otuToken === "") {
            yield put(getLoginStatusOfUser());
        }
        else {
            validateOTUResponse = yield zero.validateOTU(partner, context, otuToken,ins_new_flow)
            yield put(setLoading(false))
            if (validateOTUResponse.data.responseStatus === "SUCCESS") {
                if (window.dataLayer) {
                    window.dataLayer.push({ userTrackingId: validateOTUResponse.data.data.trackingId })
                }
                const { zuser, zpartneruser } = validateOTUResponse.data.data;
                if (window.location !== window.parent.location) {   
                    if (zpartneruser) {
                        setItemInStorage("zpartneruser", zpartneruser);
                    }
                }
                setItemInSessionStorage("zpartneruser",zpartneruser)
                if (zuser) {
                    setItemInStorage("zuser", zuser)
                    setItemInSessionStorage("zuser",zuser)
                }
                const { loginRequired } = validateOTUResponse.data.data;
                const { checkoutUrl } = validateOTUResponse.data;

                if (checkoutUrl && !loginRequired) {
                    redirectInterceptHref(checkoutUrl);
                    return;
                }

                yield put(otuValidated({ ...validateOTUResponse.data }));
            } else if (!(validateOTUResponse.data.error != null && (validateOTUResponse.data.error.code == LOGIN_REQUIRED || validateOTUResponse.data.error.code == ERROR_NO_ACTION))) {
                window.dataLayer.push({
                    'event': 'eventTracking',
                    'category': 'OTU',
                    'action': 'OTU_VALIDATION_FAILED',
                    'label': validateOTUResponse.data.error,
                });
                redirectIntercept("/error")
            }
        }

    } catch (e) {
        window.dataLayer.push({
            'event': 'eventTracking',
            'category': 'OTU',
            'action': 'OTU_VALIDATION_FAILED',
            'label': e.message,
        });
        yield put(setLoading(false))
        redirectIntercept("/error")
        console.error(e)
    }
}