import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const getConfigs = createAction('GET_CONFIGS');
export const setConfigs = createAction('SET_CONFIGS');
export const setConfig = createAction('SET_CONFIG');
export const saveConfig = createAction('SAVE_CONFIG');

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
      setConfigs,
      (state, action) => {
        return action.payload
      }
    ],
    [
      setConfig,
      (state, action) => {
        let inserted = false;
        let updatedState = state.map((item, index) => {
          // Replace the item at index 2
          if (item.Key === action.payload.Key) {
            inserted = true;
            return action.payload;
          }
          // Leave every other item unchanged
          return item;
        });
        if (inserted) {
          return inserted;
        } else {
          return [...updatedState, action.payload]
        }
      }
    ]
  ]),
  []
);

// SELECTORS
export const getConfigsState = state => state.configs;

