import { takeLatest, put, select } from 'redux-saga/effects';
import { sendLedgerFeedback } from '../api';
import { setLoading } from '../reducers/loading';
import { ledgerFeedbackSent } from '../reducers/ledgerFeedback';
import { setError } from '../reducers/error';
import { getUserState } from '../reducers/user';
import { LOGIN_REQUIRED, ERROR_NO_ACTION } from '../constants';

export default function* watchSendLedgerFeedback() {
    yield takeLatest('SET_LEDGER_FEEDBACK', sendData)
}

function* sendData(action) {
    try {
        yield put(setLoading(true))
        let { tag, message, partner, context } = action.payload
        const user = yield select(getUserState);
        const userId = user && user.data && user.data.userInfo ? user.data.userInfo.userId : "";
        let feedbackResponse = yield sendLedgerFeedback(partner, context, userId, { tag, message })
        yield put(setLoading(false))
        if (feedbackResponse.data.responseStatus == "SUCCESS") {
            yield put(ledgerFeedbackSent({ ledgerFeedbackSent: true }));
        } else if (feedbackResponse.data.error != null) {
            let error = feedbackResponse.data.error;
            if (!(error!= null && (error.code == LOGIN_REQUIRED || error.code == ERROR_NO_ACTION))) {
                yield put(setError({ ...feedbackResponse.data.error, viewed: false }))
            }
        }
    } catch (e) {
        yield put(setError({ viewed: false, description: 'Seems there is some problem with your network, please check back in a while.' }))
        yield put(setLoading(false))
    }
}