import { takeLatest, put } from 'redux-saga/effects';
import { setClose } from '../reducers/close'
import * as zero from '../api'
import { setLoading } from '../reducers/loading';
import {redirectIntercept} from '../utils/common';

export default function* watchGetConfig() {
    yield takeLatest('GET_CLOSE_URL', getCloseURL)
}

function* getCloseURL(action) {
    try {
        yield put(setLoading(true))
        let response = yield zero.closeURL(action.payload.partner)
        yield put(setLoading(false))
        if (response.data != null)
            yield put(setClose(response.data));
    } catch(e) {
        yield put(setLoading(false))
        redirectIntercept("/error")
        console.log(e)
    }
}