import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const setLoading = createAction('SET_LOADING');

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
        setLoading,
      (state, action) => {
        if (action.payload === true) {
          return {status: action.payload, message: "Loading..."}
        } else if (action.payload === false) {
          return {status: action.payload, message: "Loading..."}
        } else if (typeof action.payload === 'object')
          return action.payload
      } 
    ]
  ]),
  {status: false, message: "Loading..."}
);

// SELECTORS
export const getLoading = state => state;
 
