import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const setRedirectUrl = createAction('SET_REDIRECT_URL');

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
        setRedirectUrl,
      (state, action) => {
        return action.payload
      } 
    ]
  ]),
  {data: null}
);

// SELECTORS
 
export const getRedirectURL = state => state.redirectUrl;