import { handleActions } from 'redux-actions';
import { setAction } from './genericReducer';

// ACTION CREATORS
export const CMS_CONTENT = 'CMS_CONTENT';

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
      setAction(CMS_CONTENT),
      (state, action) => {
        return { ...state, [action.payload.pageKey]: { ...action.payload.data } }
      }
    ],
  ]),
  {}
);
