import { applyMiddleware, createStore, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session'
import saga from '../sagas';
import reducer from '../reducers';


/**
 * Redux persist config
 */
const persistConfig = {
  key: 'root',
  storage: sessionStorage,
  whitelist: ['config', 'login', 'travelData','lenderStage']
}
/**
 * Init redux persist reducer with config, root reducer;
 */
const persistedReducer = persistReducer(persistConfig, reducer)

/**
 * Redux Store middleware
 */
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = process.env.NODE_ENV !== 'production'
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  : compose;
const createStoreWithMiddleware = composeEnhancers(
  applyMiddleware(
    sagaMiddleware
  ),
)(createStore);

export default function configureStore(allSagas, initialState) {
  const store = createStoreWithMiddleware(persistedReducer, initialState);
  sagaMiddleware.run(saga(allSagas));
  const persistor = persistStore(store);
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers');
      store.replaceReducer(nextRootReducer);
    });
  }

  return { store, persistor };
}

