import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const getUsers = createAction('GET_USERS');
export const setUsers = createAction('SET_USERS');

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
        setUsers,
      (state, action) => {
        return action.payload
      } 
    ]
  ]),
  { data: null, error: null}
);

// SELECTORS
export const getUserState = state => state.users;
 
