import { createAction, handleActions } from 'redux-actions';

export const setRuleTestResult = createAction('SET_RULE_TEST_RESULT');;
export const testRule = createAction('TEST_RULE');;

// REDUCERS
export const reducer = handleActions(
    new Map([
      [
        setRuleTestResult,
        (state, action) => {
          return action.payload
        } 
      ]
    ]),
    {}
  );