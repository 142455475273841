import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const getContextFromOTU = createAction('GET_CONTEXT_FROM_OTU');
export const setContext = createAction('SET_CONTEXT');
export const getContextFailed = createAction('GET_CONTEXT_FAILED');

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
        setContext,
      (state, action) => {
        return action.payload
      } 
    ],
    [
        getContextFailed,
      (state, action) => {
        return action.payload
      } 
    ],
  ]),
  {
    "error": null,
    "data": null
  }
);

// SELECTORS
export const getContext = state => state.context;
 
