import axios from 'axios'
import { captureException, withScope, configureScope } from '@sentry/browser';
import uuidv4 from 'uuid/v4';
import { ERROR_NO_ACTION, LOGIN_REQUIRED, MakeMyTrip, OTP_RETRY, SUCCESS } from '../../../constants';
import { getOnboardingStages } from '../util/index'
import { getDeviceDetailsObject, getParameterByName, getSpecificCookie, randomIntFromInterval } from '../util/common';
import { BMF_PROGRAM_COOKIE_KEY, DEVICE, DISPLAY_ERROR, DISPLAY_ERROR_MISMATCH, INR_SESSION_COOKIE, PARTNER_KEY, TM_AUTH_COOKIE } from '../util/constants';
import { checkUnprotectedAPIRoutes } from '../../../utils/common';
import { persistSpecificParamInCookie } from '../util/common';
import { getPartner } from '../util';


const regex = /(\/partners\/)(\w*)(\/)/gm;
let result = regex.exec(window.location.href);
let partner = result && result.length >= 3 ? result[2] : "";
let baseURL = `/ext/api/v1/partners/${partner}/`;
// if (process.env.NODE_ENV !== "production") {
//     baseURL = "http://localhost:8080"
// }
const afi = axios.create({
    baseURL,
    timeout: 30000,
    validateStatus: function (status) {
        return status >= 200 && status < 500; // default
    },
});

const nonCriticalRequests = axios.create({
    baseURL,
    timeout: 20000,
    validateStatus: function (status) {
        return status >= 200 && status < 300; // default
    },
});


const tmAuth = getSpecificCookie(TM_AUTH_COOKIE);
const isForexContext = window?.location?.pathname?.includes("/forex");
const getInrSession = () => {
    return getSpecificCookie(INR_SESSION_COOKIE)
}

// Add a request interceptor
afi.interceptors.request.use(function (config) {
    let uuid = uuidv4();
    config.headers['X-Request-Id'] = uuid;
    if (config?.url?.includes('onboarding/photo/upload')) {
        config.headers["Content-Type"] = "multipart/form-data";
    }
    if (isForexContext) {
        config.headers[TM_AUTH_COOKIE] = tmAuth || "";
        config.headers[PARTNER_KEY] = getPartner();
        config.headers[DEVICE] = JSON.stringify(getDeviceDetailsObject(navigator.userAgent, getPartner()));
    }
    config.headers[INR_SESSION_COOKIE] = getInrSession();
    if (config?.url?.includes('/card/listing')) {
        persistSpecificParamInCookie(INR_SESSION_COOKIE, "false", {
            domain: getPartner() === MakeMyTrip ? ".makemytrip.com" : ".goibibo.com",
            path: "/"
        })
    }

    configureScope(function (scope) {
        scope.setTag("X-Request-Id", uuid);
    });
    // Do something before request is sent
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

export const setupForexInterceptors = (store) => {
    // Add a response interceptor
    afi.interceptors.response.use(function (response) {
        // if (response.headers['z-csrf-token'] !== undefined) {
        //     store.dispatch({ type: "SET_OTHER_INFO", payload: { csrf: response.headers['z-csrf-token'] } })
        //     afi.defaults.headers.common['Z-Csrf-Token'] = response.headers['z-csrf-token'];
        // }
        // if (response.headers['lat'] !== undefined) {
        //     store.dispatch({ type: "SET_OTHER_INFO", payload: { lat: response.headers['lat'] } })
        // }
        // if (response.headers['long'] !== undefined) {
        //     store.dispatch({ type: "SET_OTHER_INFO", payload: { long: response.headers['long'] } })
        // }
        // if (response.status === 200) {

        if (response.data == null
            || (response.data.success !== true
                && response.data.success !== false)) {
            withScope(scope => {
                scope.setFingerprint(['INTERNAL_ERROR']);
                scope.setExtra("url", response.config.url);
                captureException(new Error("UNKNOWN_RESPONSE_STATUS"));
            });
            store.dispatch({
                type: "SET_OTHER_INFO", payload: {
                    requestData: {
                        xRequestId: response && response.config && response.config.headers && response.config.headers['X-Request-Id'],
                        apiURL: (response && response.config && response.config.url ? response.config.url : "NOT FOUND"),
                        reponseData: JSON.stringify(response ? response.data : {}),
                        responseHeaders: JSON.stringify(response && response.headers ? response.headers : {}),
                        pageUrl: JSON.stringify(window.location)
                    }
                }
            })
            store.dispatch({ type: 'INTERNAL_ERROR' })
            return new Promise(() => { });
        } else if (response.data.success === false) {
            store.dispatch({
                type: "SET_OTHER_INFO", payload: {
                    requestData: {
                        xRequestId: response && response.config && response.config.headers && response.config.headers['X-Request-Id'],
                        apiURL: (response && response.config && response.config.url ? response.config.url : "NOT FOUND"),
                        reponseData: JSON.stringify(response ? response.data : {}),
                        responseHeaders: JSON.stringify(response && response.headers ? response.headers : {}),
                        pageUrl: JSON.stringify(window.location)
                    }
                }
            })
            if (response.data.error.code === "INTERNAL_ERROR") {
                withScope(scope => {
                    scope.setFingerprint(['INTERNAL_ERROR']);
                    scope.setExtra("url", response.config.url);
                    captureException(new Error("INTERNAL_ERROR"));
                });
                store.dispatch({ type: 'INTERNAL_ERROR' })
                return new Promise(() => { });
            }
            if (response.data.error.code === DISPLAY_ERROR_MISMATCH) {
                withScope(scope => {
                    scope.setFingerprint([DISPLAY_ERROR_MISMATCH]);
                    scope.setExtra("url", response.config.url);
                    captureException(new Error(DISPLAY_ERROR_MISMATCH));
                });
                return new Promise((resolve) => resolve(response.data));
            }
            if (response.data.error.code === "DISPLAY_ERROR_PAGE") {
                withScope(scope => {
                    scope.setFingerprint(['DISPLAY_ERROR_PAGE']);
                    scope.setExtra("url", response.config.url);
                    captureException(new Error("DISPLAY_ERROR_PAGE"));
                });
                store.dispatch({ type: 'DISPLAY_ERROR_PAGE', payload: response.data.error, redirectUrl: (response.data.data ? response.data.data.redirectUrl : null), retry: false })
                return new Promise(() => { });
            }
            if (response.data.error.code === "DISPLAY_ERROR_PAGE_RETRY") {
                withScope(scope => {
                    scope.setFingerprint(['DISPLAY_ERROR_PAGE_RETRY']);
                    scope.setExtra("url", response.config.url);
                    captureException(new Error("DISPLAY_ERROR_PAGE_RETRY"));
                });
                store.dispatch({ type: 'DISPLAY_ERROR_PAGE', payload: response.data.error, redirectUrl: (response.data.data ? response.data.data.redirectUrl : null), retry: true })
                return new Promise(() => { });
            }
            if (response.data.error.code === LOGIN_REQUIRED) {
                store.dispatch({
                    type: "SET_OTHER_INFO", payload: {
                        loggedIn: false,
                        sessionEnded: true
                    }
                });

                withScope(scope => {
                    scope.setFingerprint([LOGIN_REQUIRED]);
                    scope.setExtra("url", response.config.url);
                    captureException(new Error(LOGIN_REQUIRED));
                });
                //redirect to init
                store.dispatch({ type: LOGIN_REQUIRED })
            } else if (response.data.error.code === ERROR_NO_ACTION) {
                //redirect to init
                store.dispatch({ type: ERROR_NO_ACTION, payload: response.data.error.description })
            } else if (response.data.error.code === DISPLAY_ERROR) {
                if (response.data.error.action !== OTP_RETRY) {
                    store.dispatch({ type: DISPLAY_ERROR, payload: response.data.error.description })
                }
            } else {
                store.dispatch({ type: 'INTERNAL_ERROR' })
                return new Promise(() => { });
            }
        }
        // }
        if (response && response.data && ((response.data.error && response.data.error.code != LOGIN_REQUIRED) || response.data.success)) {
            store.dispatch({
                type: "SET_OTHER_INFO", payload: {
                    loggedIn: checkUnprotectedAPIRoutes(response.config && response.config.url) ? false : true,
                    sessionEnded: false
                }
            });
        }
        return response;
    }, function (error) {
        let response = error.response;
        if (response && response.headers && response.headers['z-csrf-token'] !== undefined) {
            store.dispatch({ type: "SET_OTHER_INFO", payload: { csrf: response.headers['z-csrf-token'] } })
            afi.defaults.headers.common['Z-Csrf-Token'] = response.headers['z-csrf-token'];
        }

        store.dispatch({
            type: "SET_OTHER_INFO", payload: {
                requestData: {
                    xRequestId: response && response.config && response.config.headers && response.config.headers['X-Request-Id'],
                    apiURL: (response && response.config && response.config.url ? response.config.url : "NOT FOUND"),
                    reponseData: JSON.stringify(response ? response.data : {}),
                    responseHeaders: JSON.stringify(response && response.headers ? response.headers : {}),
                    pageUrl: JSON.stringify(window.location)
                }
            }
        })

        withScope(scope => {
            scope.setFingerprint(['NETWORK_REQUEST_ERROR']);
            scope.setExtra("url", error.config.url);
            scope.setExtra("csrf", afi.defaults.headers.common['Z-Csrf-Token'])
            if (response) {
                scope.setExtra("response-data", JSON.stringify(response.data))
                scope.setExtra("response-headers", JSON.stringify(response.headers))
            }
            captureException(error);
        });
        if (response.data.error.code === "INTERNAL_ERROR") {
            withScope(scope => {
                scope.setFingerprint(['INTERNAL_ERROR']);
                scope.setExtra("url", response.config.url);
                captureException(new Error("INTERNAL_ERROR"));
            });
            store.dispatch({ type: 'INTERNAL_ERROR' })
            return new Promise(() => { });
        }
        if (response.data.error.code === DISPLAY_ERROR) {
            store.dispatch({ type: DISPLAY_ERROR, payload: response.data.error.description })
            return new Promise(() => { });
        } else if (response.status < 500) {
            store.dispatch({ type: 'INTERNAL_ERROR' })
            return new Promise(() => { });
        }
        return Promise.reject(error);
    });
}

let headers = {
    'X-REQUEST-ID': uuidv4()
}

export const submitPersonalDetails = (data) => afi.post(getOnboardingStages()["initiate_workflow"].apiRoute, data);
export const uploadPhoto = (data) => afi.post(getOnboardingStages()["upload_photo"].apiRoute, data);
export const getHypervergeToken = (data) => afi.get(`/card-onboarding/api/v1/onboarding/hyperverge/getToken?program=${data.program}`);
export const submitCardAssigned = (data) => afi.post(getOnboardingStages()["show_card_image"].apiRoute, data);
export const updateAddress = (data) => afi.post(getOnboardingStages()[data.program === getSpecificCookie(BMF_PROGRAM_COOKIE_KEY) ? "bmf_update_address" : "update_address"].apiRoute, data);
export const ckycSubmitPersonalDetails = (data) => afi.post(getOnboardingStages()["ckyc_initiate_workflow"].apiRoute, data);
export const ckycUploadPhoto = (data) => afi.post(getOnboardingStages()["ckyc_upload_photo"].apiRoute, data);
export const ckycSubmitCardAssigned = (data) => afi.post(getOnboardingStages()["ckyc_show_card_image"].apiRoute, data);
export const ckycUpdateAddress = (data) => afi.post(getOnboardingStages()["ckyc_update_address"].apiRoute, data);
export const ckycTimerBreached = (data) => afi.post(getOnboardingStages()["ckyc_read_timeout_breached"].apiRoute, data);
export const uploadPhotoHv = ({ program, live_encoded_photo, endPoint }) => {
    let formData = new FormData();
    formData.append("program", program);
    formData.append("live_encoded_photo", live_encoded_photo);
    return afi.post(endPoint, formData);
}

//forex dashboard

export const blockCard = (data) => afi.post("/card-management/api/v1/card/block", data);
export const unblockCard = (data) => afi.post("/card-management/api/v1/card/unblock", data);
export const trackCardStatus = (data) => afi.get(`/card-management/api/v1/card/orders/tracking?program=${data.program}&card_id=${data.card_id}`);
export const getAllDisputes = (data) => afi.get(`/card-management/api/v1/disputes?program=${data.program}&type=${data.type}&card_id=${data.card_id}&page=${data.page}&offset=${data.offset}`);
export const getDisputeReasons = (data) => afi.get(`/card-management/api/v1/disputes/reasons?program=${data.program}`);
export const getDisputeById = (data) => afi.get(`/card-management/api/v1/disputes/${data.dispute_id}?program=${data.program}&card_id=${data.card_id}`);
export const activateCard = (data) => afi.put(`/card-management/api/v1/card/activate`, data);
export const getTransactionModes = (data) => afi.get(`/card-management/api/v1/card/modes?program=${data.program}&card_id=${data.card_id}`)
export const setTransactionModes = (data) => afi.put(`/card-management/api/v1/card/modes/set`, data)
export const setTransactionLimit = (data) => afi.post(`/card-management/api/v1/card/limits/set`, data)
export const getTransactionLimit = (data) => afi.get(`/card-management/api/v1/card/limits?program=${data.program}&card_id=${data.card_id}`)
export const getCurrentUserStatus = (data) => afi.get(`/card-management/api/v1/card/listing${data.program ? `?program=${data.program}` : ""}`);

export const pinSet = (data) => afi.post(`/card-management/api/v1/card/pinset`, data);
export const pinSetVerifyOTP = (data) => afi.post(`/card-management/api/v1/otp-verify`, data);
export const pinSetSendOTP = (data) => afi.post(`/card-management/api/v1/otp-send`, data);

export const getCardDetails = (data) => afi.post(`/card-management/api/v1/card/details`, data);
export const getWithdrawlEligiblity = (data) => afi.get(`/card-management/api/v1/card/withdrawal/eligibility?program=${data.program}&card_id=${data.card_id}`);
export const getWithdrawLimit = ({ cardId, program }) => afi.get(`/card-management/api/v1/${cardId}/withdrawal/limits?program=${program}`);
export const submitWithdraw = (data) => afi.post(`/card-management/api/v1/unload/money`, data);
export const loadRequest = (data) => afi.post(`/card-management/api/v1/card/money/load`, data);
export const postDispute = (data) => afi.post(`/card-management/api/v1/disputes`, data);

export const pincode = (data) => axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${data.pincode}&key=AIzaSyCly1sJ56NfQY9aYSqplkwb9VL_mwSyE4o`);

export const transactions = (data) => afi.post('/card-management/api/v1/txn/history', data);

export const getLoadMoneyTimestamp = (data) => afi.get(`/card-management/api/v1/load/money/settlement/details${data.program ? "?program=" + data.program : ""}`);


//forex dashboard

//bmf
export const updateTripDuration = (data) => afi.post(`/card-onboarding/api/v1/onboarding/update_trip_duration`, data)
export const getBmfTripPurpose = (data) => afi.get("/card-management/api/v1/travel-purposes");
export const updateTripPurpose = (data) => afi.post(`/card-onboarding/api/v1/onboarding/update_trip_purpose`, data)
export const submitBmfOrder = (data) => afi.post(`/card-onboarding/api/v1/onboarding/submit_order`, data)
export const updateTravellerDetails = (data) => afi.post(`/card-onboarding/api/v1/onboarding/update_traveller_details`, data);
export const bmfTravellerDetailsSendOTP = (data) => afi.post(`/card-management/api/v1/orders/${data.order_id}/travellers/otp/send`, data);
export const updateBMFDocuments = (data) => afi.post(`/card-onboarding/api/v1/onboarding/update_documents`, data);
export const getBMFCountries = (data) => afi.get(`/card-management/api/v1/countries?program=${data.program}`);
export const submitTripDetails = (data) => afi.post(`/card-onboarding/api/v1/onboarding/update_trip_details`, data)
export const getPreviousStageData = (data) => afi.get(`/card-onboarding/api/v1/onboarding/get_workflow_stage?program=${data.program}&order_id=${data.order_id}&next_signal_state=${data.stage}`);
export const getBMFOrderDetails = (data) => afi.get(`/card-management/api/v1/orders/${data.order_id}?program=${data.program}`);
export const initiateBMFWorkflow = (data) => afi.post(`/card-onboarding/api/v1/onboarding/initiate_workflow`, { ...data });
export const addBMFProducts = (data) => afi.post(`/card-onboarding/api/v1/onboarding/add_products`, { ...data });
export const getBMFCities = (data) => afi.get(`/card-management/api/v1/cities?program=${data.program}`);
export const getBMFCurrencies = (data) => afi.get(`/card-management/api/v1/currencies?program=${data.program}`);
export const getBMFExchangeRate = ({ program, code, type, product, city, order_id }) => afi.get(`/card-management/api/v1/product/${product}/rate?program=${program}&curr_code=${code}&type=${type}&city=${city}&order_id=${order_id}`);
export const getBMFWorkflowStage = ({ program, order_id, next_signal_state }) => afi.get(`/card-onboarding/api/v1/onboarding/get_workflow_stage?program=${program}&order_id=${order_id}&next_signal_state=${next_signal_state}`);
export const getBMFWorkflowProgress = ({ program, order_id }) => afi.get(`/card-onboarding/api/v1/onboarding/get_workflow_progress?program=${program}&order_id=${order_id}`)
export const updateTripConcent = (data) => afi.post(`/card-onboarding/api/v1/onboarding/document_consent`, data)
export const confirmOrderDetails = (data) => afi.post(`/card-onboarding/api/v1/onboarding/confirm_order_details`, data);
export const getPaymentStatus = (data) => afi.get(`/card-onboarding/api/v1/order/${data.order_id}/payment/status?program=${data.program}`);
export const getBMFRequiredDocuments = (data) => afi.get(`/card-management/api/v1/documents?program=${data.program}`);
export const deleteUploadedDoc = (data) => afi.post(`/card-management/api/v1/documents/delete`, { ...data });
export const getOrderDocuments = (data) => afi.get(`/card-management/api/v1/documents?program=${data.program}&purpose=${data.purpose}`);
export const getSpeceficOrderDocuments = (data) => afi.get(`/card-management/api/v1/orders/${data.order_id}/documents?program=${data.program}`);
export const getPrograms = (data) => afi.get(`/card-management/api/v1/programs`);
export const getCurrencyRates = ({ from_currency, to_currency, from_amount, program }) => afi.get(`/card-management/api/v1/forex-rate?from_currency=${from_currency}&to_currency=${to_currency}&from_amount=${from_amount}&program=${program}`)
export const getPopularCurrencyRates = ({ currency1, currency2, currency3, currency4, program }) => afi.get(`/card-management/api/v1/forex-rate?currency1=${currency1}&currency2=${currency2}&currency3=${currency3}&currency4=${currency4}&program=${program}`)
export const getRecheckStatus = (data) => afi.post(`/card-onboarding/api/v1/refresh/user`, data)
export const getTravellerDetailsFromPan = (data) => afi.post('/card-management/api/v1/user/pan/verify', data)
export const rateWhatsappNotify = ({ event, program, curr_code, order_id, product, city, type = "BUY" }) => afi.post(`/card-management/api/v1/product/${product}/rate/notify/${event}?program=${program}&curr_code=${curr_code}&type=${type}&city=${city}&order_id=${order_id}`, {})
export const getPaymentCheckoutLink = (data) => afi.post('/card-management/api/v1/card/money/load/request', data);
export const getRateAndReviews = (program) => afi.get(`/card-management/api/v1/fetch/rate/reviews/${program}`);
export const updateRatings = ({ data }) => afi.post(`/card-management/api/v1/rate/review/${data.eventType}`, data);
export const forexUserDetails = (program) => afi.get(`/card-onboarding/api/v1/user/details?program=${program}`);
export const bmfOrderStatus = (data) => afi.get(`/card-onboarding/api/v1/onboarding/${data.order_id}/status?program=${data.program}`);
export const getRequestPaymentCheckoutLinkByID = (data) => afi.get(`/mmt/card-management/api/v1/card/money/load/redirect-details?checkout_id=${data?.checkout_id}`);
export const getDynamicConfigs = (data) => afi.get(`/card-management/api/v1/get-config`);
export const getValidCardHolder = (data) => afi.get(`/card-management/api/v1/user/valid-card/check?program=${data.program}${data.eventType ? `&event=${data.eventType}` : ''}`);
export const getTravellerDetails = (data) => afi.get(`/card-management/api/v1/traveller/details?program=${data.program}`);
export const getPokusEnabled = (data) => afi.get(`/card-management/api/v1/user/experiment/forex_currency_revamp/state`);
export const getTmUser = (data) => afi.get(`/card-management/ext/api/v1/user`)

