import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const saveRuleMeta = createAction('SAVE_RULE_META');
export const setRuleMeta = createAction('SET_RULE_META')

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
        setRuleMeta,
      (state, action) => {
        return action.payload
      } 
    ]
  ]),
  {}
);

