import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const setPan = createAction('SET_PAN');
export const setAddress = createAction('SET_ADDRESS');
export const register = createAction('REGISTER'); 
export const setRegistratonError = createAction('REGISTRATION_ERROR'); 


// REDUCERS
export const reducer = handleActions(
  new Map([
    [
      setPan,
      (state, action) => {
        return {...state, ...action.payload}
      } 
    ],
    [
        setAddress,
        (state, action) => {
            return {...state, ...action.payload}
        } 
    ],
    [
      setRegistratonError,
      (state, action) => {
          return {...state, ...action.payload}
      } 
  ],
  ]),
  {pan: null, pin: null, street: null, village: null, appointment_date: null, appointment_start_time: null, address: null, tnc: false, registration_error: null} 
);

// SELECTORS
export const getPanAndAddress = state => state.panAndAddress;
 
