import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const getLedgerLoanDetails = createAction('GET_LEDGER_LOAN_DETAILS');
export const setLedgerLoanDetails = createAction('SET_LEDGER_LOAN_DETAILS');

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
        setLedgerLoanDetails,
      (state, action) => {
        return action.payload
      } 
    ]
  ]),
  {}
);
 
