import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const getFacts = createAction('GET_FACTS')
export const setOtherInfo = createAction('SET_OTHER_INFO');

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
    setOtherInfo,
      (state, action) => {
        return {...state, ...action.payload}
      } 
    ]
  ]),
  {} 
);

// SELECTORS
export const getAgentLogin = state => state.agentLogin;
 