import React from "react";
import LoadingOverlay from "react-loading-overlay";
import { useSelector } from "react-redux";

import "./CardLoader.css";

function CardLoader() {
  const loading = useSelector((state) => state.loading && state.loading.status);
  return (
    <LoadingOverlay active={!loading} spinner text="Loading..." /> 
  );
}

export default CardLoader;
