import classnames from 'classnames';
import { put, } from 'redux-saga/effects';
import { getCurrentStage } from '../reducers/stage'
import { setLoading } from '../reducers/loading'
import { LOGIN_REQUIRED, KISSHT, ZEST, IDFC, CF, INSURANCE_OFFERINGS, DEEPLINK_PARAM_ORDER_ID } from '../constants';
import { getSpecificCookie, redirectIntercept, redirectInterceptPush, sendGAEventForInit } from '../utils/common';

export function getLenderLogo(lender, context, krysalisConfig) {
    if (lender) {
        switch (lender.toLowerCase()) {
            case KISSHT:
                return (krysalisConfig && krysalisConfig.data && krysalisConfig.data.lenders && krysalisConfig.data.lenders.KISSHT ? krysalisConfig.data.lenders.KISSHT.logo : "");
            case ZEST:
                return (krysalisConfig && krysalisConfig.data && krysalisConfig.data.lenders && krysalisConfig.data.lenders.ZEST ? krysalisConfig.data.lenders.ZEST.logo : "");
            case IDFC:
                return (krysalisConfig && krysalisConfig.data && krysalisConfig.data.lenders && krysalisConfig.data.lenders.IDFC ? krysalisConfig.data.lenders.IDFC.logo : "");
            case CF:
                return (krysalisConfig && krysalisConfig.data && krysalisConfig.data.lenders && krysalisConfig.data.lenders.CF ? krysalisConfig.data.lenders.CF.logo : "");
            default:
                return;
        }
    }
}



//redirect to lender url
export function redirectToLender(redirectUrl){
    window.location.href = redirectUrl;
}

export function redirectToLedger(config,user,partner){
    if (config.ledgerUrl) {
        sendGAEventForInit(partner, "ledger", user, "ledger", true);
        if (config.ledgerUrl.substring(0, 7) == "https://") {
            window.location.href =  config.ledgerUrl
        } else {
            redirectInterceptPush(config.ledgerUrl)
        }
    } else {
        sendGAEventForInit(partner, "ledger", user, "ledger", true);
        redirectInterceptPush(getOtherCompleteRoute('ledger', partner, 'ledger'));
    }
}

//rejected page redirection
export function redirectToGenericPage(partner,context){
    redirectIntercept(`/partner/${partner}/context/${context}/lendingCommon${window.location.search}`)
}

export function getLenderStyles(lender) {
    return classnames(
        { 'cf_logo': lender === CF },
        { 'kissht_logo': lender === KISSHT },
        { 'idfc_logo': lender === IDFC },
        { 'zest_logo': lender === ZEST }
    );
}


export function getLenderLogoClass(lender, context, krysalisConfig) {
    if (lender) {
        switch (lender.toLowerCase()) {
            case KISSHT:
                switch (context) {
                    default: return "kissht__logo";
                }
                break;

            case ZEST:
                switch (context) {
                    default: return "zest__logo";
                }
                break;

            case IDFC:
                switch (context) {
                    default: return "idfc__logo";
                }
                break;
            case CF:
                switch (context) {
                    default: return "cf__logo";
                }
                break;


        }
    }
}

const retryConfig = {
    "zest_employment_info": {
        retryCount: 18,
        delay: 5000
    },
    "zest_kyc_verification": {
        retryCount: 45,
        delay: 2000
    }
}

export function* retry(partner, context, userId, lender, stage, data) {

    let retConfig = retryConfig[stage.data.data.stageDetails.stageName];

    if (data && data.retryCount !== undefined && data.retryCount < retConfig.retryCount) {
        yield put(setLoading({ status: true, message: "Evaluating your eligibility, it will take some more time.." }))
        yield sleep(retConfig.delay);
        yield put(getCurrentStage({ partner, context, userId, lender, stage: "current", data: { retryCount: data.retryCount + 1 }, loadingMessage: "Evaluating your eligibility, it will take some more time.." }))
    } else {
        redirectIntercept(mapStageToRoute(stage.data.data.stageDetails.stageName + "_pending", partner, context));
    }
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function getLenderDisplayName(krysalisConfig, user) {
    let lender = user && user.data != null && user.data.loanInfo != null ? user.data.loanInfo.lender.toUpperCase() : "";
    return (krysalisConfig && krysalisConfig.lenders && krysalisConfig.lenders[lender] != null ? krysalisConfig.lenders[lender].displayName : "");
}

const kisshtRouteMap = {
    "kissht_userinfo_basic": "/personal-info",
    "kissht_userinfo_extended": "/employment-info",
    "kissht_eligible": "/success",
    "kissht_reject": "/rejected",
    "kissht_userinfo_min": "/customer-pan",
    "kissht_eligible_min": "/customer",
    "kissht_doc_upload": "/kyc-upload",
    "kissht_si_bank_details": "/setup-si",
    "kissht_si_select_mode": "/enach-select-mode",
    "kissht_si_pending": "/si-pending",
    "kissht_thankyou": "/thank-you",
    "kissht_bank_list": "/banks",
    "kissht_cohort_active": "/kissht/cohort/thank-you",
    "kissht_cohort_reject": "/rejected"
}

const zestRoutes = {
    "zest_userinfo_basic": "/zest/personal-info",
    "zest_employment_info": "/zest/employment-info",
    'zest_thankyou': "/thank-you",
    'zest_si_pending': "/si-pending",
    'zest_si_select_mode': "/enach-select-mode",
    'zest_si_bank_details': "/setup-si",
    'zest_additional_details': "/zest/additional-details",
    'zest_doc_upload': "/zest/zest-doc-upload",
    'zest_eligible': "/success",
    "zest_employment_info_pending": "/zest/zest-eligibility-pending",
    "zest_kyc_verification_pending": "/zest/zest-kyc-pending?kyc=true",
    "zest_reject": "/rejected",
    "zest_verify_aadhar": "/zest/verify-aadhar",
    "zest_cohort_active": "/success-page-post-login",
    "zest_min_active": "/success-page-post-login"
}

const idfcRoutes = {
    "idfc_pan": "/idfc/pan",
    "idfc_aadhaar": "/idfc/aadhaar",
    "idfc_verify_aadhar": "/idfc/aadhaar-verify",
    "idfc_consent": "/idfc/consent",
    "idfc_eligible": "/idfc/success",
    "idfc_pending": "/idfc/pending",
    "idfc_si_bank_details": "/setup-si",
    "idfc_si_select_mode": "/enach-select-mode",
    "idfc_si_pending": "/si-pending",
    'idfc_thankyou': "/thank-you",
    "idfc_reject": "/rejected",
    "idfc_purge": "/idfc/purge"
}

const iciciRoutes = {
    "icici_userinfo": "/icici/email/verify"
}

const lenderRoutes = {
    "lender_filter": "/login-location",
    "lender_waitlist": "/rejected"
}

const cfRoutes = {
    'cf_thankyou': "/thank-you",
    "cf_reject": "/rejected"
}

const stageRouteMap = {
    ...kisshtRouteMap,
    ...zestRoutes,
    ...idfcRoutes,
    ...iciciRoutes,
    ...lenderRoutes,
    ...cfRoutes
}

export const getValueFromQueryParams = (key, searchQueryParam) => {
    const queryParams = new URLSearchParams(searchQueryParam);
    if (queryParams.has(key)) {
        return queryParams.get(key);
    }
}

export const mapStageToRoute = (stageName, partner, context, pTxnId) => {
    if (partner === undefined || context === undefined || stageName === undefined || stageRouteMap[stageName] === undefined) {
        return "/error"
    }
    if (pTxnId !== undefined) {
        return `/partner/${partner}/context/${context}${stageRouteMap[stageName]}/${pTxnId}`;
    }

    if (stageName === "idfc_pan") {

        let pincodePageShown = getSpecificCookie("pincodePageShown");
        return `/partner/${partner}/context/${context}${stageRouteMap[stageName]}${pincodePageShown ? "?fromPin=true" : ""}`;
    }

    return `/partner/${partner}/context/${context}${stageRouteMap[stageName]}`;
}

//@TODO : Send fn parameters in an object instead of separate parameters
export const getOtherCompleteRoute = (route, partner, context, sendTo = "", actionVal = "", postSales = "", deeplink = "", orderId = "", deeplinkParamOrderId="") => {
    if (partner === undefined || context === undefined) {
        return "/error"
    }
    switch (route) {
        case "refresh": return `/partners/${partner}/init/${context}/refresh?postSales=${postSales}&action=${actionVal}${orderId ? "&orderId=" + orderId : ""}${deeplink ? "&deeplink=" + deeplink : ""}${deeplinkParamOrderId ? "&"+(DEEPLINK_PARAM_ORDER_ID)+"=" + (deeplinkParamOrderId) : ""}${sendTo ? "&sendTo=" + sendTo : ""}`;
        case LOGIN_REQUIRED: return `/partners/${partner}/init/${context}/otp`;
        case 'otp': return `/partner/${partner}/context/${context}/otp`;
        case 'ledgerSOA': return `/partner/${partner}/context/${context}/getStatementPage`;
        case 'ledgerFAQ': return `/partner/${partner}/context/${context}/faqPage`;
        case 'ledgerFeedback': return `/partner/${partner}/context/${context}/feedbackPage`;
        case 'ledger': return `/partner/${partner}/context/${context}/ledgerPage`;
        case 'ledgerEMIDetailPage': return `/partner/${partner}/context/${context}/EMIDetailPage`;
        case 'downloadApp': return `/partner/${partner}/context/${context}/download-app`;
        case 'tnc': return `/partner/${partner}/context/${context}/tnc`;
        //no default
    }
    return "/error";
}

export const getRouteForInsurance = ({ route, partner, id, context, subCategory, refID, action = "", orderId = "" }) => {
    if (partner === undefined || context === undefined) {
        return "/insurance/error"
    }

    if (route === INSURANCE_OFFERINGS) {
        return `/partner/${partner}/context/${context}/landing?landing-no-redirect=true`;
    }

    switch (route) {
        case 'travel-landing': return `/partner/${partner}/context/${context}/travel-landing`;
        case 'landing-no-redirect': return `/partner/${partner}/context/${context}/landing?landing-no-redirect=true`;
        case 'landing': return `/partner/${partner}/context/${context}/landing?action=${action}&orderId=${orderId}`;
        case 'products': return `/partner/${partner}/context/${context}/products`;
        case 'policy-details': return `/partner/${partner}/context/${context}/policy-details?action=${action ?? ""}`;
        case 'add-details': return `/partner/${partner}/context/${context}/add-details`;
        case 'insurance-activated': return `/partner/${partner}/context/${context}/insurance-activated`;
        case `policy-details/${id}`: return `/partner/${partner}/context/${context}/policy-details/${id}?action=${action ?? ""}`;
        case 'terms-and-conditions': return `/partner/${partner}/context/${context}/terms-and-conditions`;
        case 'claim-documents': return `/partner/${partner}/context/${context}/claim-documents?id=${id}`;
        case 'policy-details-landing': return `/partner/${partner}/context/${context}/policy-details?landing=true&action=${action}${orderId ? "&orderId=" + orderId : ""}`;
        case 'edit-details': return `/partner/${partner}/context/${context}/edit-policy-details/${id}?refID=${refID}&action=${action ?? ""}`;
        case 'policy-details-refetch': return `/partner/${partner}/context/${context}/policy-details/${id}?refetch=true&action=${action ?? ""}`;
        default: return "/insurance/error";
    }
}

export const shouldPoleStage = (stageData) => {
    return (
        stageData && stageData.data && stageData.data.data &&
        (
            (stageData.data.data.stageDetails.status.toUpperCase() === "PENDING" && stageData.data.data.stageDetails.stageName === "zest_employment_info") ||
            (stageData.data.data.stageDetails.status.toUpperCase() === "PENDING" && stageData.data.data.stageDetails.stageName === "zest_kyc_verification")
        )
    );
}

export const checkIfRedirectIsRequired = (stageData) => {
    return stageData && stageData.data && stageData.data && stageData.data.stageDetails.stageName === "icici_userinfo" &&
        stageData.data.stageDetails.status.toUpperCase() === "COMPLETED"
}

export const shouldRefreshUser = (stageName) => {
    return stageName === "kissht_eligible" ||
        stageName === "kissht_eligible_min" ||
        stageName === "kissht_thankyou" ||
        stageName === "zest_eligible" ||
        stageName === "zest_thankyou"

}

export const isAutoSubmitStage = (stage) => {
    switch (stage.data.data.stageDetails.stageName) {
        case "idfc_aadhaar": return (stage.data.data.stageDetails.xdata && stage.data.data.stageDetails.xdata.ekycRequired === false ? true : false);
        case "idfc_ckyc": return true;
        case "zest_kyc_verification":
            if (stage.data.data.stageDetails.xdata && stage.data.data.stageDetails.xdata.redirectUrl === "") {
                return true;
            };
            break;
        default: return;
    }

}

export const shouldRestartOnboardingFlow = (stageData) => {
    return (
        stageData && stageData.data && stageData.data.data &&
        (
            (stageData.data.data.stageDetails.stageName === "idfc_purge" && stageData.data.data.stageDetails.xdata && stageData.data.data.stageDetails.xdata.purge)
        )
    );
}

export const doNotRedirect = (stageDetails) => {
    return stageDetails && stageDetails.stageName === "idfc_aadhaar" && stageDetails.xdata && stageDetails.xdata.otp;
}
