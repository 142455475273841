import { takeLatest, put } from 'redux-saga/effects';
import { history } from "../containers/Router";
import { setOtherInfo } from '../reducers/otherInfo';
import { hashCode } from '../utils/common';

export default function* watchDisplayError() {
    yield takeLatest('ERROR_NO_ACTION', handleInternalError)
}

function* handleInternalError(action) {
    try {
        yield put(setOtherInfo({noActionErrorPageData: action.payload}))
        history.replace(`/error-na?type=${Math.abs(hashCode((action.payload && action.payload.description) ?? ""))}`)
    } catch(e) {
        console.log(e)
    }
}