import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const setAgentLogin = createAction('SET_AGENT_LOGIN');

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
      setAgentLogin,
      (state, action) => {
        return {...state, ...action.payload}
      } 
    ]
  ]),
  {username: null, password: null} 
);

// SELECTORS
export const getAgentLogin = state => state.agentLogin;
 
