import { all } from 'redux-saga/effects';

/**
 * Root Saga
 */

export default function(allSagas) {
    return function* rootSaga() {
        yield all(allSagas);
    }
}
 
