import { takeLatest, put, select } from "redux-saga/effects";
import * as zero from "../api";
import { setLoading } from "../reducers/loading";
import { history } from "../containers/Router";
import { otpValidated } from "../reducers/otp";
import { setError } from "../reducers/error";
import { getOtherCompleteRoute } from "../utils/stage";
import { getLoginStatusOfUser } from "./loginStatus";
import { persistSpecificParamInCookie, redirectIntercept } from "../utils/common";

import {
  LOGIN_FROM_LANDING,
  LOGIN_REQUIRED,
  ONBOARDING,
  ERROR_NO_ACTION,
  INSURANCE,
  LOGIN_SESSION_STORAGE_KEY,
} from "../constants";
import { clearUser, getUser, setUser } from "../reducers/user";

import { getCurrentLenderStage } from "../reducers/lendingCurrentStage";

export default function* watchValidateOTP() {
  yield takeLatest("VALIDATE_OTP", validateOTP);
}

function* validateOTP(action) {
  try {
    yield put(setLoading(true));
    const {
      wa_postsales,
      orderID,
      partner,
      context,
      otp,
      sendTo,
      action: actionVal,
      postSales,
      bmf_product,
      bmf_program,
      deeplink,
      deeplinkParamOrderId,
    } = action.payload;
    let validateOTPResponse = yield zero.validateOTP(partner, otp);
    yield put(setLoading(false));
    if (
      validateOTPResponse.data &&
      validateOTPResponse.data.responseStatus == "SUCCESS"
    ) {
      persistSpecificParamInCookie(LOGIN_SESSION_STORAGE_KEY, true);
      yield put(otpValidated(true));

      if (wa_postsales && context === INSURANCE && orderID) {
        yield put(clearUser({}));
        yield put(getUser({ partner, context }));
        redirectIntercept(
          `/partner/${partner}/context/${context}/policy-details/${orderID}${window.location.search}&refetch=true`
        );
        return;
      }

      if (partner !== "tm" || context === ONBOARDING) {
        redirectIntercept(
          `${getOtherCompleteRoute(
            "refresh",
            partner,
            context,
            sendTo,
            actionVal,
            postSales,
            deeplink,
            null,
            deeplinkParamOrderId
          )}${bmf_product ? "&bmf_product=" + bmf_product : ""}${
            bmf_program ? "&bmf_program=" + bmf_program : ""
          }`
        );
      } else if (context === LOGIN_FROM_LANDING) {
        redirectIntercept("/products");
      } else {
        yield put(getLoginStatusOfUser({ context }));
      }
    } else {
      let error = validateOTPResponse.data.error;
      if (
        !(
          error != null &&
          (error.code == LOGIN_REQUIRED || error.code == ERROR_NO_ACTION)
        )
      ) {
        window.dataLayer.push({
          event: "eventTracking",
          category: "OTP",
          action: "OTP_VALIDATION_FAILED",
          label: validateOTPResponse.data.error.description,
        });
        yield put(
          setError({ ...validateOTPResponse.data.error, viewed: false })
        );
      }
    }
  } catch (e) {
    window.dataLayer.push({
      event: "eventTracking",
      category: "OTP",
      action: "OTP_VALIDATION_FAILED",
      label: e.message,
    });
    yield put(setLoading(false));
    redirectIntercept("/error");
    console.error(e);
  }
}
