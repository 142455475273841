import { takeLatest, put, select } from 'redux-saga/effects';
import { getLedger } from '../api';
import { setLoading } from '../reducers/loading';
import { setLedgerInfo } from '../reducers/ledger';
import { setError } from '../reducers/error';
import { getUserState } from '../reducers/user';
import { LOGIN_REQUIRED, ERROR_NO_ACTION } from '../constants';

export default function* watchGetLedgerInfo() {
    yield takeLatest('GET_LEDGER_INFO', getData)
}

function* getData(action) {
    try {
        yield put(setLoading(true))
        const { partner, context } = action.payload;
        const user = yield select(getUserState);
        const userId = user.data && user.data.userInfo ? user.data.userInfo.userId : "";
        let ledgerResponse = yield getLedger(partner, context, userId)
        yield put(setLoading(false))
        if (ledgerResponse.data.data != null && ledgerResponse.data.responseStatus == "SUCCESS") {
            yield put(setLedgerInfo(ledgerResponse.data.data));
        } else {
            let error = ledgerResponse.data.error;
            if (!(error!= null && (error.code == LOGIN_REQUIRED || error.code == ERROR_NO_ACTION))) {
                yield put(setLedgerInfo({ banner: { heading: "", theme: "tripMoney" } }))
                //yield put(setError({viewed: false, description: ledgerResponse.data.error.description}))
            }
        }
    } catch (e) {
        yield put(setError({ viewed: false, description: 'Seems there is some problem with your network, please check back in a while.' }))
        yield put(setLoading(false))
    }
}