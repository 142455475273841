import { takeLatest, put } from 'redux-saga/effects';
import { initPaymentOptions } from '../api';
import { setLoading } from '../reducers/loading';
import { setError } from '../reducers/error';
import { setOtherInfo } from '../reducers/otherInfo';
import { LOGIN_REQUIRED, ERROR_NO_ACTION } from '../constants';

export default function* watchInitPayment() {
    yield takeLatest('INIT_PAYMENT', initPayOption)
}

function* initPayOption(action) {
    try {
        yield put(setLoading(true))
        const {partner, pTxnId, selectedTenure} = action.payload
        window.dataLayer.push({
            'event': 'eventTracking',
            'category': 'TRANSACTION',
            'action': 'INIT_PAYMENT_OPTIONS',
            'label': 'pTxnId',
            'value': pTxnId
        });
        let initPaymentResponse = yield initPaymentOptions(partner, pTxnId, selectedTenure)
        yield put(setLoading(false))
        if (initPaymentResponse.data != null && initPaymentResponse.data.responseStatus == "SUCCESS") {
            window.dataLayer.push({
                'event': 'eventTracking',
                'category': 'TRANSACTION',
                'action': 'INIT_PAYMENT_OPTIONS_SUCCESS',
                'label': 'ztxnId',
                'value': initPaymentResponse.data.data.ztxnId
            });
            yield put(setOtherInfo({ztxnId: initPaymentResponse.data.data.ztxnId}))
        } else if (initPaymentResponse.data != null && initPaymentResponse.data.error) {
            let error = initPaymentResponse.data.error;
            if (!(error != null && (error.code == LOGIN_REQUIRED || error.code == ERROR_NO_ACTION))) {
                window.dataLayer.push({
                    'event': 'eventTracking',
                    'category': 'TRANSACTION',
                    'action': 'INIT_PAYMENT_OPTIONS_FAILED',
                    'label': initPaymentResponse.data.error.description,
                    'value': 0
                });
                yield put(setOtherInfo({ztxnId: ''}))
                yield put(setError({...initPaymentResponse.data.error, viewed: false}))
            }
        }        
    } catch(e) {
        window.dataLayer.push({
            'event': 'eventTracking',
            'category': 'TRANSACTION',
            'action': 'INIT_PAYMENT_OPTIONS_FAILED',
            'label': e.message,
            'value': 0
        });
        yield put(setOtherInfo({ztxnId: ''}))
        yield put(setError({viewed: false, description: 'Seems there is some problem with your network, please check back in a while.'}))
        yield put(setLoading(false))
    }
}