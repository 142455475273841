import { takeLatest, put, select } from 'redux-saga/effects';
import { getLedgerLoanDetails } from '../api';
import { setLoading } from '../reducers/loading';
import { setLedgerLoanDetails } from '../reducers/ledgerLoanDetails';
import { setError } from '../reducers/error';
import { getUserState } from '../reducers/user';
import { LOGIN_REQUIRED, ERROR_NO_ACTION } from '../constants';

export default function* watchGetLedgerLoanDetails() {
    yield takeLatest('GET_LEDGER_LOAN_DETAILS', getData)
}

function* getData(action) {
    try {
        yield put(setLoading(true))
        let {
            accountId,
            partnerId,
            txnId,
            partner,
            context
        } = action.payload
        const user = yield select(getUserState);
        const userId = user && user.data && user.data.userInfo ? user.data.userInfo.userId : "";
        let ledgerLoanDetailsResponse = yield getLedgerLoanDetails(context, partner, txnId, userId);
        yield put(setLoading(false))
        if (ledgerLoanDetailsResponse.data != null && ledgerLoanDetailsResponse.data.responseStatus == "SUCCESS") {
            yield put(setLedgerLoanDetails(ledgerLoanDetailsResponse.data.data));
        } else if (ledgerLoanDetailsResponse.data != null && ledgerLoanDetailsResponse.data.error) {
            let error = ledgerLoanDetailsResponse.data.error;
            if (!(error!= null && (error.code == LOGIN_REQUIRED || error.code == ERROR_NO_ACTION))) {
                yield put(setError({ viewed: false, description: ledgerLoanDetailsResponse.data.error.description }))
            }
        }
    } catch (e) {
        yield put(setError({ viewed: false, description: 'Seems there is some problem with your network, please check back in a while.' }))
        yield put(setLoading(false))
    }
}