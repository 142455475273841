import { takeLatest, put } from 'redux-saga/effects';
import { setLoading } from '../reducers/loading';
import { setAction } from '../reducers/genericReducer';
import { setError } from '../reducers/error';
import {redirectIntercept} from '../utils/common';
export default function* watch(actionName, redirectUrl, getResponseExectuor, loader = true, passEntireResponse = false, showError = true) {
    yield takeLatest(`GET_${actionName}`, getWorker(actionName, redirectUrl, getResponseExectuor, loader, passEntireResponse, showError))
}

const getWorker = (actionName, redirectUrl, getResponseExectuor, loader = true, passEntireResponse = false, showError = true) => {
    return function* getData(action) {
        try {
            if (loader)
                yield put(setLoading(true))
            let response = yield getResponseExectuor(action.payload)
            if (loader)
                yield put(setLoading(false))
            yield put(setAction(actionName)(response.data));
        } catch (e) {
            if (loader)
                yield put(setLoading(false))
            if (e.response && e.response.status === 401) {
                redirectIntercept(`/login?redirect_url=${redirectUrl}`);
            } else if (e.response && e.response.status === 403) {
                yield put(setError({ viewed: false, description: 'You are not authorized to access!' }))
            } else {
                if (showError) {
                    yield put(setError({ viewed: false, description: 'Seems there is some problem with your network, please check back in a while.' }))
                }
            }
            console.log(e)
        }
    }
}