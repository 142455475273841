import { BROWSER, LANDING, WEBVIEW,HOME_PAGE } from "../constants";
import { getSpecificCookie } from "../utils/common";
import * as zero from '../api';
import { SetTravelLoan } from "../reducers/travelLoanReducer";
import { put, takeLatest } from "redux-saga/effects";

export default function* watchGetUser() {
    yield takeLatest('GET_NEW_TRAVEL_LOAN',getTravelLoan)
}

function* getTravelLoan(action) {
    try {
        const { context,partner,lender } = action.payload;
        if (context === LANDING) {
             
            const reqBody = {
                user_agent_tag : window.mmt_android_bridge ? WEBVIEW : BROWSER,
                partner : partner,
                utm_source : HOME_PAGE,
                utm_medium  : getSpecificCookie('utm_medium') ?? (partner + 'app')
            }
            let response = yield zero.getTravelLoan(reqBody, lender, partner);
            yield put(SetTravelLoan(response?.data))
        }

    } catch (e) {
         //TODO : use proper metrics for error handling.
         console.error(e);
    }
}