import { takeLatest, select } from 'redux-saga/effects';
import { LOGIN_REQUIRED } from '../constants';
import { getUrlParams } from '../reducers/urlParams';
import { getUserState } from '../reducers/user';
import { getSessionExpiredRedirectUrl, getUserMobile } from '../utils/common';

export default function* watchLoginRequired() {
    yield takeLatest(LOGIN_REQUIRED, handleLoginRequired)
}

function* handleLoginRequired() {
    try {
        const { partner, context, action, postSales } = yield select(getUrlParams);
        const user = yield select(getUserState);
        const mobile = getUserMobile(user);

        window.dataLayer.push({
            'event': 'eventTracking',
            'category': LOGIN_REQUIRED,
            'action': "REDIRECTING_TO_INIT_OTU",
            'label': partner +"-"+ context
        });
        //redirecting to initOTU on session expiry
        window.location = getSessionExpiredRedirectUrl({ partner, context, action, postSales, mobile })
    } catch(e) {
        console.log(e)
    }
}