import { takeLatest, put } from 'redux-saga/effects';
import FileDownload from 'js-file-download'
import { setLoading } from '../reducers/loading';
import { setAction } from '../reducers/genericReducer';
import { setError } from '../reducers/error';
import { ERROR_NO_ACTION, LANDING, LOGIN_REQUIRED } from '../constants';

let history;

export default function (hstry) {
    history = hstry;
    return watch;
}

function* watch(actionName, redirectUrl, getResponseExectuor, loader = true, passEntireResponse = false, showError = true, customLoader = false) {
    yield takeLatest(`GET_${actionName}`, getWorker(actionName, redirectUrl, getResponseExectuor, loader, passEntireResponse, showError, customLoader));
}

const getWorker = (actionName, redirectUrl, getResponseExectuor, loader = true, passEntireResponse = false, showError = true, customLoader = false) => {
    return function* getData(action) {
        try {
            const { context } = action.payload;
            if (loader && context != LANDING) {
                yield put(setLoading(true));
            }
            if (customLoader) {
                yield put(setLoading({ customLoader: true }));
            }
            let response = yield getResponseExectuor(action.payload)
            if (loader) {
                yield put(setLoading(false));
            }
            if (customLoader) {
                yield put(setLoading({ customLoader: false }));
            }
            if (response.data.responseStatus === "SUCCESS") {
                yield put(setAction(actionName)(passEntireResponse ? response.data : (response.data.data ? response.data.data : response.data)))
            } else {
                if (response.headers["content-disposition"] && response.headers["content-disposition"].indexOf("attachment") > -1) {
                    FileDownload(response.data, 'report.csv')
                }
                yield put(setAction(actionName)(response.data))
                if (showError) {
                    if(response.data.error && response.data.error.code && (response.data.error.code === ERROR_NO_ACTION || response.data.error.code === LOGIN_REQUIRED)){
                        yield put({ type: response.data.error.code, payload: response.data.error.description});
                    }else if(!(response.data.error.code == LOGIN_REQUIRED || response.data.error.code == ERROR_NO_ACTION)){
                        yield put(setError({ ...response.data.error, viewed: false }));
                    }
                }
            }
        } catch (e) {
            if (loader)
                yield put(setLoading(false))
            else if (e.response && e.response.status === 403) {
                yield put(setError({ viewed: false, description: 'You are not authorized to access!' }))
            } else {
                if (showError) {
                    yield put(setError({ viewed: false, description: 'Seems there is some problem with your network, please check back in a while.' }))
                }
            }
            console.log(e)
        }
    }
}