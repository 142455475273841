import { takeLatest, put } from 'redux-saga/effects';
import { captureException, configureScope } from '@sentry/browser';
import { getPaymentOptions } from '../api';
import { setLoading } from '../reducers/loading';
import { setError } from '../reducers/error';
import { setPaymentOptions } from '../reducers/paymentOptions';
import { LOGIN_REQUIRED, ERROR_NO_ACTION } from '../constants';

export default function* watchGetPaymentOptions() {
    yield takeLatest('GET_PAYMENT_OPTIONS', fetchPaymentOption)
}

function* fetchPaymentOption(action) {
    try {
        yield put(setLoading(true))
        const { partner, pTxnId } = action.payload
        window.dataLayer.push({
            'event': 'eventTracking',
            'category': 'TRANSACTION',
            'action': 'GET_PAYMENT_OPTIONS',
            'label': 'pTxnId',
            'value': pTxnId
        });
        let paymentOptionsResponse = yield getPaymentOptions(partner, pTxnId)
        configureScope((scope) => {
            scope.setTag("pTxnId", pTxnId)
            scope.setTag("paymentOptionsResponse", paymentOptionsResponse)
        });
        yield put(setLoading(false))
        if (paymentOptionsResponse.data != null && paymentOptionsResponse.data.responseStatus === "SUCCESS") {
            window.dataLayer.push({
                'event': 'eventTracking',
                'category': 'TRANSACTION',
                'action': 'GET_PAYMENT_OPTIONS_SUCCESS',
                'label': 'pTxnId',
                'value': pTxnId
            });
            yield put(setPaymentOptions(paymentOptionsResponse.data.data))
        } else if (paymentOptionsResponse.data != null && paymentOptionsResponse.data.error) {
            let error = paymentOptionsResponse.data.error;
            configureScope((scope) => {
                scope.setTag("pTxnId", pTxnId)
                scope.setTag("paymentOptionsResponse", paymentOptionsResponse)
                captureException("Error in fetchPaymentOptions " + error? error.description : "" )
            });
            if (!(error!= null && (error.code == LOGIN_REQUIRED || error.code == ERROR_NO_ACTION))) {
                window.dataLayer.push({
                    'event': 'eventTracking',
                    'category': 'TRANSACTION',
                    'action': 'GET_PAYMENT_OPTIONS_FAILED',
                    'label': paymentOptionsResponse.data.error.description,
                    'value': 0
                });
                yield put(setError({ ...paymentOptionsResponse.data.error, viewed: false }))
            }
        }
    } catch (e) {
        window.dataLayer.push({
            'event': 'eventTracking',
            'category': 'TRANSACTION',
            'action': 'GET_PAYMENT_OPTIONS_FAILED',
            'label': e.message,
            'value': 0
        });
        captureException("Error in fetchPaymentOptions " + e.message + action.payload )
        yield put(setError({ viewed: false, description: 'Seems there is some problem with your network, please check back in a while.' }))
        yield put(setLoading(false))
    }
}