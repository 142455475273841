import { takeLatest, put, select } from 'redux-saga/effects';
import { setLoading } from '../../../reducers/loading';
import { setAction } from '../../../reducers/genericReducer';
import { setError } from '../../../reducers/error';
import { ERROR_NO_ACTION, GoIbibo, LOGIN_REQUIRED, MakeMyTrip } from '../../../constants';
import { getForexBasePath, handleStageResponse } from '../util';
import { strings } from '../util/string';
import { setOtherInfo } from '../../../reducers/otherInfo';
import { redirectIntercept } from '../util/common';

const skipRedirectionCase = (next_signal_state) => {
    const signal_states = ["payment_being_confirmed","payment_failed","payment_confirmed"];
    if(window.location.pathname.indexOf("bmf/continue-form") > -1 && signal_states.indexOf(next_signal_state) > -1){
        return true
    }
    return false
}

const krysalisConfig = (state) => state.config;
const getOtherInfo = (state) => state.otherInfo;

export default function* watch(actionName, successAction, getResponseExectuor, loader = true, passEntireResponse = false, showError = true) {
    yield takeLatest(`${actionName}`, getWorker(successAction, getResponseExectuor, loader, passEntireResponse, showError))
}

const getLoadingText = (actionName) => {
    switch (actionName) {
        case "SUBMIT_BMF_TRAVELLER_DETAILS":
            return strings.common.verifying_details;
        default:
            return strings.common.loading;
    }
}

const getWorker = (successAction, getResponseExectuor, loader = true, passEntireResponse = false, showError = true) => {
    return function* getData(action) {
        try {
            if (loader) {
                yield put(setLoading({ status: true, message: getLoadingText(action.type) }))
            }
            if (action?.type === "SUBMIT_UPLOAD_PHOTO_HV") {
                const otherInfo = yield select(getOtherInfo);
                yield put(setOtherInfo({...otherInfo, retryCount: otherInfo?.retryCount ? otherInfo?.retryCount + 1 : 1}));
            }
            let response = yield getResponseExectuor(action.payload)
            if (loader)
                yield put(setLoading(false))
            if (response.data.success === true) {
                yield put(setAction(successAction)(passEntireResponse ? response.data : (response.data.data ? response.data.data : response.data)));
                yield put(setAction("WORKFLOW_PROGRESS_DATA")(response.data && response.data.data && response.data.data.workflow_progress || null));
                if (response.data && response.data.data && response.data.data.is_payment_possible !== undefined && !response.data.data.is_payment_possible) {
                    yield put(setError({ description: "Sorry! We can't process your request at this time due to vendor unavailability. Please try again after some time.", viewed: false }));
                } else {
                    if (!action.payload.skipRedirection) {
                        // Added loader to show loader when redirecting to payments page
                        if (response.data && response.data.data && response.data.data.payment_redirection_url) {
                            yield put(setLoading(true))
                        }

                        if(skipRedirectionCase(response.data.next_signal_state)){
                            yield put(setLoading(false))
                        }

                        if(response.data && !skipRedirectionCase(response.data.next_signal_state)){
                            const config = yield select(krysalisConfig);
                            let partner = response?.config?.url?.includes(MakeMyTrip) ? MakeMyTrip : GoIbibo;
                            handleStageResponse(response.data, config.data, partner);
                        }
                    }
                }
            } else {
                if (showError) {
                    if (response.data.error && response.data.error.code && (response.data.error.code === ERROR_NO_ACTION || response.data.error.code === LOGIN_REQUIRED)) {
                        yield put({ type: response.data.error.code, payload: response.data.error.description });
                    } else if (!(response.data.error.code === LOGIN_REQUIRED || response.data.error.code === ERROR_NO_ACTION)) {
                        yield put(setError({ ...response.data.error, viewed: false }));
                        if(action?.type === "GET_DETAILS_FROM_PAN") {
                            yield put(setAction(successAction)(response.data));
                        } 
                    }
                } else {
                    if (action?.type === "SUBMIT_UPLOAD_PHOTO_HV") {
                        const otherInfo = yield select(getOtherInfo);
                        if (otherInfo.retryCount <= 3) {
                            yield put(action);
                        } else {
                            redirectIntercept(`${getForexBasePath()}/onboarding/selfie-error`);
                            yield put(setOtherInfo({...otherInfo, retryCount: null}));
                        }
                    }
                }
            }
        } catch (e) {
            if (loader)
                yield put(setLoading(false))
            if (e.response && e.response.status === 403) {
                yield put(setError({ viewed: false, description: 'You are not authorized to access!' }))
            } else {
                if (showError) {
                    yield put(setError({ viewed: false, description: 'Seems there is some problem with your network, please check back in a while.' }))
                }
                if(action?.type === "GET_DETAILS_FROM_PAN") {
                    yield put(setAction(successAction)({success: false}));
                } 
            }
            console.log(e)
        }
    }
}