import { store } from "../../../components/AppProvider";
import { canNavigateToPostSales, getSpecificCookie, isHvScriptLoaded, isHypervergeFlow, redirectIntercept, redirectInterceptHref } from "./common"
import { BMF_PRODUCT_COOKIE_KEY, PC } from "./constants";

export const getForexBasePath = () => {
    const regex = /(\/partners\/(.*)\/forex)/gm;
    return (regex.exec(window.location.href))[1];
}

export const getPartner = () => {

    const regex = /(\/partners\/)(\w*)(\/)/gm;
    return (regex.exec(window.location.href))[2];

}

export const getOnboardingStages = () => {
    return {
        "initiate_workflow": {
            "apiRoute": "/card-onboarding/api/v1/onboarding/initiate_workflow",
            "uiRoute": `${getForexBasePath()}/onboarding/personal-details`,
        },
        "upload_photo": {
            "apiRoute": "/card-onboarding/api/v1/onboarding/photo/upload",
            "uiRoute": `${getForexBasePath()}/onboarding/photo`
        },
        "upload_photo_hv": {
            "apiRoute": "/card-onboarding/api/v1/onboarding/photo/upload",
            "uiRoute": `${getForexBasePath()}/onboarding/photo-hv`
        },
        "show_card_image": {
            "apiRoute": "/card-onboarding/api/v1/onboarding/show_card_image",
            "uiRoute": `${getForexBasePath()}/onboarding/card-assigned`
        },
        "update_address": {
            "apiRoute": "/card-onboarding/api/v1/onboarding/update_address",
            "uiRoute": `${getForexBasePath()}/onboarding/update-address`
        },
        "thank_you": {
            "apiRoute": "/card-onboarding/api/v1/onboarding/card_under_processing",
            "uiRoute": `${getForexBasePath()}/onboarding/thank-you`
        },
        "kyc_declined": {
            "apiRoute": "/card-onboarding/api/v1/onboarding/kyc_declined",
            "uiRoute": `${getForexBasePath()}/onboarding/kyc-declined`
        },
        "kyc_awaited": {
            "apiRoute": "/card-onboarding/api/v1/onboarding/kyc_pending",
            "uiRoute": `${getForexBasePath()}/onboarding/kyc-awaited`
        },
        "card_under_processing": {
            "apiRoute": "/card-onboarding/api/v1/onboarding/card_under_processing",
            "uiRoute": `${getForexBasePath()}/dashboard?refresh=true`
        },
        "ckyc_initiate_workflow": {
            "apiRoute": "/card-onboarding/api/v1/onboarding/ckyc_initiate_workflow",
            "uiRoute": `${getForexBasePath()}/onboarding/personal-details`,
        },
        "ckyc_upload_photo": {
            "apiRoute": "/card-onboarding/api/v1/onboarding/photo/upload_ckyc",
            "uiRoute": `${getForexBasePath()}/onboarding/photo`
        },
        "ckyc_upload_photo_hv": {
            "apiRoute": "/card-onboarding/api/v1/onboarding/photo/upload_ckyc",
            "uiRoute": `${getForexBasePath()}/onboarding/photo-hv`
        },        
        "ckyc_show_card_image": {
            "apiRoute": "/card-onboarding/api/v1/onboarding/ckyc_show_card_image",
            "uiRoute": `${getForexBasePath()}/onboarding/card-assigned`
        },
        "ckyc_update_address": {
            "apiRoute": "/card-onboarding/api/v1/onboarding/ckyc_update_address",
            "uiRoute": `${getForexBasePath()}/onboarding/ckyc-update-address`
        },
        "ckyc_pan_awaited": {
            "uiRoute": `${getForexBasePath()}/onboarding/kyc/waiting`
        },
        "ckyc_declined": {
            "uiRoute": `${getForexBasePath()}/onboarding/kyc/failed`
        },
        "upload_documents": {
            "apiRoute": "",
            "uiRoute": ""
        },
        "bmf_update_address": {
            "apiRoute": "/card-onboarding/api/v1/onboarding/bmf_update_address",
            "uiRoute": `${getForexBasePath()}/bmf/delivery-details`
        },
        "update_traveller_details": {
            "apiRoute": "/card-onboarding/api/v1/onboarding/update_traveller_details",
            "uiRoute": `${getForexBasePath()}/bmf/traveller-details`
        },
        "bmf_submit_order": {
            "apiRoute": "/card-onboarding/api/v1/onboarding/submit_order",
            "uiRoute": `${getForexBasePath()}/bmf/order-pending`
        },
        "update_trip_purpose": {
            "apiRoute": "/card-onboarding/api/v1/onboarding/update_trip_purpose",
            "uiRoute": `${getForexBasePath()}/bmf/trip-purpose`
        },
        "update_trip_duration": {
            "apiRoute": "/card-onboarding/api/v1/onboarding/update_trip_duration",
            "uiRoute": `${getForexBasePath()}/bmf/trip-duration`
        },
        "update_trip_details": {
            "apiRoute": "/card-onboarding/api/v1/onboarding/update_trip_details",
            "uiRoute": `${getForexBasePath()}/bmf/trip-details`
        },
        "document_consent": {
            "apiRoute": "/card-onboarding/api/v1/onboarding/document_consent",
            "uiRoute": `${getForexBasePath()}/bmf/doc-info`
        },
        "add_products": {
            "uiRoute": `${getForexBasePath()}/bmf/currency-exchange-add-money`
        },
        "confirm_order_details": {
            "apiRoute": "/card-onboarding/api/v1/onboarding/confirm_order_details",
            "uiRoute": `${getForexBasePath()}/bmf/confirm_order_details`,
        },
        "payment_confirmed": {
            "uiRoute": `${getForexBasePath()}/bmf/order-pending`
        },
        "payment_failed": {
            "uiRoute": `${getForexBasePath()}/bmf/order-failed`
        },
        "payment_being_confirmed": {
            "uiRoute": `${getForexBasePath()}/bmf/order/redirect`
        },
        "ckyc_search_completed": {
            "uiRoute": `${getForexBasePath()}/onboarding/kyc/waiting`
        },
        "ckyc_search_initiate_workflow": {
            "uiRoute": `${getForexBasePath()}/onboarding/kyc/waiting`
        },
        "recheck_initialised": {
            "uiRoute": `${getForexBasePath()}/onboarding/rechecking`
        }
    }
}

export const handleStageResponse = (stageResponse, krysalisConfig = {}, partner = '') => {
    const storeState = store.getState();
    const {bmf_reload} = storeState?.dynamicConfigs?.data || {};
    const {userId} = storeState?.user?.data?.userInfo || "";
    console.log("handleStageResponse",bmf_reload,userId,storeState)
    if (stageResponse.next_signal_state !== undefined) {
        if (stageResponse.next_signal_state === "sync_kyc" || stageResponse.next_signal_state === "payment_not_completed") {
            redirectInterceptHref(stageResponse.data.redirect_url);
        } else if (stageResponse.next_signal_state === "start_payment") {
            redirectInterceptHref(stageResponse.data.payment_redirection_url);
        } else if (stageResponse.next_signal_state === "upload_photo") {
            (isHypervergeFlow(krysalisConfig, partner) && isHvScriptLoaded()) ? redirectIntercept(getOnboardingStages()["upload_photo_hv"].uiRoute) : redirectIntercept(getOnboardingStages()["upload_photo"].uiRoute);
        } else if (stageResponse.next_signal_state === "ckyc_upload_photo") {
            (isHypervergeFlow(krysalisConfig, partner) && isHvScriptLoaded()) ? redirectIntercept(getOnboardingStages()["ckyc_upload_photo_hv"].uiRoute) : redirectIntercept(getOnboardingStages()["ckyc_upload_photo"].uiRoute);
        } else if(stageResponse.next_signal_state === "payment_confirmed"){
            const bmfProduct = getSpecificCookie(BMF_PRODUCT_COOKIE_KEY);
            if(bmfProduct === PC && (bmf_reload?.is_enabled || bmf_reload?.whitelisted_users?.includes(userId)) && canNavigateToPostSales(partner)){
                redirectInterceptHref(`/mweb/partners/${partner}/forex/bmf/dashboard`);
            }else{
                redirectIntercept(getOnboardingStages()[stageResponse.next_signal_state].uiRoute)
            }
        }else {
            redirectIntercept(getOnboardingStages()[stageResponse.next_signal_state].uiRoute)
        }
    }
}

export const redirectToDashboard = () => {
    redirectIntercept(getForexBasePath() + "?refresh=true");
}


export const getWithdrawReasonsTexts = (reason) => {
    switch (reason) {
        case "RETURNED_TO_ORIGIN":
        case "RTO":
            return {
                title: "Sorry! Your card has been returned by the delivery partner.",
                info: "Unfortunately, we can not re-initiate the delivery. You can withdraw the existing card balance by clicking below.",
            };
        case "DELIVERY_IN_PROGRESS":
            return {
                title: "Sorry! Your card delivery is taking longer than expected.",
                info: "We regret the inconvenience caused. Please contact the delivery partner for more details.",
            };
        default:
            return {
                title: "Sorry! Your card delivery is taking longer than expected.",
                info: "We regret the inconvenience caused. Please contact the delivery partner for more details.",
            };
    }
};
export const goToLanding = (otherInfo, page = "landing") => {
    if (otherInfo && otherInfo.partner && page) {
        redirectIntercept(`/partner/${otherInfo.partner}/context/${page}`);
    } else {
        window.location.href = "/";
      }
}
