import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const getBackofficeUser = createAction('GET_BACKOFFICE_USERS');
export const setBackofficeUser = createAction('SET_BACKOFFICE_USERS');
export const saveBackofficeUser = createAction('SAVE_BACKOFFICE_USER');

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
      setBackofficeUser,
      (state, action) => {
        return action.payload
      } 
    ]
  ]),
  [] 
);