import { takeLatest, put, select } from 'redux-saga/effects';
import { setCurrentStage, submitStage } from '../reducers/stage'
import * as zero from '../api';
import * as stageUtils from '../utils/stage';
import { setLoading } from '../reducers/loading'
import { setError } from '../reducers/error';
import { getUser } from '../reducers/user';
import { LOGIN_REQUIRED } from '../constants';
import { persistSpecificParamInCookie, redirectIntercept, redirectInterceptHref, sendGAEventForInit } from '../utils/common';


export default function* watchGetUser() {
    yield takeLatest('GET_CURRENT_STAGE', getStageResponse)
}

const getUserData = state => state.user;

function* getStageResponse(action) {
    try {
        const { partner, context, userId, lender, stage, data, loadingMessage, pTxnId } = action.payload;
        if (loadingMessage && loadingMessage != "") {
            yield put(setLoading({ status: true, message: loadingMessage }))
        } else {
            yield put(setLoading(true))
        }
        let stageResponse = yield zero.getCurrentStage(partner, userId, lender, stage, data);
        let user = yield select(getUserData);
        yield put(setLoading(false))
        if (stageResponse.data != null && stageResponse.data.responseStatus === "SUCCESS") {
            sendGAEventForInit(partner, context, user, (stageResponse.data.data.stageDetails.stageName), true);
            let stageData = stageResponse.data;
            if (stageUtils.checkIfRedirectIsRequired(stageData)) {
                redirectIntercept(`/partners/${partner}/transaction/${pTxnId}`)
                return;
            }
            if (stageUtils.shouldPoleStage(stageResponse)) {
                yield put(setCurrentStage(stageResponse.data));
                yield stageUtils.retry(partner, context, userId, lender, stageResponse, { retryCount: (data && data.retryCount !== undefined ? data.retryCount : 0) });
                return;
            }

            if (stageUtils.shouldRefreshUser(stageData.data.stageDetails.stageName)) {
                yield put(getUser({ partner, context }))
            }
            //Check if stage is auto submittable then submit it
            if (stageUtils.isAutoSubmitStage(stageResponse)) {
                yield put(submitStage({ partner, context, userId, lender, stageId: stageResponse.data.data.stageDetails.stageId, data: {}, retryCount: 0 }))
            }
            if (stageData.data.stageDetails.xdata && stageData.data.stageDetails.xdata.error) {
                yield put(setError({ viewed: false, description: stageResponse.data.data.stageDetails.xdata.error }))
            }
            //If not auto submit stage then set current stage and redirect
            if (!stageUtils.isAutoSubmitStage(stageResponse)) {
                //If redirect url is present then redirect page
                if (stageData.data.stageDetails && stageData.data.stageDetails.xdata && stageData.data.stageDetails.xdata.redirectUrl !== undefined
                    && stageData.data.stageDetails.xdata.redirectUrl !== "") {
                    if (window.dataLayer) {
                        window.dataLayer.push({
                            'event': 'eventTracking',
                            'category': 'GET_CURRENT_STAGE',
                            'action': 'REDIRECTION',
                            'label': user && user.data ? (user.data.userInfo && (user.data.userInfo.userId + "::" + user.data.userInfo.mobile + "::" + user.data.userInfo.trackingId + "::")) + (user.data.loanInfo ? user.data.loanInfo.lender : "") : "COULD_NOT_GET_DATA",
                            'value': 0
                        })
                    }
                    if(stageData.data.stageDetails.xdata.utmFlow){
                        persistSpecificParamInCookie("utm_flow", stageData.data.stageDetails.xdata.utmFlow);
                    }else{
                        persistSpecificParamInCookie("utm_flow", "NO_DATA");
                    }
                    redirectInterceptHref(stageData.data.stageDetails.xdata.redirectUrl);
                    return;
                }

                yield put(setCurrentStage(stageResponse.data));
                redirectIntercept(stageUtils.mapStageToRoute(stageData.data.stageDetails.stageName, partner, context, pTxnId))
            }
        } else {
            let error = stageResponse.data.error;
            sendGAEventForInit(partner, context, user, JSON.stringify(error), true);
        }
    } catch (e) {
        redirectIntercept("/error")
        yield put(setLoading(false))
        console.log(e)
    }
}