import { takeLatest, put } from 'redux-saga/effects';
import * as zero from '../api';
import { setLoading } from '../reducers/loading';
import { setError } from '../reducers/error';
import {redirectIntercept} from '../utils/common';

import { saveOtpDetailsCreditCard } from '../reducers/otpCreditCard';

export default function* watchOtpData() {
    yield takeLatest('GET_NEW_ACCESS_TOKEN', getNewTokens)
}

function* getNewTokens(action) {
    const { refreshToken, userId } = action.payload;

    try {
        yield put(setLoading(true))
        let response = yield zero.getNewAccessToken({ refreshToken });
        yield put(setLoading(false))
        if (response && response.data && response.data.responseStatus == "SUCCESS") {
            yield put(saveOtpDetailsCreditCard({ tokenDetailsCreditCard: { ...response.data.data, refreshToken, userId } }))
        } else if (response && response.data && response.data.error && response.data.error.code === 'AUTHENTICATION_ERROR') {
            yield put(saveOtpDetailsCreditCard({ tokenDetailsCreditCard: {} }))
            yield put(setError({ viewed: false, description: 'You are not authorized to access!' }))
            redirectIntercept("/credit");
        }

    } catch (e) {
        yield put(setLoading(false))
        console.log(e)
    }
}