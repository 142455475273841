import { takeLatest, put } from 'redux-saga/effects';
import { setLoading } from '../../../reducers/loading';
import { setAction } from '../../../reducers/genericReducer';
import { setError } from '../../../reducers/error';
import { ERROR_NO_ACTION, LOGIN_REQUIRED } from '../../../constants';
import { getOnboardingStages, handleStageResponse } from '../util';
import { redirectIntercept, redirectInterceptHref } from '../../../utils/common';

export default function* watch(actionName, successAction, loader = true, passEntireResponse = false, showError = true) {
    yield takeLatest(`${actionName}`, getWorker(successAction, loader, passEntireResponse, showError))
}

const getWorker = (successAction, loader = true, passEntireResponse = false, showError = true) => {
    return function* getData(action) {
        yield put(setAction(successAction)(null));
        
    }
}