import { takeLatest, put } from 'redux-saga/effects';
import * as zero from '../api';
import { setLoading } from '../reducers/loading';
import { history } from "../containers/Router";
import { setError } from '../reducers/error';
import { getOtherCompleteRoute } from '../utils/stage';
import { ERROR_NO_ACTION, LOGIN_REQUIRED } from '../constants';
import {redirectIntercept} from '../utils/common';

export default function* watchValidateOTP() {
    yield takeLatest('LINK_USER', linkUser)
}

function* linkUser(action) {
    try {
        yield put(setLoading(true))
        const { partner, context, deeplink, deeplinkParamOrderId } = action.payload;
        let linkUserResponse = yield zero.linkUser(partner);
        yield put(setLoading(false))
        if (linkUserResponse.data && linkUserResponse.data.responseStatus == "SUCCESS") {
            redirectIntercept(getOtherCompleteRoute("refresh", partner, context, null ,null, null, deeplink, deeplinkParamOrderId));
        } else {
            let error = linkUserResponse.data.error;
            if(!(error!= null && (error.code == LOGIN_REQUIRED || error.code == ERROR_NO_ACTION))){
                window.dataLayer.push({
                    'event': 'eventTracking',
                    'category': 'LINK_USER',
                    'action': 'LINK_USER_FAILED',
                    'label': linkUserResponse.data.error.description,
                });
                yield put(setError({...linkUserResponse.data.error, viewed: false}))
            }            
        }
    } catch(e) {
        window.dataLayer.push({
            'event': 'eventTracking',
            'category': 'LINK_USER',
            'action': 'LINK_USER_FAILED',
            'label': e.message,
        });
        yield put(setLoading(false))
        redirectIntercept("/error")
        console.log(e)
    }
}