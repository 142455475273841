import { ANDROID, IOS } from "../constants";
import { getUserDeviceOS } from "../utils/common";


const closeWebViewFunc = (flag) => {
    try {
        const os = getUserDeviceOS()
        if (window.app_bridge) {
            if (os === ANDROID) {
                /* eslint-disable no-unused-expressions */
                window.app_bridge?.closeAndRefreshWebView();
            } else if (os === IOS) {
                /* eslint-disable no-unused-expressions */
                window.webkit.messageHandlers.closeAndRefreshWebView.postMessage(  JSON.stringify(true));
            }
            return;
    
        } else if (window.mmt_android_bridge) {
            if (os === ANDROID)
           {
            /* eslint-disable no-unused-expressions */
            window.mmt_android_bridge?.closeWebView(false);
           }
           else if (os === IOS)
           {
            /* eslint-disable no-unused-expressions */
            window?.webkit?.messageHandlers?.closeAndRefresh?.postMessage(JSON.stringify(true))
           }
            return; 
        }
        else if (flag === true)
        {
            window.history.go(-(window.history.length - 1));
            return;
        }
        return;
    } catch (e) { 
        console.error("error while executing close application: " + e.message)
    }
}

const Close = props => {

    const closeWebview = () => {
        try {
            const os = getUserDeviceOS()
            if (os === ANDROID && window.mmt_android_bridge){
                window.mmt_android_bridge.closeAndRefreshWebView();
            }else if (os === IOS){
                window.webkit.messageHandlers.closeAndRefresh.postMessage(JSON.stringify(true))
            }else{
                window.history.go(-(window.history.length - 1))
            }
        } catch (e) { 
            console.error("error while executing close application: " + e.message)
        }
    }
    closeWebview();
    return "";
}

export default closeWebViewFunc;


