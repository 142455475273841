export default {
    personalDetails: require('../../../reducers/genericReducer').getReducer("PERSONAL_DETAILS", null, true),
    updateAddress: require('../../../reducers/genericReducer').getReducer("UPDATE_ADDRESS", null, true),
    cardAssigned: require('../../../reducers/genericReducer').getReducer("CARD_ASSIGNED", null, true),
    getHypervergeToken: require('../../../reducers/genericReducer').getReducer("GET_HYPERVERGE_TOKEN", null, true),
    uploadPhotoHv: require('../../../reducers/genericReducer').getReducer("UPLOAD_PHOTO_HV", null, true),

    //for dashboard
    blockCard: require('../../../reducers/genericReducer').getReducer("BLOCK_CARD", null, true),
    unblockCard: require('../../../reducers/genericReducer').getReducer("UNBLOCK_CARD", null, true),
    trackCardStatus: require('../../../reducers/genericReducer').getReducer("TRACK_CARD_STATUS", null, true),
    getAllDisputes: require('../../../reducers/genericReducer').getReducer("GET_ALL_DISPUTES", null, true),
    getDisputeReasons: require('../../../reducers/genericReducer').getReducer("GET_DISPUTE_REASONS", null, true),
    getDisputeById: require('../../../reducers/genericReducer').getReducer("GET_DISPUTE_BY_ID", null, true),
    postDispute: require('../../../reducers/genericReducer').getReducer("POST_DISPUTE", null, true),
    activateCard: require('../../../reducers/genericReducer').getReducer("ACTIVATE_CARD", null, true),
    getTransactionLimit: require('../../../reducers/genericReducer').getReducer("GET_TRANSACTION_LIMITS", null, true),
    getTransactionMode: require('../../../reducers/genericReducer').getReducer("GET_TRANSACTION_MODES", null, true),
    setTransactionLimit: require('../../../reducers/genericReducer').getReducer("SET_TRANSACTION_LIMITS", null, true),
    setTransactionMode: require('../../../reducers/genericReducer').getReducer("SET_TRANSACTION_MODES", null, true),
    currentUserStatus: require('../../../reducers/genericReducer').getReducer("CURRENT_USER_STATUS", null, true),
    pinSet: require('../../../reducers/genericReducer').getReducer("PIN_SET", null, true),
    pinSetVerifyOTP: require('../../../reducers/genericReducer').getReducer("PIN_SET_VERIFY_OTP", null, true),
    pinSetSendOTP: require('../../../reducers/genericReducer').getReducer("PIN_SET_SEND_OTP", null, true),
    cardDetails: require('../../../reducers/genericReducer').getReducer("CARD_DETAILS", null, true),
    withdrawlEligiblity:require('../../../reducers/genericReducer').getReducer("WITHDRAWL_ELIGIBLITY",null,true),
    withdrawDetails: require('../../../reducers/genericReducer').getReducer("WITHDRAW", null, true),
    withdrawLimit: require('../../../reducers/genericReducer').getReducer('WITHDRAW_LIMIT', null, true),
    pincode: require('../../../reducers/genericReducer').getReducer("PINCODE", null, true),
    loadMoney: require('../../../reducers/genericReducer').getReducer("LOAD_MONEY", null, true),
    transactions: require('../../../reducers/genericReducer').getReducer("TRANSACTIONS", null, true),
    //ckyc flow
    ckycPersonalDetails: require('../../../reducers/genericReducer').getReducer("CKYC_PERSONAL_DETAILS", null, true),
    ckycUpdateAddress: require('../../../reducers/genericReducer').getReducer("CKYC_UPDATE_ADDRESS", null, true),
    ckycCardAssigned: require('../../../reducers/genericReducer').getReducer("CKYC_CARD_ASSIGNED", null, true),
    ckycUploadPhotoRed: require('../../../reducers/genericReducer').getReducer("CKYC_UPLOAD_PHOTO_REDUCER", null, true),
    ckycUploadPhoto: require('../../../reducers/genericReducer').getReducer("CKYC_UPLOAD_PHOTO", null, true),
    ckycTimerBreached: require('../../../reducers/genericReducer').getReducer("CKYC_TIMER_BREACHED", null, true),


    // load money
    getLoadMoneyTimestamp: require('../../../reducers/genericReducer').getReducer("GET_LOAD_MONEY_TIMESTAMP", null, true),

    //bmf
    updateTripDuration: require('../../../reducers/genericReducer').getReducer("UPDATE_TRIP_DURATION", null, true),
    tripPurpose: require('../../../reducers/genericReducer').getReducer("TRIP_PURPOSE", null, true),
    updateTripPurpose: require('../../../reducers/genericReducer').getReducer("UPDATE_TRIP_PURPOSE", null, true),
    //for bmf
    initiateBMFWorkflow: require('../../../reducers/genericReducer').getReducer("INITIATE_BMF_WORKFLOW", null, true),
    BMFData: require('../../../reducers/genericReducer').getReducer("BMF_DATA", null, true),
    getBMFCities: require('../../../reducers/genericReducer').getReducer("GET_BMF_CITIES", null, true),
    addBMFProducts: require('../../../reducers/genericReducer').getReducer("ADD_BMF_PRODUCTS", null, true),
    bmfTravellerDetails: require('../../../reducers/genericReducer').getReducer("BMF_TRAVELLER_DETAILS", null, true),
    bmfTravellerDetailsSendOTP: require('../../../reducers/genericReducer').getReducer("BMF_TRAVELLER_DETAILS_SEND_OTP", null, true),
    bmfOrder: require('../../../reducers/genericReducer').getReducer("BMF_ORDER", null, true),
    bmfDocuments: require('../../../reducers/genericReducer').getReducer("BMF_DOCUMENTS", null, true),
    bmfOrderDetails: require('../../../reducers/genericReducer').getReducer("BMF_ORDER", null, true),
    getBMFCountries: require('../../../reducers/genericReducer').getReducer("GET_BMF_COUNTRIES", null, true),
    updateTripDetails: require('../../../reducers/genericReducer').getReducer("UPDATE_TRIP_DETAILS", null, true),
    previousStageData: require('../../../reducers/genericReducer').getReducer("PREVIOUS_STAGE_DATA", null, true),
    bmfOrderDetails: require('../../../reducers/genericReducer').getReducer("BMF_ORDER_DETAILS", null, true),
    getBMFCurrencies: require('../../../reducers/genericReducer').getReducer("GET_BMF_CURRENCIES", null, true),
    getBMFExchangeRate: require('../../../reducers/genericReducer').getReducer("GET_BMF_EXCHANGE_RATE", null, true),
    confirmOrderDetails: require('../../../reducers/genericReducer').getAction("CONFIRM_ORDER_DETAILS",null, true),
    getBMFWorkflowStage: require('../../../reducers/genericReducer').getReducer("GET_BMF_WORKFLOW_STAGE", null, true),
    getBMFWorkflowProgress: require('../../../reducers/genericReducer').getReducer("GET_BMF_WORKFLOW_PROGRESS", null, true),
    updateTripConcent: require('../../../reducers/genericReducer').getReducer("UPDATE_TRIP_CONSENT", null, true),
    getPaymentStatus:require('../../../reducers/genericReducer').getReducer("GET_PAYMENT_STATUS", null, true),
    WorkflowProgressData: require('../../../reducers/genericReducer').getReducer("WORKFLOW_PROGRESS_DATA", null, true),
    getBMFRequiredDocuments: require('../../../reducers/genericReducer').getReducer("GET_BMF_REQUIRED_DOCUMENTS", null, true),
    deleteUploadedDoc: require('../../../reducers/genericReducer').getReducer("DELETE_UPLOADED_DOC", null, true),
    getOrderDocuments: require('../../../reducers/genericReducer').getReducer("GET_ORDER_DOCUMENTS", null, true),
    getPrograms: require('../../../reducers/genericReducer').getReducer("GET_PROGRAMS", null, true),
    getSpeceficOrderDocuments: require('../../../reducers/genericReducer').getReducer("GET_SPECEFIC_ORDER_DOCUMENTS", null, true),
    getCurrencyRates: require('../../../reducers/genericReducer').getReducer("GET_CURRENCY_RATES", null, true),
    getPopularCurrencyRates : require('../../../reducers/genericReducer').getReducer("GET_POPULAR_CURRENCY_RATES", null, true),
    getRecheckKycStatus: require('../../../reducers/genericReducer').getReducer("SUBMIT_RECHECK_STATUS", null, true),    getDetailsFromPan: require('../../../reducers/genericReducer').getReducer("DETAILS_FROM_PAN", null, true),
    rateWhatsappNotify:require('../../../reducers/genericReducer').getReducer("RATE_WHATSAPP_NOTIFY", null, true),
    getPaymentLink: require('../../../reducers/genericReducer').getReducer("PAYMENT_CHECKOUT_LINK", null, true),
    getRequestPaymentCheckoutLink: require('../../../reducers/genericReducer').getReducer("REQUEST_PAYMENT_CHECKOUT", null, true),
    
    //Feedback component
    getRateAndReviews: require("../../../reducers/genericReducer").getReducer("GET_RATE_AND_REVIEWS", null, true),
    updateRatings: require("../../../reducers/genericReducer").getReducer("SUBMIT_RATINGS",null, true),
    forexUserDetails:require("../../../reducers/genericReducer").getReducer("FOREX_USER_DETAILS",null, true),
    bmfOrderStatus:require("../../../reducers/genericReducer").getReducer("BMF_ORDER_STATUS",null, true), 
    dynamicConfigs:require("../../../reducers/genericReducer").getReducer("GET_DYNAMIC_CONFIGS",null, true),
    getValidCardHolder:require("../../../reducers/genericReducer").getReducer("GET_VALID_CARD_HOLDER",null, true),
    getTravellerDetails: require("../../../reducers/genericReducer").getReducer("GET_TRAVELLER_DETAILS", null, true),
    pokusEnabled:require("../../../reducers/genericReducer").getReducer("GET_POKUS_ENABLED",null, true),
};
