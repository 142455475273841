import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const syncUser = createAction('SYNC_USER');
export const setSyncUser = createAction('SET_SYNC_USER');

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
        setSyncUser,
      (state, action) => {
        return action.payload
      } 
    ]
  ]),
  { data: null, error: null}
);

// SELECTORS
export const getSyncState = state => state.sync;
 
