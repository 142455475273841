import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const getRuleEngines = createAction('GET_RULE_ENGINES');
export const setRuleEngines = createAction('SET_RULE_ENGINES');
export const publishRuleMachine = createAction('PUBLISH_RULE_MACHINE');

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
        setRuleEngines,
      (state, action) => {
        return action.payload
      } 
    ]
  ]),
  []
);

// SELECTORS
export const getConfigState = state => state.ruleEngines;
 
