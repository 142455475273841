import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const getRuleEngine = createAction('GET_RULE_ENGINE');
export const setRuleEngine = createAction('SET_RULE_ENGINE');
export const saveRuleEngine = createAction('SAVE_RULE_ENGINE')
export const testRule = createAction('TEST_RULE');;
export const setRuleTestResult = createAction('SET_RULE_TEST_RESULT');;

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
        setRuleEngine,
      (state, action) => {
        return action.payload
      } 
    ]
  ]),
  {}
);

// SELECTORS
export const getConfigState = state => state.ruleEngines;
 
