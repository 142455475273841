import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'
import forexReducers from '../containers/Forex/reducer'

export default combineReducers({
	travelLoan : require('./travelLoanReducer').reducer,
	lenderStage : require('./lendingCurrentStage').reducer,
	panAndAddress: require('./setPanAndAddress').reducer,
	videoKycRooms: require('./videoKycRooms').reducer,
	agentLogin: require('./setAgentLogin').reducer,
	loaners: require('./loaners').reducer,
	user: require('./user').reducer,
	loading: require('./loading').reducer,
	si: require('./si').reducer,
	config: require('./config').reducer,
	otu: require('./otu').reducer,
	stage: require('./stage').reducer,
	form: formReducer.plugin({
		bankDetails: (state, action) => { // <------ 'account' is name of form given to reduxForm()
			switch (action.type) {
				case 'BANK_SELECTED':
					return undefined;       // <--- blow away form data
				default:
					return state;
			}
		}
	}),
	otp: require('./otp').reducer,
	bankDetails: require('./bankDetails').reducer,
	error: require('./error').reducer,
	rateError:require("./rateError").reducer,
	otherInfo: require('./otherInfo').reducer,
	login: require('./login').reducer,
	search: require('./searchUsers').reducer,
	sync: require('./sync').reducer,
	pms: require('./pms').reducer,
	ledgerDetails: require('./ledger').reducer,
	ledgerFaq: require('./ledgerFaq').reducer,
	ledgerLoanDetails: require('./ledgerLoanDetails').reducer,
	ledgerFeedback: require('./ledgerFeedback').reducer,
	ruleEngines: require('./rules').reducer,
	ruleEngine: require('./rule').reducer,
	lendersConfigs: require('./lendersConfig').reducer,
	context: require('./context').reducer,
	paymentOptions: require('./paymentOptions').reducer,
	redirectUrl: require('./redirectUrl').reducer,
	urlParams: require('./urlParams').reducer,
	backofficeUsers: require('./backofficeUsers').reducer,
	partnersConfig: require('./partnersConfig').reducer,
	users: require('./users').reducer,
	testRuleResult: require('./testRuleResult').reducer,
	errorEvents: require('./errorEvents').reducer,
	ruleMeta: require('./ruleMeta').reducer,
	zeroUsers: require('./genericReducer').getReducer("ZERO_USERS"),
	partnerUsers: require('./partnerUsers').reducer,
	loanDetails: require('./loanDetails').reducer,
	onboardingDetails: require('./genericReducer').getReducer("ONBOARDING_DETAILS"),
	onboardingStageDetails: require('./genericReducer').getReducer("ONBOARDING_STAGING_DETAILS"),
	oldTransactions: require('./genericReducer').getReducer("OLD_TRANSACTIONS"),
	s3contents: require('./genericReducer').getReducer("S3_CONTENTS"),
	transactionAudit: require('./genericReducer').getReducer("TRANSACTION_AUDIT"),
	loanAudit: require('./genericReducer').getReducer("LOAN_AUDIT"),
	loanCallbacks: require('./genericReducer').getReducer("LOAN_CALLBACKS"),
	stageInfo: require('./stageInfo').reducer,
	dashboards: require('./genericReducer').getReducer("DASHBOARDS"),
	currentDashboard: require('./genericReducer').getReducer("CURRENT_DASHBOARD"),
	payNowData: require('./payNowUrl').reducer,
	fieldDefs: require('./genericReducer').getReducer("FIELD_DEFS"),
	transactions: require('./genericReducer').getReducer("TRANSACTIONS"),
	close: require('./close').reducer,
	sendStatement: require('./genericReducer').getReducer("SEND_STATEMENT"),
	validatePAN: require('./genericReducer').getReducer("VALIDATE_PAN", null, true),
	segments: require('./genericReducer').getReducer("SEGMENT"),
	downloadApp: require('./genericReducer').getReducer("SEND_DOWNLOAD_APP_SMS"),
	insuranceProducts: require('./genericReducer').getReducer("GET_INSURANCE_PRODUCTS"),
	checkout: require('./genericReducer').getReducer("CHECKOUT"),
	orders: require('./genericReducer').getReducer("GET_ORDERS", null, true),
	productSelected: require('./genericReducer').getReducer("PRODUCT_SELECTED", null),
	sendPolicyMail: require('./genericReducer').getReducer("SEND_POLICY_EMAIL", null, true),
	sendPolicyMailNonLoggedIn: require('./genericReducer').getReducer("SEND_POLICY_EMAIL_NON_LOGGED_IN", null, true),
	sendPolicyEMailByAdmin: require('./genericReducer').getReducer("SEND_POLICY_EMAIL_BY_ADMIN"),
	generateDocumentByAdmin: require('./genericReducer').getReducer("GENERATE_DOCUMENT_BY_ADMIN"),
	clearProductCacheByAdmin: require('./genericReducer').updateAction("UPDATE_PRODUCT_CACHE"),
	clearPricePlanCacheByAdmin: require('./genericReducer').updateAction("UPDATE_PRICE_PLAN_CACHE"),
	faqs: require('./genericReducer').getReducer("GET_FAQ", null, true),
	faqsPremium: require('./genericReducer').getReducer("GET_FAQ_PREMIUM", null, true),
	charges: require('./genericReducer').getReducer("GET_CHARGES_AND_FEES", null, true),
	transactionOptions: require('./genericReducer').getReducer("TRANSACTION_OPTIONS", {}),
	plOtp: require('./genericReducer').getReducer("SEND_PL_OTP", {}),
	sendAppLinkToMobile: require('./genericReducer').getReducer("SEND_APP_LINK_TO_MOBILE", null, true),
	productCards: require('./genericReducer').getReducer("GET_PRODUCT_CARDS", null, true),
	insuranceUserData: require('./genericReducer').getReducer("GET_INSURANCE_USER_DATA", null, true),
	sections: require('./genericReducer').getReducer("LANDING_PAGE_SECTIONS", null, true),

	insuranceAddOns: require('./genericReducer').getReducer("GET_INSURANCE_PS_ADD_ONS"),
	insuranceCheckoutDetails: require('./genericReducer').getReducer("GET_CHECKOUT_DETAILS"),
	insuranceThankYouDetails: require('./genericReducer').getReducer("GET_THANK_YOU_PAGE_DETAILS"),
	tmUser: require('./genericReducer').getReducer("GET_TM_USER",null, true),

	//for standalone insurance
	standaloneInsuranceProducts: require('./genericReducer').getReducer("GET_STANDALONE_INSURANCE_PRODUCTS", null, true),
	checkoutStandaloneInsurance: require('./genericReducer').getReducer("CHECKOUT_INSURANCE_PRODUCTS", null, true),
	searchHistory: require('./genericReducer').getReducer("GET_SEARCH_HISTORY", null, true),
	insuranceBookingDetails: require('./genericReducer').getReducer("GET_INSURANCE_BOOKING_DETAILS", null, true),

	insuranceDetails: require('./genericReducer').getReducer("GET_INSURANCE_DETAILS", null, true),
	tokenDetailsCreditCard: require('./otpCreditCard').reducer,
	cms: require('./cms').reducer,
	resendOTPForZest: require('./genericReducer').getReducer("RESEND_OTP_ZEST", null, true),
	pdtLogging: require('./genericReducer').getReducer("PDT_LOGGING", null, true),

	//for insurance
	registerClaim: require('./genericReducer').getReducer("REGISTER_CLAIM", null, true),
	getClaimStatus: require('./genericReducer').getReducer("GET_CLAIM_STATUS", null, true),
	verifyOTPInsurance: require('./genericReducer').getReducer("VERIFY_OTP_INSURANCE"),
	editPolicyDetails: require('./genericReducer').getReducer("EDIT_POLICY_DETAILS", null, true),

	intermediateEligibility: require('./genericReducer').getReducer("GET_INTERMEDIATE_ELIGIBILITY", null, true),
	//for admin find common customers
	findCommonCustomers: require('./genericReducer').getReducer("FIND_COMMON_CUSTOMERS"),
	travelData: require('./genericReducer').getReducer("TRAVEL_DATA", null, true),
	ruleFolders: require('./genericReducer').getReducer("RULE_FOLDERS", null, true),
	currentFolderContents: require('./genericReducer').getReducer("FOLDER_CONTENT", null, true),
	moveFileOrFolder: require('./genericReducer').getReducer("MOVE_FILE_FOLDER", null, true),
	createFolder: require('./genericReducer').getReducer("CREATE_FOLDER", null, true),
	updateFolder: require('./genericReducer').getReducer("UPDATE_FOLDER", null, true),

	...forexReducers
});