import { takeLatest, put, select } from 'redux-saga/effects';
import { history } from "../containers/Router";
import { setOtherInfo } from '../reducers/otherInfo';
import { redirectIntercept } from '../utils/common';

export default function* watchDisplayError() {
    yield takeLatest('DISPLAY_ERROR_PAGE', handleInternalError)
}

const getOtherInfo = state => state.otherInfo;

function* handleInternalError(action) {
    try {
        let otherInfo = yield select(getOtherInfo);
        yield put(
            setOtherInfo({
              displayErrorPageData: action.payload && action.payload.description,
              displayErrorPageTitle: action.payload && action.payload.title,
              displayErrorIcon: action.payload && action.payload.icon,
              displayErrorButtonText: action.payload && action.payload.ctaTitle,
              displayErrorPageRetry: action.retry,
            })
        );
        redirectIntercept(`/error-page?x-request-id=${otherInfo && otherInfo.requestData ? otherInfo.requestData.xRequestId : ""}`)
    } catch (e) {
        console.log(e)
    }
}