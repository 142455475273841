import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const getLendersConfig = createAction('GET_LENDERS_CONFIG');
export const setLendersConfig = createAction('SET_LENDERS_CONFIG');
export const saveLendersConfig = createAction('SAVE_LENDERS_CONFIG');

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
      setLendersConfig,
      (state, action) => {
        return action.payload
      } 
    ]
  ]),
  [] 
);

// SELECTORS
export const getLendersConfigs = state => state.lendersConfig;
 
