import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const setLoaners = createAction('SET_LOANERS');
export const searchLoaners = createAction('SEARCH_LOANERS');

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
        setLoaners,
      (state, action) => {
        return action.payload
      } 
    ]
  ]),
  []
);

// SELECTORS
export const getLoaners = state => state;
 
