import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const getLedgerInfo = createAction('GET_LEDGER_INFO');
export const setLedgerInfo = createAction('SET_LEDGER_INFO');

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
        setLedgerInfo,
      (state, action) => {
        return action.payload
      } 
    ]
  ]),
  {}
);

// SELECTORS
export const getLedger = state => state.ledgerDetails;
 
