import { takeLatest, put } from 'redux-saga/effects';
import * as zero from '../api';
import { otuValidated } from '../reducers/otu';
import { setLoading } from '../reducers/loading';
import { history } from "../containers/Router";
import { getUser } from '../reducers/user';
import { createAction } from 'redux-actions';
import { ERROR_NO_ACTION, LOGIN_REQUIRED } from '../constants';
import {redirectIntercept} from '../utils/common';

export const getLoginStatusOfUser = createAction('LOGIN_STATUS');

export default function* watchGetLoginStatus() {
    yield takeLatest('LOGIN_STATUS', getLoginStatus)
}

function* getLoginStatus(action) {
    let loginStatusResponse;

    try {
        console.log(action);
        yield put(setLoading(true))
        const partner = "tm";
        const context = action.payload ? action.payload.context : "";
        loginStatusResponse = yield zero.getUserLoginStatus()
        yield put(setLoading(false))
        if (loginStatusResponse.data.responseStatus == "SUCCESS") {
            if (window.dataLayer) {
                window.dataLayer.push({ userTrackingId: loginStatusResponse.data.data.trackingId })
            }
            yield put(otuValidated({ ...loginStatusResponse.data }));
            if (loginStatusResponse.data.data && !loginStatusResponse.data.data.loginRequired)
                yield put(getUser({ partner, context }));

        } else {
            yield put(setLoading(false))
            window.dataLayer.push({
                'event': 'eventTracking',
                'category': 'LOGIN_STATUS',
                'action': 'GET_LOGIN_FAILED',
                'label': loginStatusResponse.data.error,
            });
            redirectIntercept("/error")
        }

    } catch (e) {
        yield put(setLoading(false))
        window.dataLayer.push({
            'event': 'eventTracking',
            'category': 'LOGIN_STATUS',
            'action': 'GET_LOGIN_FAILED',
            'label': e.message,
        });
        yield put(setLoading(false))
        redirectIntercept("/error")
        console.log(e)
    }
}