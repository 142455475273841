import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const getPaymentOptions = createAction('GET_PAYMENT_OPTIONS');
export const setPaymentOptions = createAction('SET_PAYMENT_OPTIONS');
export const initPayment = createAction('INIT_PAYMENT');
export const finalisePayment = createAction('FINALISE_PAYMENT');


// REDUCERS
export const reducer = handleActions(
  new Map([
    [
        setPaymentOptions,
      (state, action) => {
        return action.payload
      } 
    ]
  ]),
  { data: null, error: null}
);