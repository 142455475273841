import { takeLatest, put } from 'redux-saga/effects';
import { otpSent } from '../reducers/otp'
import * as zero from '../api';
import { setLoading } from '../reducers/loading'
import { setError } from '../reducers/error';
import { otpValidated } from '../reducers/otp'
import { history } from "../containers/Router";
import { getOtherCompleteRoute } from '../utils/stage';
import {redirectIntercept} from '../utils/common';

export default function* watchGetUser() {
    yield takeLatest('SEND_OTP', sendOTP)
}

function* sendOTP(action) {
    try {
        yield put(otpSent(null))
        yield put(setLoading(true))
        const { partner, context, mobile } = action.payload;
        
        window.dataLayer.push({
            'event': 'eventTracking',
            'category': 'OTP',
            'action': 'SENDING_OTP',
            'label': mobile,
            'value': 0
        });
        let otpResponse = yield zero.sendOTP(partner, mobile)
        yield put(setLoading(false))
        if (otpResponse.data != null && otpResponse.data.responseStatus == "SUCCESS") {
            window.dataLayer.push({
                'event': 'eventTracking',
                'category': 'OTP',
                'action': 'OTP_SENT',
                'label': mobile,
                'value': 0
            });
            yield put(otpSent(true));
        } else {
            if (otpResponse.data != null && otpResponse.data.error.code == "ERR_LOGGED_IN") {
                yield put(otpValidated(true));
                redirectIntercept(getOtherCompleteRoute("refresh", partner, context))
            } else {
                window.dataLayer.push({
                    'event': 'eventTracking',
                    'category': 'OTP',
                    'action': 'SEND_OTP_FAILED',
                    'label': otpResponse.data.error.description,
                    'value': 0
                });
                yield put(setError({...otpResponse.data.error, viewed: false}))
            }
        }
    } catch(e) {
        window.dataLayer.push({
            'event': 'eventTracking',
            'category': 'OTP',
            'action': 'SEND_OTP_FAILED',
            'label': e.message,
            'value': 0
        });
        yield put(setError({viewed: false, description: 'Seems there is some problem with your network, please check back in a while.'}))
        yield put(setLoading(false))
        console.log(e)
    }
}