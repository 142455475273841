import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const getLoanDetails = createAction('GET_LOAN_DETAILS');
export const setLoanDetails = createAction('SET_LOAN_DETAILS');

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
        setLoanDetails,
      (state, action) => {
        return action.payload
      } 
    ]
  ]),
  {rows: [], total: 0} 
);

// SELECTORS
 
