import React from 'react';

export const strings = {
    common: {
        start_for_card: "Let's order bookmyforex card for your upcoming trip",
        start_for_currency: "Let's order currency notes for your trip",
        currency_exchange_banner_text: <>Get Forex <br /> Currency Notes <br /> at your doorstep</>,
        card_exchange_banner_text: <>Order <br /> BookMyForex Card <br /> in 5 mins</>,
        currency: "Currency",
        card: "Card",
        currency_progressbar_message: "Forex rates are dynamic and may change while you complete the order. Please verify the total amount on payments page.",
        protip_currency_message: "Get up to Rs. 5000 cashback on forex currency orders above Rs. 50,000 till 31st July!",
        protip_card_message: "Get up to Rs. 5000 cashback on forex cards orders above Rs. 50,000 till 31st July!",
        protip_card_message2: "Get up to Rs. 5000 cashback on forex cards orders above Rs. 50,000 till 31st July!",
        progressbar_trip_details: "Select all the countries you will be visting on this trip.",
        progressbar_trip_duration: "Please provide travel dates.",
        progressbar_trip_purpose: "As per regulations, below details are mandatory to process your order.",
        progressbar_delivery_details: "Please provide your address for delivery.",
        confirm_payment_status: "Confirming Payment Status",
        payment_status_subtext: "Confirming your payment status with the partner.",
        payment_status_timeout_subtext: "This may take up 30 mins.",
        take_a_nap: "Take a nap!!",
        payment_status_still_heading: "Still Checking Payment Status",
        payment_status_30min_heading: "This may take up 30 mins.",
        payment_status_timeout5sec_subtext: "It may take a couple of minutes to confirm your payment.",
        payment_status_whatsapp_text: "You will receive confirmation on WhatsApp once payment is successful.",
        card_delivery_text: "Card will be delivered within 7-10 days",
        not_your_number_personal_Details: "Not your number? Please login to the app again with correct mobile number. OTP verification & KYC check will be done on the number displayed above.",
        dob_text_personal_details: "DOB needs to match the date mentioned on PAN card",
        order_review: "Order Review",
        search_by_currency_name: "Search currency by name",
        load_more_money: (arg = "5000") => `Load Rs.${arg} or more to get your card delivered!`,
        tripMoney: "TripMoney",
        global_cash_card: "Global Cash Card",
        golbal_card_closure_title: "Card Closure Notice!",
        golbal_card_closure_description: "This card will stop working on 31st October, 2023. Please withdraw any balance immediately",
        golbal_card_closure_know_more: "Know  More",
        upgrade_now: "UPGRADE NOW",
        mmt_gift_voucher_offer:"Order and get an MMT gift voucher worth INR 1000",
        powered_by_sbm: "Powered by SBM Bank (India) Ltd",
        card_on_way: "Your TripMoney Global Cash Card is on its way",
        card_shipped: "Your card has been shipped",
        access_benefits: "Access to regular special discounts & offers",
        card_activated: "Your card is ACTIVATED!",
        proceed: "Proceed",
        further_query: "Have further query?",
        otp_sent: "OTP has been resent successfully",
        currency_details: "Currency Details",
        card_details: "Card Details",
        pending_transactions: "Pending Transactions",
        completed_transactions: "Completed Transactions",
        pending: "Pending",
        completed: "Completed",
        no_transactions_found: "No transactions found",
        continue: "Continue",
        load_money_spend_global: "Load money in INR. Spend Globally.",
        no_forex_markup: "No Forex Markup",
        key_charges: "Key charges you should know",
        avoid_check_balance: "Avoid balance enquiry charge by checking the balance on the app for FREE.",
        exit: "Exit",
        select_desired_currency: "Select the desired currency",
        redirecting_to_home: "Now you are redirecting to home page.",
        verifying_otp: "Verifying OTP",
        do_not_press_back: "Do not refresh the page or press the back button",
        verifying_details: "Verifying details...",
        loading: "Loading...",
        withdraw_money_amount_exceeded: "Amount entered is greater than the available balance. Please enter a valid amount.",
        withdraw_money_limit_exceeded: "Amount entered is greater than the withdraw limit. Please enter a valid amount.",
        please_enter_name: "Please enter name",
        please_enter_account_number: "Please enter Account Number",
        please_enter_valid_ifsc: "Please enter valid IFSC code",
        unload_money_success_text: "We have received the request for withdrawal. The amount will be transferred via NEFT in the next 72 hours.",
        unload_money_txn_failed: "Transaction Failed.",
        faqs: 'Frequently Asked Questions (FAQs)',
        card_based_faqs: 'Card Based FAQs',
        general_faqs: 'General FAQs',
        create_new_order: 'Create New Order',
        current_conversion_rate: 'Current conversion rate',
        total: 'Total',
        unable_to_process_photo: "Photo could not be processed",
        try_again: "Please try again",
        retake_selfie: "Retake Selfie",
        currency_notes: "Currency Notes",
        bookmyforex_card: "BookMyForex Card",
        house_floor_building: "House No., Floor, or Building",
        locality_colony_street: "Locality, Colony, or Street",
        indian_rupee: "🇮🇳 Indian Rupee",
        upload_document: "Please upload the following documents after making the payment to process your order.",
        INDICATIVE_RATE: "Rates displayed are indicative. Actual rates may differ slightly.",
        INPUT_AMOUNT: "Input Amount",
        status_awaited: "KYC Status Awaited!",
        checking_kyc_details: "Checking KYC Details",
        kyc_taking_longer_time: "It is taking longer than usual to get your KYC status from the bank. Let us give it one more push.",
        kyc_details_submitted: "Your details and selfie are being submitted to our partner bank for KYC.",
        refresh_back: "Please dont refresh or press back button",
        yet_to_recieve: "We are yet to receive your KYC status from the bank. Sit back and relax. We shall notify you as soon as we get a response.",
        go_to_homepage: "Go To Homepage",
        SIT_BACK: "Sit back and relax while we complete the verification process.",
        WITHDRAW_MONEY: "Withdraw Money",
        LOAD_MONEY_INR: "Load Money",
        CARD_RETURNED: "Your card has been returned.",
        CARD_DELIVERED: "Your card has been delivered. Please activate it.",
        DELIVERY_IN_PROGRESS: "Your Forex Card Delivery is in Progress",
        FIFA_OFFERS: [
            "Spend Duration: 20th October '22 to 20th November '22",
            'Only non-INR spends on the TripMoney Global Cash card will be considered',
            'Spends only in Point of Sale terminals (POS) and E-Com platforms to be considered for the purpose of calculations; ATM withdrawals will not be considered',
            'Top 6 Users with maximum spends on TripMoney Global Cash Card in contest duration, win',
            'No customer can win 2 or more tickets',
            'Winners to be announced on 25th November ‘22',
            'The Quarter final is in Qatar on 10th December ’22'
        ],
        UAE_OFFERS: [
            'Only AED spends from the card to be considered',
            `Duration: 1 Dec '22 to 31 Jan '23`,
            `Spends only in Point of Sale terminals (POS) and E-Com platforms to be considered for the purpose of calculations`,
            `Winner Announcement: 10 Feb '23`,
            'No customer can win 2 awards',
            `Customer Qualifying for the draw where spend is greater than Rs. 1,00,000 also qualifies for the draw where spend greater than Rs. 35,000`,
            `Spends should be from the same SBM bank powered TripMoney Global Cash Card`,
            `INR equivalent of the AED spends should be as per mentioned Spend Amount slabs`

        ],
        read_more_about_the_offer: "Read more about the offer",
        claim_your_free_card_now: "Claim your free card now!",
        UAE_CAMPAIGN: {
            travelling_to_uae: "Travelling to UAE?",
            spend_using_tripmoney_global_card: "Spend using the TripMoney Global Cash Card to win iPad Air or Fossil SmartWatch",

            ipad_air: "iPad Air",
            first_lucky_winner: "1st Lucky winner gets an iPad Air on spend of greater than Rs. 1,00,000 equivalent of AED",
            fossils_first_watch_gen: "Fossil Smart Watch Gen 5",
            four_lucky_winners: "4 Lucky winners get a Fossil Smart Watch Gen 5 on spend of greater than Rs. 35,000 equivalent of AED",
            spend_amount_in_inr: "Spend Amount (In INR)",
            award: "Award",
            no_of_award: "No.of Awards",
            fossil_smartwatch_watch_gen_5: "Fossil Smartwatch Watch Gen 5",
        },
        FIFA_CAMPAIGN: {
            make_your_dream_come_true: "⚽ Make Your Dream Come True ⚽",
            spend_using_tripmoney_global_card: "Spend Using TripMoney Global Cash Card on International Trips & Win FIFA World Cup Tickets!",
            sponsored_by_visa: "(Sponsored by VISA)",
            six_lucky_winners_get: "6 lucky winners will get 1 Quarter Final Ticket Each for the FIFA World Cup to be held in Qatar",
        },
        STATUS_AWAITED_IN_PROGRESS: "We are still awaiting a response from the bank. You will be notified via whatsapp once the bank confirms your KYC status.",
        KYC_SUCCESS: "KYC Successful",
        KYC_SUCCESS_SUBTEXT1: "Almost done! Please enter your delivery details on the next page",
        KYC_REDIRECT_DO_NOT_PRESS: (seconds) =>  `You will be directed to the next page in ${seconds || 0} seconds`,
        VENDOR_ERROR: 'We are facing vendor issues. Please click Proceed to try with a different vendor.',
        NOT_LIVE_SELFIE: "Photo is not live/Invalid photo.",
        CLICK_LIVE_SELFIE: "Please click a live selfie.",
        RECLICK_SELFIE: "Please re-click the selfie.",
        PROBLEM_INITIATE_FLOW: "We could not process your selfie. Please click a new Selfie.",
        SELFIE_PROCESSING_ERROR: "Selfie Processing Error",
        SELFIE_ERROR_DESCRIPTION: "Our partner bank could not process your selfie. Please click a new selfie and try again.",
        CLICK_NEW_SELFIE: "Click new selfie",
        FACE_INSTRUCTIONS_TITLE: "Upload selfie for KYC",
        FACE_INSTRUCTIONS_DESC: "Please remove Spectacles, Hats and Masks. A clearly visible face will get approved fast.",
        CAMERA_PERMISSION_DENIED: "Camera Permission required to proceed. Please allow camera access to the internet browser from camera settings.",
        CAMERA_PERMISSION_DENIED_APP: "Camera Permission required to proceed.",
        SECURE_AND_CONFIDENTIAL: "100% secure & confidential",
        LOAD_MONEY: "Card balance is fully redeemable and can be withdrawn at any time free of charge.",
        WITHDRAW_REQUEST_NOTE: <>Note: Merchant Name will appear as <br/><b>"VA Tech Ventures Pvt Ltd"</b><br/> in your bank statement against this transaction.</>,
        REQUEST_PAYMENT_STEPS: [
            "Step 1: Generate & share payment link",
            "Step 2: They successfully process payment via shared link",
            "Step 3: Money is added to your card"
        ]
    },
    errorMessages: {
        decimal_error: 'Decimals are not allowed',
        PASSPORT_VALIDATION: <>Please ensure that passport file no. is in the correct format.<br />(Eg. AB1234567890) <br /> <div><span className='text-underline'>Please note:</span> Passport file number is not the same as Passport number. (Refer the image below)</div></>,
    }
}