import { takeLatest, put } from 'redux-saga/effects';
import { setLoading } from '../../../reducers/loading';
import { setAction } from '../../../reducers/genericReducer';
import { setError } from '../../../reducers/error';
import { DISPLAY_ERROR_MISMATCH, ERROR_NO_ACTION, LOGIN_REQUIRED } from '../util/constants';
import { redirectIntercept, redirectInterceptHref } from '../util/common';
import { getForexBasePath, getOnboardingStages } from '../util';

export default function* watch(actionName, successAction, redirectUrl, getResponseExectuor, loader = true, passEntireResponse = false, showError = true) {
    yield takeLatest(`GET_${actionName}`, getWorker(successAction, redirectUrl, getResponseExectuor, loader, passEntireResponse, showError))
    yield takeLatest(`SUBMIT_${actionName}`, getWorker(successAction, redirectUrl, getResponseExectuor, loader, passEntireResponse, showError))
}

const getWorker = (successAction, redirectUrl, getResponseExectuor, loader = true, passEntireResponse = false, showError = true) => {
    return function* getData(action) {
        try {
            let showLoader = true;
            if(!!action?.payload?.noNeedOfLoader) {
                showLoader = false;
                delete action.payload.noNeedOfLoader
            } else if (action?.payload?.hasOwnProperty?.("noNeedOfLoader")) {
                delete action.payload.noNeedOfLoader
            }
            if (loader && showLoader)
                yield put(setLoading(true))
            let response = yield getResponseExectuor(action.payload)
            if (loader)
                yield put(setLoading(false))
            if (response.data.success === true) {
                if (response.data.data && !response.data.data.forexFunnelAllowedToBeShownToUser) {
                    redirectInterceptHref(`${getForexBasePath()}/onboarding/restricted`);
                    return;
                }
                yield put(setAction(successAction)(passEntireResponse ? response.data : (response.data.data ? response.data.data : response.data)))
            } else {
                yield put(setAction(successAction)(response.data))
                if (showError) {
                    if (response.data.error && response.data.error.code && (response.data.error.code === ERROR_NO_ACTION || response.data.error.code === LOGIN_REQUIRED)) {
                        yield put({ type: response.data.error.code, payload: response.data.error.description });
                    } else if (response.error && response.error.code === DISPLAY_ERROR_MISMATCH) {
                        yield put(setAction(successAction)(response))
                        yield put(
                            setError({
                                action: DISPLAY_ERROR_MISMATCH,
                                payload: response.error,
                                data:
                                    response.data &&
                                        response.data.cards &&
                                        response.data.cards.length &&
                                        response.data.cards[0].data &&
                                        response.data.cards[0].data.data
                                        ? {
                                            ...response.data.cards[0].data.data,
                                            current_workflow_state:
                                                response.data.cards[0].data.current_workflow_state ||
                                                "",
                                            next_signal_state:
                                                response.data.cards[0].data.next_signal_state || "",
                                        }
                                        : {},
                                response: response,
                            })
                        );
                        if (action.payload && !action.payload.redirectNotNeeded) {
                            if(response?.data?.cards?.[0].data?.next_signal_state === "ckyc_pan_awaited") {
                                redirectIntercept(getOnboardingStages()["kyc_awaited"].uiRoute);
                            } else {
                                redirectIntercept(`${getForexBasePath()}/mismatch/error`, response.error);
                            }
                        }
                    }
                    else if (!(response.data.error.code === LOGIN_REQUIRED || response.data.error.code === ERROR_NO_ACTION)) {
                        yield put(setError({ ...response.data.error, viewed: false }));
                    }
                }
            }
        } catch (e) {
            if (loader)
                yield put(setLoading(false))
            if (e.response && e.response.status === 401) {
                redirectIntercept(`/login?redirect_url=${redirectUrl}`);
            } else if (e.response && e.response.status === 403) {
                yield put(setError({ viewed: false, description: 'You are not authorized to access!' }))
            } else {
                if (showError) {
                    yield put(setError({ viewed: false, description: 'Seems there is some problem with your network, please check back in a while.' }))
                }
            }
            console.log(e)
        }
    }
}