import { takeLatest, put, select } from 'redux-saga/effects';
import { finalPayment } from '../api';
import { setLoading } from '../reducers/loading';
import { setError } from '../reducers/error';
import { setOtherInfo } from '../reducers/otherInfo';
import { getRedirectURL } from '../reducers/redirectUrl';
import { LOGIN_REQUIRED, ERROR_NO_ACTION } from '../constants';

export default function* watchFinalisePayment() {
    yield takeLatest('FINALISE_PAYMENT', finalise)
}

function* finalise(action) {
    try {
        yield put(setLoading(true))
        const { partner, ztxnId, otp } = action.payload
        const redirectUrl = yield select(getRedirectURL);
        window.dataLayer.push({
            'event': 'eventTracking',
            'category': 'TRANSACTION',
            'action': 'FINALIZE_PAYMENT',
            'label': 'ztxnId',
            'value': ztxnId
        });
        let initPaymentResponse = yield finalPayment(partner, ztxnId, otp)
        console.log(initPaymentResponse.data);
        if (initPaymentResponse.data != null && initPaymentResponse.data.data != null && initPaymentResponse.data.data.txnStatus == "SUCCESS") {
            console.log("success");
            window.dataLayer.push({
                'event': 'eventTracking',
                'category': 'TRANSACTION',
                'action': 'FINALIZE_PAYMENT_SUCCESS',
                'label': 'ztxnId',
                'value': ztxnId
            });
            window.location = initPaymentResponse.data.data.redirectUrl;
        } else if (initPaymentResponse.data != null && initPaymentResponse.data.error) {
            yield put(setLoading(false))
            // yield put (setOtherInfo({showTransactionOtp: true}));
            let error = initPaymentResponse.data.error;
            if (!(error != null && (error.code == LOGIN_REQUIRED || error.code == ERROR_NO_ACTION))) {
                window.dataLayer.push({
                    'event': 'eventTracking',
                    'category': 'TRANSACTION',
                    'action': 'FINALIZE_PAYMENT_ERROR',
                    'label': initPaymentResponse.data.error.description,
                    'value': ztxnId
                });
                //yield put(setOtherInfo({ ztxnId: '' }))
                yield put(setError({ ...initPaymentResponse.data.error, viewed: false, redirectUrl }))
            }
        }
    } catch (e) {
        window.dataLayer.push({
            'event': 'eventTracking',
            'category': 'TRANSACTION',
            'action': 'FINALIZE_PAYMENT_FAILED',
            'label': e.message,
            'value': 0
        });
        yield put(setOtherInfo({ ztxnId: '' }))
        yield put(setError({ viewed: false, code: "CLOSE_WEBVIEW", description: 'Seems there is some problem with your network, please check back in a while.' }))
        yield put(setLoading(false))
    }
}