import { takeLatest, put } from 'redux-saga/effects';
import { setLoading } from '../reducers/loading'
import * as zero from '../api';
import { history } from "../containers/Router";
import * as stageUtils from '../utils/stage';
import { setError } from '../reducers/error';
import { ERROR_NO_ACTION, LOGIN_REQUIRED } from '../constants';
import { setStageInfo } from '../reducers/stageInfo';

import { createAction } from 'redux-actions';
import {redirectIntercept} from '../utils/common';

// ACTION CREATORS
export const savePersonalInfo = createAction('SAVE_STAGE_INFO');

export default function* watchSavePersonalInfoSaga() {
    yield takeLatest('SAVE_STAGE_INFO', savePersonalInfoSaga)
}

function* savePersonalInfoSaga(action) {
    let { partner, context, userId, lender, stageId, data } = action.payload;
    try {
        yield put(setLoading(true))
        //saving to reducer so that same info can be passed to employment info submit stage
        yield put(setStageInfo(data))
        let savePersonalInfoResponse = yield zero.saveStageInfo(partner, userId, lender, stageId, data)
         yield put(setLoading(false))
        if (savePersonalInfoResponse.data != null
            && savePersonalInfoResponse.data.responseStatus == "SUCCESS") {
            window.dataLayer.push({
                'event': 'eventTracking',
                'category': 'PERSONAL_INFO',
                'action': 'SAVE_STAGE_INFO_SUCCESS',
                'label': 'SAVED_PERSONAL_INFO',
                'value': data.retryCount != undefined ? data.retryCount : 0
            });
            redirectIntercept(stageUtils.mapStageToRoute("zest_userinfo_basic_employment", partner, context))
        }
        if (savePersonalInfoResponse.data.responseStatus == "ERROR") {
            let error = savePersonalInfoResponse.data.error;
            if (!(error.error != null && (error.error.code == LOGIN_REQUIRED || error.error.code == ERROR_NO_ACTION))) {
                window.dataLayer.push({
                    'event': 'eventTracking',
                    'category': 'PERSONAL_INFO',
                    'action': 'SAVE_STAGE_INFO_FAILED',
                    'label': savePersonalInfoResponse.data.error,
                    'value': data.retryCount != undefined ? data.retryCount : 0
                });
                if (savePersonalInfoResponse.data.error.code !== "DISPLAY_ERROR_PAGE") {
                    yield put(setError({ ...savePersonalInfoResponse.data.error, viewed: false }))
                }
            }
        }
    } catch (e) {
        window.dataLayer.push({
            'event': 'eventTracking',
            'category': 'PERSONAL_INFO',
            'action': 'SAVE_STAGE_INFO_FAILED',
            'label': e.message,
            'value': data.retryCount != undefined ? data.retryCount : 0
        });
        console.log(data);
        console.log(e);
        if ((e.message == "Network Error" || e.code === 'ECONNABORTED') && (data.retryCount == undefined || data.retryCount < 3)) {
            let retryCount = 0;
            yield put(setLoading({ status: true, message: "Saving personal information, it will take some more time.." }))
            if (e.code === 'ECONNABORTED') {
                yield sleep(60000)
            } else {
                yield sleep(10000)
            }
            if (data.retryCount !== undefined)
                retryCount = data.retryCount + 1;
            else
                retryCount = 1;
            yield put(savePersonalInfo({ partner, context, userId, lender, stageId, data, retryCount: retryCount }))
        } else {
            yield put(setError({ viewed: false, description: 'Seems there is some problem with your network, please check back in a while.' }))
            yield put(setLoading(false))
        }
        console.log(e)
    }
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}