import { takeLatest, put } from 'redux-saga/effects';
import { captureException, withScope } from '@sentry/browser';

import { setLoading } from '../reducers/loading'
import * as zero from '../api';
import { history } from "../containers/Router";
import { setCurrentStage, getCurrentStage, submitStage } from '../reducers/stage';
import { getUser } from '../reducers/user';
import * as stageUtils from '../utils/stage';
import { setError } from '../reducers/error';
import { setUser } from '../reducers/user';
import { LOGIN_REQUIRED, IDFC_AADHAAR, IDFC_CONSENT, REFRESH_ERROR, ERROR_NO_ACTION } from '../constants';
import { redirectIntercept,redirectInterceptHref} from '../utils/common';

export default function* watchSubmitStage() {
    yield takeLatest('SUBMIT_STAGE', submitStageSaga)
}

function* submitStageSaga(action) {
    let { partner, context, userId, lender, stageId, stageName, loader, data, pTxnId } = action.payload;
    try {
        if (stageName === IDFC_AADHAAR || stageName === IDFC_CONSENT) {
            yield put(setLoading({ status: true, specialLoader: true, loader }))
        } else {
            yield put(setLoading(true))
        }

        let stageResponse = yield zero.submitStage(partner, userId, lender, stageId, data)
        //If post stage data is successful, then get the new stage details
        //by calling current stage
        if (stageName === IDFC_AADHAAR || stageName === IDFC_CONSENT) {
            yield put(setLoading({ status: false, specialLoader: false, loader: null }))
        } else {
            yield put(setLoading(false))
        }
        //Success response
        if (stageResponse.data != null
            && stageResponse.data.responseStatus === "SUCCESS") {
            window.dataLayer.push({
                'event': 'eventTracking',
                'category': 'SUBMIT_STAGE',
                'action': 'STAGE_SUBMITED',
                'label': stageResponse.data.data.stageDetails.stageName,
                'value': data.retryCount !== undefined ? data.retryCount : 0
            });
            let stage = stageResponse.data;
            if (stageUtils.checkIfRedirectIsRequired(stage)) {
                redirectIntercept(`/partners/${partner}/transaction/${pTxnId}`)
                return;
            }
            if (stageUtils.shouldPoleStage(stageResponse)) {
                yield put(setLoading(true))
                yield stageUtils.retry(partner, context, userId, lender, stageResponse, { retryCount: (data && data.retryCount !== undefined ? data.retryCount : 0) });
                return;
            }

            //Check if stage is auto submittable 
            if (stageUtils.isAutoSubmitStage(stageResponse)) {
                if (stage.data.stageDetails && stage.data.stageDetails.xdata && stage.data.stageDetails.xdata.error) {
                    yield put(setError({ viewed: false, description: stage.data.stageDetails.xdata.error }))
                } else {
                    yield put(submitStage({ partner, context, userId, lender, stageId: stage.data.stageDetails.stageId, data: {}, retryCount: 0 }))
                }
            } else {
                //Check if this stage requires refresh of user details
                if (stageUtils.shouldRefreshUser(stageResponse.data.data.stageDetails.stageName)) {
                    yield put(getUser({ partner, context }))
                }

                if (stageUtils.shouldRestartOnboardingFlow(stageResponse)) {
                    yield put(setUser({}));
                    redirectIntercept(stageUtils.getOtherCompleteRoute("refresh", partner, context));
                    return;
                }

                //If redirect url is present then redirect page
                if (stage.data.stageDetails.xdata.redirectUrl !== undefined
                    && stage.data.stageDetails.xdata.redirectUrl !== "") {
                    redirectInterceptHref(stage.data.stageDetails.xdata.redirectUrl);
                    return;
                }

                //If stage response has error in xdata then show it in popup
                if (stage.data.stageDetails && stage.data.stageDetails.xdata.error) {
                    yield put(setError({ viewed: false, description: stage.data.stageDetails.xdata.error }))
                }
                //Set this as current stage and redirect
                yield put(setCurrentStage(stageResponse.data));
                if (!stageUtils.doNotRedirect(stage.data.stageDetails)) {
                    redirectIntercept(stageUtils.mapStageToRoute(stage.data.stageDetails.stageName, partner, context))
                }
            }
        }

        if (stageResponse.data.responseStatus === "ERROR") {
            withScope(scope => {
                scope.setFingerprint(['SUBMIT_STAGE_ERROR']);
                scope.setExtra("payload", action.payload);
                captureException(stageResponse.data);
            });
            let error = stageResponse.data.error;
            if (!(error != null && (error.code == LOGIN_REQUIRED || error.code == ERROR_NO_ACTION))) {
                if (stageResponse.data.error.code == "INVALID_STAGE") {
                    window.dataLayer.push({
                        'event': 'eventTracking',
                        'category': 'SUBMIT_STAGE',
                        'action': 'STAGE_SUBMIT_FAILED',
                        'label': 'INVALID_STAGE',
                        'value': data.retryCount !== undefined ? data.retryCount : 0
                    });
                    yield put(getCurrentStage({ partner, context, userId, lender, stage: "current" }))
                } else if (error && error.code === REFRESH_ERROR) {
                    window.dataLayer.push({
                        'event': 'eventTracking',
                        'category': 'SUBMIT_STAGE',
                        'action': 'STAGE_SUBMIT_FAILED',
                        'label': REFRESH_ERROR,
                        'value': data.retryCount !== undefined ? data.retryCount : 0
                    });
                    yield put(setUser({}));
                    redirectIntercept(`/partners/${partner}/init/${context}/refresh`);
                } else {
                    window.dataLayer.push({
                        'event': 'eventTracking',
                        'category': 'SUBMIT_STAGE',
                        'action': 'STAGE_SUBMIT_FAILED',
                        'label': stageResponse.data.error,
                        'value': data.retryCount !== undefined ? data.retryCount : 0
                    });
                    if (stageResponse.data.error.code !== "DISPLAY_ERROR_PAGE" && stageResponse.data.error.code !== "DISPLAY_ERROR_PAGE_RETRY") {
                        yield put(setError({ ...stageResponse.data.error, viewed: false }))
                    }
                }
            }
        }
    } catch (e) {
        window.dataLayer.push({
            'event': 'eventTracking',
            'category': 'SUBMIT_STAGE',
            'action': 'STAGE_SUBMIT_FAILED',
            'label': e.message,
            'value': data.retryCount !== undefined ? data.retryCount : 0
        });
        //If error is network error then retry for 3 times
        if ((e.message === "Network Error" || e.code === 'ECONNABORTED') && (data.retryCount === undefined || data.retryCount < 3)) {
            let retryCount = 0;
            yield put(setLoading({ status: true, message: "Evaluating your eligibility, it will take some more time.." }))
            if (e.code === 'ECONNABORTED') {
                yield sleep(60000)
            } else {
                yield sleep(10000)
            }
            if (data.retryCount !== undefined)
                retryCount = data.retryCount + 1;
            else
                retryCount = 1;
            yield put(submitStage({ partner, context, userId, lender, stageId, data, retryCount: retryCount }))
        } else {
            yield put(setError({ viewed: false, description: 'Seems there is some problem with your network, please check back in a while.' }))
            yield put(setLoading(false))
        }
        withScope(scope => {
            scope.setFingerprint(['SUBMIT_STAGE_ERROR']);
            scope.setExtra("payload", action.payload);
            captureException(e);
        });
        console.log(e)
    }
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}