import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const GetTravelLoan = createAction('GET_NEW_TRAVEL_LOAN');
export const SetTravelLoan = createAction('SET_NEW_TRAVEL_LOAN');
export const ClearTravelLoan = createAction('CLEAR_TRAVEL_LOAN');

// REDUCERS
export const reducer = handleActions(
    new Map([
      [
        SetTravelLoan,
        (state, action) => {
          return {data : action.payload}
        } 
      ],
      [
        ClearTravelLoan,
        (state, action) => {
          return {data : null,error : null}
        } 
      ]
    ]),
    {data : null,error : null} 
  );