import { createAction, handleActions } from 'redux-actions';

// ACTION CREATORS
export const getLogin = createAction('GET_LOGIN');
export const setLogin = createAction('SET_LOGIN');
export const createSession = createAction('CREATE_SESSION');

// REDUCERS
export const reducer = handleActions(
  new Map([
    [
        setLogin,
      (state, action) => {
        return action.payload
      } 
    ]
  ]),
  false
);

// SELECTORS
export const getUserState = state => state.user;
 
