import { takeLatest, select } from 'redux-saga/effects';
import { history } from "../containers/Router";
import { redirectIntercept } from '../utils/common';

export default function* watchInternalError() {
    yield takeLatest('INTERNAL_ERROR', handleInternalError)
}

const getOtherInfo = state => state.otherInfo;

function* handleInternalError(action) {
    
    try {
        //redirectIntercept("/error")
        let otherInfo = yield select(getOtherInfo);
        redirectIntercept(`/error?x-request-id=${otherInfo && otherInfo.requestData ? otherInfo.requestData.xRequestId : ""}`)
    } catch(e) {
        console.log(e)
    }
}