import * as zero from '../api';
import handleError from './handleError';
import logActionsSaga from './logActions';
import wsHandler from './kycEvents';
import getUser from './user';
import config from './config';
import validateOTU from './validateOTU';
import stage from './stage';
import submitStage from './submitStage';
import otp from './otp';
import validateOtp from './validateOtp';
import internalError from './internalError';
import whatsAppConsent from './whatsAppConsent'
import getZipCodeInfo from './getZipCodeInfo'
import getIfscInfo from './getIfscInfo'
import getLedgerInfo from './ledger';
import getLedgerFaq from './ledgerFaq';
import getLedgerLoanDetails from './ledgerLoanDetails';
import setLedgerFeedback from './ledgerFeedback';
import getPayNowUrl from './getPayNowUrl';
import displayErrorPage from './displayErrorPage';
import submitAddress from './submitAddress';
import getContext from './getContext';
import getPaymentOptions from './getPaymentOptions';
import initPayment from './initPayment';
import finalPayment from './finalPayment';
import loginRequired from './loginRequired';
import saveStageInformation from './saveStageInfo';
import linkUser from './linkUser';
import getCloseURL from './close';
import genericFetchSagaCreator from './genericFetchDataCreator'; // common with admin
import getLoginStatus from './loginStatus';
import logoutAction from './logout';
import validatePLOTP from './validatePLOTP';
import pdtEventSaga from './publishPDTEvent';
import lenderFetchSaga from './fetchCurrentLenderStage'
import genericFetchDataElasticSearch from './genericFetchDataElasticSearch'; // common with admin
import { history } from '../containers/Router';

import genericFetchDataCreditCard from './genericFetchDataCreditCard';
import otpCreditCard from './otpCreditCard';
import checkTokenExpiry from './checkTokenExpiry';
import getNewToken from './getNewToken';
import errorNoAction from './errorNoAction';
import cmsContent from './cmsContent';
import getTravelLoanSaga from './getTravelLoanSaga'

const genericFetchSaga = genericFetchSagaCreator(history);

const allSagas = [
    getTravelLoanSaga(),
    lenderFetchSaga(),
    pdtEventSaga(),
    handleError(),
    logActionsSaga(),
    wsHandler(),
    getUser(),
    config(),
    validateOTU(),
    stage(),
    submitStage(),
    otp(),
    validateOtp(),
    internalError(),
    whatsAppConsent(),
    getZipCodeInfo(),
    getIfscInfo(),
    getLedgerInfo(),
    getLedgerFaq(),
    getLedgerLoanDetails(),
    setLedgerFeedback(),
    getPayNowUrl(),
    displayErrorPage(),
    submitAddress(),
    getContext(),
    getPaymentOptions(),
    initPayment(),
    finalPayment(),
    loginRequired(),
    saveStageInformation(),
    linkUser(),
    getCloseURL(),
    cmsContent(),
    genericFetchSaga("SEND_STATEMENT", "", zero.sendSOA),
    genericFetchSaga("VALIDATE_PAN", "", zero.validatePAN, false, true),
    getLoginStatus(),
    logoutAction(),
    genericFetchSaga("SEND_DOWNLOAD_APP_SMS", "", zero.sendDownloadAppSMS),
    genericFetchSaga("GET_INSURANCE_PRODUCTS", "", zero.getProducts),
    genericFetchSaga("CHECKOUT", "", zero.checkout),
    genericFetchSaga("GET_ORDERS", "", zero.getOrders),
    genericFetchSaga("SEND_POLICY_EMAIL", "", zero.sendPolicyMail, false),
    genericFetchSaga("SEND_POLICY_EMAIL_NON_LOGGED_IN", "", zero.sendPolicyMailNonLoggedIn),
    genericFetchSaga("GET_FAQ", "", zero.getFAQ, false),
    genericFetchSaga("GET_FAQ_PREMIUM", "", zero.getFAQ, false),
    genericFetchSaga("GET_CHARGES_AND_FEES", "", zero.getFAQ, false, false, false),
    genericFetchSaga("TRANSACTION_OPTIONS", "", zero.getLoanCards, true, true),
    genericFetchSaga("SEND_PL_OTP", "", zero.sendPLOTP),
    validatePLOTP(),
    genericFetchSaga("SEND_APP_LINK_TO_MOBILE", "", zero.sendAppLinkToMobile, true, true),
    genericFetchSaga("GET_PRODUCT_CARDS", "", zero.getProductCards),
    genericFetchSaga("GET_INSURANCE_USER_DATA", "", zero.getUserData, true, false, false),
    // For Insurance Postsale
    genericFetchSaga("GET_INSURANCE_PS_ADD_ONS", "", zero.getAddOnsDetails, true, true, false),
    genericFetchSaga("GET_CHECKOUT_DETAILS", "", zero.getCheckoutDetails, true, true, false),
    genericFetchSaga("GET_THANK_YOU_PAGE_DETAILS", "", zero.getThankYouPageDetails, true, true, false),

    // For Standalone Insurance
    genericFetchSaga("GET_STANDALONE_INSURANCE_PRODUCTS", "", zero.getStandaloneInsuranceProducts, false, true, true, true),
    genericFetchSaga("CHECKOUT_INSURANCE_PRODUCTS", "", zero.getStandaloneCheckoutDetails, true, true, true),
    genericFetchSaga("GET_SEARCH_HISTORY", "", zero.getSearchHistory, false, true, false),
    genericFetchSaga("GET_INSURANCE_BOOKING_DETAILS", "", zero.getStandaloneBookingDetails, true, true, false),

    //For personal loan
    genericFetchSaga("GET_INSURANCE_DETAILS", "", zero.getInsuranceDetails, true, true, true),
    otpCreditCard(),
    checkTokenExpiry(),
    getNewToken(),
    //for new tripmoney mobile only landing page
    genericFetchSaga("LANDING_PAGE_SECTIONS", "", zero.getTripLandingPageSections, false, false, false),
    genericFetchSaga("RESEND_OTP_ZEST", "", zero.resendOTPForZestTransaction, true, true, false),
    genericFetchSaga("PDT_LOGGING", "", zero.logPDT, false, true, false),

    //for insurance claim
    genericFetchSaga("REGISTER_CLAIM", "", zero.registerClaim, true, true, true),
    genericFetchSaga("GET_CLAIM_STATUS", "", zero.getClaimStatus, true, true, true),
    genericFetchSaga("VERIFY_OTP_INSURANCE", "", zero.verifyOtpForInsurance, true, false, true),
    genericFetchSaga("GET_INTERMEDIATE_ELIGIBILITY", "", zero.getIntermediateEligibility, true, true, true),

    genericFetchSaga("EDIT_POLICY_DETAILS", "", zero.editPolicyDetails, true, true, true),
    errorNoAction()
];

export default allSagas;